import { Grid, alpha, makeStyles } from "@material-ui/core";
import React from "react";

import { agentIpCallBatchSequenceType } from "@/enums/voiceCall";
import Body from "./Body";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  floatingInboundCall: {
    width: "100%",
    height: "174px",
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 3px 6px ${alpha(theme.palette.common.black, 0.22)}`,
  },
}));

const FloatingInboundCall = ({
  voiceConversation,
  isAgentMonitoringAnyCall,
  isAnyVoiceCallStarting,
  isEndingCall,
  isStartingCall,
  isVideoCallInUse,
  onAnswerIncomingCall,
  onRejectIncomingCall,
}) => {
  const classes = useStyles();

  const { conversation, initiatorVoiceLeg, lastAgentIpCallToMe } =
    voiceConversation || {};
  const { contact, group, conversationTags } = conversation || {};
  const { isSelfInvite, sequenceType } =
    lastAgentIpCallToMe?.batch?.callSequence || {};
  const isCallTransfer =
    sequenceType === agentIpCallBatchSequenceType.callTransfer;

  if (isSelfInvite) return null;

  return (
    <Grid className={classes.floatingInboundCall} container direction="column">
      <Header
        contact={contact}
        group={group}
        conversationTags={conversationTags}
      />

      <Body
        voiceProviderName={initiatorVoiceLeg.voiceProviderAccount.name}
        isAgentMonitoringAnyCall={isAgentMonitoringAnyCall}
        isAnyVoiceCallStarting={isAnyVoiceCallStarting}
        isCallTransfer={isCallTransfer}
        isEndingCall={isEndingCall}
        isStartingCall={isStartingCall}
        isVideoCallInUse={isVideoCallInUse}
        onAnswerIncomingCall={onAnswerIncomingCall}
        onRejectIncomingCall={onRejectIncomingCall}
      />
    </Grid>
  );
};

export default FloatingInboundCall;
