import { useTheme } from "@material-ui/core";
import React from "react";

import Button from "@/components/Button";

const LeaveCallButton = (props) => {
  const theme = useTheme();

  return (
    <Button
      fullWidth={false}
      width="100px"
      height="42px"
      margin="0 19px 0 0"
      padding="0"
      fontSize="1.23rem"
      color={theme.palette.common.white}
      backgroundColor={{
        normal: theme.palette.alert[400],
        hover: theme.palette.alert.main,
      }}
      {...props}
    >
      Leave call
    </Button>
  );
};

export default LeaveCallButton;
