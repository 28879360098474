import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  outerCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "14px",
    height: "14px",
    marginRight: "5px",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
  },

  innerCircle: {
    width: "6px",
    height: "6px",
    backgroundColor: theme.palette.alert[300],
    borderRadius: "50%",
    animation: "$blink 1s cubic-bezier(.5, 0, 1, 1) infinite alternate",
  },

  "@keyframes blink": {
    from: { opacity: 0.3 },
    to: { opacity: 1 },
  },
}));

const RecordingIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.outerCircle}>
      <div className={classes.innerCircle} />
    </div>
  );
};

export default RecordingIcon;
