/* TODO: Switch other actions to use prefixed types */
const namespace = "@/hookActions";

export const REGISTER_HOOK = `${namespace}/REGISTER_HOOK`;
export const WATCH_ACTION_TIMEOUT = `${namespace}/WATCH_ACTION_TIMEOUT`;

export const EDITOR_CLEARED = `${namespace}/EDITOR_CLEARED`;
export const EDITOR_SWITCHED = `${namespace}/EDITOR_SWITCHED`;
export const MESSAGE_SENT = `${namespace}/MESSAGE_SENT`;

export const getEventHookActionType = (event) => `${namespace}/${event}`;

export const registerHook = ({
  extensionAppId,
  watchEvent,
  cancelEvents,
  data,
  runHook,
}) => ({
  type: REGISTER_HOOK,
  extensionAppId,
  watchEvent,
  cancelEvents,
  data,
  runHook,
});
