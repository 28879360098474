import { useTheme } from "@material-ui/core/styles";
import React from "react";

import ShareScreenIcon from "@/assets/images/icon-share-screen.svg";
import StopShareScreenIcon from "@/assets/images/icon-stop-share.svg";
import IconAvatar from "./IconAvatar";

const ShareScreenButton = ({ isScreenSharing, onClick }) => {
  const theme = useTheme();

  return (
    <IconAvatar
      isEnabled
      backgroundColor={isScreenSharing ? theme.palette.primary[700] : undefined}
      size="36px"
      onClick={onClick}
    >
      {isScreenSharing ? <StopShareScreenIcon /> : <ShareScreenIcon />}
    </IconAvatar>
  );
};

export default ShareScreenButton;
