import { Snackbar } from "@material-ui/core";
import React from "react";

import Alert from "@/components/Alert";
import { useMediaUploadSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";

const MediaUploadSnackbar = ({
  message,
  SnackbarProps = {},
  AlertProps = {},
}) => {
  const { onClearMediaSnackbar } = useMediaUploadSnackbar();

  const isOpen = !!message;

  if (!isOpen) return null;

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={onClearMediaSnackbar}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...SnackbarProps}
    >
      <Alert {...AlertProps}>{message}</Alert>
    </Snackbar>
  );
};

export default MediaUploadSnackbar;
