import { makeStyles } from "@material-ui/core";

import InfoIcon from "@/assets/images/icon-info-blue.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  infoIcon: {
    display: "flex",
    alignItems: "center",
    "& path": {
      fill: theme.palette.alert.main,
    },
  },

  message: {
    fontSize: "0.85rem",
    color: theme.palette.alert.main,
  },
}));

const InvalidPhoneNumberMessage = ({ message = "Invalid phone number" }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.infoIcon}>
        <InfoIcon width={12} height={12} />
      </div>

      <div className={classes.message}>{message}</div>
    </div>
  );
};

export default InvalidPhoneNumberMessage;
