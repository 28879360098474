import * as publicVideoCallActions from "@/actions/publicVideoCallActions";
import * as userActions from "@/actions/userActions";

const defaultState = Object.freeze({
  opentokSession: null,
  changedStream: null,
  streams: [],
  connections: [],
});

const publicVideoCallReducer = (state = defaultState, action) => {
  switch (action.type) {
    case publicVideoCallActions.SET_OPENTOK_SESSION: {
      return Object.freeze({
        ...state,
        opentokSession: action.opentokSession,
      });
    }

    case publicVideoCallActions.SET_CHANGED_STREAM: {
      return Object.freeze({
        ...state,
        changedStream: action.changedStream,
      });
    }

    case publicVideoCallActions.ADD_CONNECTION: {
      return Object.freeze({
        ...state,
        connections: [...state.connections, action.connection],
      });
    }

    case publicVideoCallActions.REMOVE_CONNECTION: {
      return Object.freeze({
        ...state,
        connections: state.connections.filter(
          (connection) => connection.id !== action.connection.id,
        ),
      });
    }

    case publicVideoCallActions.ADD_STREAM: {
      const isExistingStream = state.streams.some(
        (currentStream) => currentStream.id === action.stream.id,
      );

      if (isExistingStream) return;

      return Object.freeze({
        ...state,
        streams: [...state.streams, action.stream],
      });
    }

    case publicVideoCallActions.REMOVE_STREAM: {
      return Object.freeze({
        ...state,
        streams: state.streams.filter(
          (stream) => stream.id !== action.stream.id,
        ),
      });
    }

    case publicVideoCallActions.RESET_VIDEO_CALL_STATES: {
      return defaultState;
    }

    case userActions.LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default publicVideoCallReducer;
