import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  actionBannerContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "end",
  },

  /* zIndex ensure that action banner appear on top of any ChatPanel component */
  actionBanner: {
    position: "absolute",
    backgroundColor: theme.palette.alert.main,
    color: theme.palette.common.white,
    padding: "8px 12px 7px 12px",
    borderRadius: "5px",
    margin: "7px 13px 0px 0px",
    zIndex: 4,
  },
}));

const ActionBanner = ({ children, containerClassName, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.actionBannerContainer, containerClassName)}>
      <div className={clsx(classes.actionBanner, className)}>{children}</div>
    </div>
  );
};

export default ActionBanner;
