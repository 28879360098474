import { Snackbar, makeStyles } from "@material-ui/core";

import Alert from "@/components/Alert";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1500,
    "&.MuiSnackbar-anchorOriginBottomLeft": {
      left: "53px",
      bottom: "35px",
    },
  },
}));

const AppSnackbar = ({ message, SnackbarProps = {}, AlertProps = {} }) => {
  const classes = useStyles();

  const { onClearAppSnackbar } = useAppSnackbar();

  const isOpen = !!message;

  if (!isOpen) return null;

  return (
    <Snackbar
      classes={{ root: classes.root }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={onClearAppSnackbar}
      {...SnackbarProps}
    >
      <Alert {...AlertProps}>{message}</Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
