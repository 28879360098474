import { makeStyles, Menu, MenuList } from "@material-ui/core";
import React from "react";

import hexTransparencyEnum from "@/enums/hexTransparencyEnum";
import { videoCallDeviceEnum } from "@/enums/videoCall";
import DeviceMenuItem from "./DeviceMenuItem";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[600]}`,
    boxShadow: `0px 1px 6px ${theme.palette.common.black}${hexTransparencyEnum[18]}`,
    minWidth: "215px",
  },

  menuList: {
    padding: "10px 0px 7px",
  },

  divider: {
    backgroundColor: theme.palette.primaryAction.light,
  },
}));

const VideoOptions = ({
  anchorEl,
  open,
  defaultCamera,
  devices,
  onCameraChange,
  onClose,
}) => {
  const classes = useStyles();

  /* Compare the video input devices by deviceId since the label may differ */

  return (
    <>
      <Menu
        variant="menu"
        classes={{ paper: classes.paper }}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            transform: "translateY(-14px)",
          },
        }}
        getContentAnchorEl={null}
        onClose={onClose}
      >
        <DeviceMenuItem
          isBackButton
          isButton={false}
          text="Video option"
          onClick={onClose}
        />
        <MenuList classes={{ root: classes.menuList }}>
          <DeviceMenuItem
            isBold={true}
            isButton={false}
            isTitle={true}
            text="Select a Camera"
          />
          {devices[videoCallDeviceEnum.CAMERA].map((camera) => {
            const isSelected = camera.deviceId === defaultCamera.deviceId;

            return (
              <DeviceMenuItem
                key={camera.label}
                device={camera}
                isSelected={isSelected}
                text={camera.label}
                onClick={onCameraChange}
              />
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default VideoOptions;
