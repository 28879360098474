import * as crmPageActions from "@/actions/crmPageActions";
import { LOG_OUT_SUCCESS } from "@/actions/userActions";

const defaultState = Object.freeze({
  shouldRefetchInstances: false,
});

const CRMPageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case crmPageActions.SET_SHOULD_REFETCH_INSTANCES: {
      const { shouldRefetchInstances } = action;
      return Object.freeze({
        ...state,
        shouldRefetchInstances,
      });
    }

    case LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default CRMPageReducer;
