import { all } from "redux-saga/effects";

import conversationSaga from "./conversationSaga";
import downloadSaga from "./downloadSaga";
import hookSaga from "./hookSaga";
import userSaga from "./userSaga";

export default function* rootSaga() {
  yield all([conversationSaga(), downloadSaga(), hookSaga(), userSaga()]);
}
