import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.alert.main,
  },
}));

const ErrorBoundaryFallback = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      An error has ocurred in this component
    </div>
  );
};

export default ErrorBoundaryFallback;
