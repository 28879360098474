import { Tooltip as BaseTooltip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[1800],
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    border: `1px solid ${theme.palette.grey[600]}`,
    padding: "10px 8px",
  },

  whiteTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.textSecondary[1740],
    padding: "5px",
  },
}));

const TooltipV2 = ({
  children,
  isWhiteVersion,
  BaseTooltipProps = {},
  ContainerProps = {},
}) => {
  const classes = useStyles();

  const {
    title = "",
    classes: baseTooltipClasses = {},
    ...otherBaseTooltipProps
  } = BaseTooltipProps;

  const { tooltip: tooltipClass, ...otherBaseTooltipClasses } =
    baseTooltipClasses;

  return (
    <BaseTooltip
      title={title}
      classes={{
        tooltip: clsx(
          classes.tooltip,
          { [classes.whiteTooltip]: isWhiteVersion },
          tooltipClass,
        ),
        ...otherBaseTooltipClasses,
      }}
      {...otherBaseTooltipProps}
    >
      <div {...ContainerProps}>{children}</div>
    </BaseTooltip>
  );
};

export default TooltipV2;
