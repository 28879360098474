import { Divider, Menu, MenuList, makeStyles } from "@material-ui/core";
import React from "react";

import hexTransparencyEnum from "@/enums/hexTransparencyEnum";
import { videoCallDeviceEnum } from "@/enums/videoCall";
import DeviceMenuItem from "./DeviceMenuItem";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[600]}`,
    boxShadow: `0px 1px 6px ${theme.palette.common.black}${hexTransparencyEnum[18]}`,
    minWidth: "215px",
  },

  menuList: {
    padding: "10px 0px 7px",
  },

  divider: {
    backgroundColor: theme.palette.primaryAction.light,
  },
}));

const AudioOptions = ({
  anchorEl,
  open,
  defaultMicrophone,
  defaultSpeaker,
  devices,
  onMicrophoneChange,
  onSpeakerChange,
  onClose,
}) => {
  const classes = useStyles();

  /* Compare the audio devices by its label since the deviceId may differ */

  return (
    <>
      <Menu
        variant="menu"
        classes={{ paper: classes.paper }}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            transform: "translateY(-14px)",
          },
        }}
        getContentAnchorEl={null}
        onClose={onClose}
      >
        <DeviceMenuItem
          isBackButton
          isButton={false}
          text="Audio option"
          onClick={onClose}
        />

        <MenuList classes={{ root: classes.menuList }}>
          <DeviceMenuItem
            isBold={true}
            isButton={false}
            isTitle={true}
            text="Select a Microphone"
          />
          {devices[videoCallDeviceEnum.MICROPHONE].map((microphone) => {
            const isSelected = microphone.label === defaultMicrophone.label;

            return (
              <DeviceMenuItem
                key={microphone.label}
                device={microphone}
                isSelected={isSelected}
                text={microphone.label}
                onClick={onMicrophoneChange}
              />
            );
          })}
        </MenuList>

        <Divider className={classes.divider} />

        <MenuList classes={{ root: classes.menuList }}>
          <DeviceMenuItem
            isBold={true}
            isButton={false}
            isTitle={true}
            text="Select a Speaker"
          />
          {devices[videoCallDeviceEnum.SPEAKER].map((speaker) => {
            const isSelected = speaker.label === defaultSpeaker.label;

            return (
              <DeviceMenuItem
                key={speaker.label}
                device={speaker}
                isSelected={isSelected}
                text={speaker.label}
                onClick={onSpeakerChange}
              />
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default AudioOptions;
