const namespace = "@/privateVideoCallActions";

export const SET_OPENTOK_SESSION = `${namespace}/SET_OPENTOK_SESSION`;
export const SET_CHANGED_STREAM = `${namespace}/SET_CHANGED_STREAM`;
export const ADD_CONNECTION = `${namespace}/ADD_CONNECTION`;
export const REMOVE_CONNECTION = `${namespace}/REMOVE_CONNECTION`;
export const ADD_STREAM = `${namespace}/ADD_STREAM`;
export const REMOVE_STREAM = `${namespace}/REMOVE_STREAM`;
export const SET_PREVIOUS_PUBLISHER = `${namespace}/setPreviousPublisher`;
export const SET_CALL_TIMER = `${namespace}/SET_CALL_TIMER`;
export const SET_WAITING_TIMER = `${namespace}/SET_WAITING_TIMER`;
export const RESET_VIDEO_CALL_STATES = `${namespace}/RESET_VIDEO_CALL_STATES`;

export const SET_IS_FLOATING_VIDEO_CALL = `${namespace}/SET_IS_FLOATING_VIDEO_CALL`;
export const SET_HAS_VIDEO_SESSION = `${namespace}/SET_HAS_VIDEO_SESSION`;
export const SET_CONVERSATION_STARTING_VIDEO_CALL = `${namespace}/SET_CONVERSATION_STARTING_VIDEO_CALL`;
export const SET_ONGOING_VIDEO_CALL_CONVERSATION_ID = `${namespace}/SET_ONGOING_VIDEO_CALL_CONVERSATION_ID`;

export const SET_IS_VIDEO_SESSION_DATA_LOADING = `${namespace}/SET_IS_VIDEO_SESSION_DATA_LOADING`;
export const SET_IS_SCREENSHOT_LOADING = `${namespace}/SET_IS_SCREENSHOT_LOADING`;

export const setOpentokSession = ({ opentokSession }) => ({
  type: SET_OPENTOK_SESSION,
  opentokSession,
});

export const setChangedStream = ({ changedStream }) => ({
  type: SET_CHANGED_STREAM,
  changedStream,
});

export const addConnection = ({ connection }) => ({
  type: ADD_CONNECTION,
  connection,
});

export const removeConnection = ({ connection }) => ({
  type: REMOVE_CONNECTION,
  connection,
});

export const addStream = ({ stream }) => ({
  type: ADD_STREAM,
  stream,
});

export const removeStream = ({ stream }) => ({
  type: REMOVE_STREAM,
  stream,
});
export const setPreviousPublisher = ({ previousPublisher }) => ({
  type: SET_PREVIOUS_PUBLISHER,
  previousPublisher,
});

export const setCallTimer = ({ callTimer }) => ({
  type: SET_CALL_TIMER,
  callTimer,
});

export const setWaitingTimer = ({ waitingTimer }) => ({
  type: SET_WAITING_TIMER,
  waitingTimer,
});

export const resetVideoCallStates = () => ({ type: RESET_VIDEO_CALL_STATES });

export const setHasVideoSession = ({ hasVideoSession }) => ({
  type: SET_HAS_VIDEO_SESSION,
  hasVideoSession,
});

export const setConversationStartingVideoCall = ({
  conversationIdStartingVideoCall,
}) => ({
  type: SET_CONVERSATION_STARTING_VIDEO_CALL,
  conversationIdStartingVideoCall,
});

export const setOngoingVideoCallConversationId = ({
  ongoingVideoCallConversationId,
}) => ({
  type: SET_ONGOING_VIDEO_CALL_CONVERSATION_ID,
  ongoingVideoCallConversationId,
});

export const setIsFloatingVideoCall = ({ isFloatingVideoCall }) => ({
  type: SET_IS_FLOATING_VIDEO_CALL,
  isFloatingVideoCall,
});

export const setIsVideoSessionDataLoading = ({
  isVideoSessionDataLoading,
}) => ({
  type: SET_IS_VIDEO_SESSION_DATA_LOADING,
  isVideoSessionDataLoading,
});

export const setIsScreenshotLoading = ({ isScreenshotLoading }) => ({
  type: SET_IS_SCREENSHOT_LOADING,
  isScreenshotLoading,
});
