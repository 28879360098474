import * as userActions from "@/actions/userActions";
import * as voiceCallActions from "@/actions/voiceCallActions";
import { replaceInArray } from "@/utils/commonUtils";

const defaultState = Object.freeze({
  conversationIdStartingVoiceCall: null,
  conversationIdReconnectingVoiceCallMonitor: null,
  conversationIdSwitchingCallTo: null,
  activeVoiceCallConversationId: null,
  ongoingVoiceCalls: [],
  endedVoiceConversationsMap: {},
});

const voiceCallReducer = (state = defaultState, action) => {
  switch (action.type) {
    case voiceCallActions.SET_CONVERSATION_STARTING_VOICE_CALL: {
      return Object.freeze({
        ...state,
        conversationIdStartingVoiceCall: action.conversationId,
        activeVoiceCallConversationId:
          action.conversationId || state.activeVoiceCallConversationId,
      });
    }

    case voiceCallActions.SET_CONVERSATION_RECONNECTING_VOICE_CALL_MONITOR: {
      return Object.freeze({
        ...state,
        conversationIdReconnectingVoiceCallMonitor: action.conversationId,
      });
    }

    case voiceCallActions.SET_CONVERSATION_ID_SWITCHING_VOICE_CALL_TO: {
      return Object.freeze({
        ...state,
        conversationIdSwitchingCallTo: action.conversationId,
      });
    }

    case voiceCallActions.SET_CONVERSATION_IS_ENDING_VOICE_CALL: {
      const { conversationId, isEndingCall } = action;
      const selectedCallObjIndex = state.ongoingVoiceCalls.findIndex(
        (item) => item.conversationId === conversationId,
      );

      /* Do nothing if index was not found */
      if (selectedCallObjIndex === -1) return state;

      const selectedCallObj = state.ongoingVoiceCalls[selectedCallObjIndex];
      const newCallObj = { ...selectedCallObj, isEndingCall };

      return Object.freeze({
        ...state,
        ongoingVoiceCalls: replaceInArray({
          array: state.ongoingVoiceCalls,
          element: newCallObj,
          index: selectedCallObjIndex,
        }),
      });
    }

    case voiceCallActions.SET_ACTIVE_VOICE_CALL_CONVERSATION_ID: {
      return Object.freeze({
        ...state,
        activeVoiceCallConversationId: action.conversationId,
      });
    }

    case voiceCallActions.SET_CANCEL_VOICE_CALL_INVITE_STATE: {
      const { conversationId, isCancelVoiceCallInviteShown, invitedAt } =
        action;
      const cancelVoiceCallInviteState = {
        isCancelVoiceCallInviteShown,
        invitedAt,
      };

      const selectedCallObjIndex = state.ongoingVoiceCalls.findIndex(
        (item) => item.conversationId === conversationId,
      );

      /* Do nothing if index was not found */
      if (selectedCallObjIndex === -1) return state;

      const selectedCallObj = state.ongoingVoiceCalls[selectedCallObjIndex];
      const newCallObj = { ...selectedCallObj, cancelVoiceCallInviteState };

      return Object.freeze({
        ...state,
        ongoingVoiceCalls: replaceInArray({
          array: state.ongoingVoiceCalls,
          element: newCallObj,
          index: selectedCallObjIndex,
        }),
      });
    }

    case voiceCallActions.ADD_INITIATED_VOICE_CALL: {
      const { callObj } = action;
      const isExistingVoiceCall = state.ongoingVoiceCalls.some(
        (item) => item.conversationId === callObj.conversationId,
      );

      if (isExistingVoiceCall) return state;

      return Object.freeze({
        ...state,
        ongoingVoiceCalls: [...state.ongoingVoiceCalls, callObj],
      });
    }

    case voiceCallActions.UPDATE_ONGOING_VOICE_CALL_DATA: {
      const { conversationId, voiceConversation } = action;
      const selectedCallObjIndex = state.ongoingVoiceCalls.findIndex(
        (item) => item.conversationId === conversationId,
      );

      /* Do nothing if index was not found */
      if (selectedCallObjIndex === -1) return state;

      const selectedCallObj = state.ongoingVoiceCalls[selectedCallObjIndex];
      const newCallObj = { ...selectedCallObj, voiceConversation };

      return Object.freeze({
        ...state,
        ongoingVoiceCalls: replaceInArray({
          array: state.ongoingVoiceCalls,
          element: newCallObj,
          index: selectedCallObjIndex,
        }),
      });
    }

    case voiceCallActions.REMOVE_ONGOING_VOICE_CALL: {
      const { conversationId } = action;
      const selectedCallObj = state.ongoingVoiceCalls.find(
        (item) => item.conversationId === conversationId,
      );

      /* Do nothing if call object is not found */
      if (!selectedCallObj) return state;

      const conversationIdStartingVoiceCall =
        conversationId === state.conversationIdStartingVoiceCall
          ? null
          : state.conversationIdStartingVoiceCall;

      const conversationIdReconnectingVoiceCallMonitor =
        conversationId === state.conversationIdReconnectingVoiceCallMonitor
          ? null
          : state.conversationIdReconnectingVoiceCallMonitor;

      const conversationIdSwitchingCallTo =
        conversationId === state.conversationIdSwitchingCallTo
          ? null
          : state.conversationIdSwitchingCallTo;

      const activeVoiceCallConversationId =
        conversationId === state.activeVoiceCallConversationId
          ? null
          : state.activeVoiceCallConversationId;

      return Object.freeze({
        ...state,
        conversationIdStartingVoiceCall,
        conversationIdReconnectingVoiceCallMonitor,
        conversationIdSwitchingCallTo,
        activeVoiceCallConversationId,
        ongoingVoiceCalls: state.ongoingVoiceCalls.filter(
          (item) => item.conversationId !== conversationId,
        ),
        endedVoiceConversationsMap: {
          ...state.endedVoiceConversationsMap,
          [selectedCallObj.voiceConversation.id]: true,
        },
      });
    }

    case userActions.LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default voiceCallReducer;
