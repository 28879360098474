import React from "react";

import AgentStatusIcon from "@/components/AgentStatusIcon";

const StatusBadge = ({ status }) => {
  const {
    status: { systemStatus },
    canChat,
    canVoiceCall,
    canVideoCall,
  } = status;

  return (
    <AgentStatusIcon
      systemStatus={systemStatus}
      canChat={canChat}
      canVoiceCall={canVoiceCall}
      canVideoCall={canVideoCall}
    />
  );
};

export default StatusBadge;
