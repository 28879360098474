import AddParticipantButton from "./AddParticipantButton";
import AudioButton from "./AudioButton";
import EndCallButton from "./EndCallButton";
import HoldCallButton from "./HoldCallButton";
import LeaveCallButton from "./LeaveCallButton";
import ParticipantAvatar from "./ParticipantAvatar";
import ScreenshotButton from "./ScreenshotButton";
import ShareScreenButton from "./ShareScreenButton";
import SwitchButton from "./SwitchButton";
import SwitchCameraButton from "./SwitchCameraButton";
import VideoButton from "./VideoButton";

export default {
  AudioButton,
  VideoButton,
  ScreenshotButton,
  ShareScreenButton,
  SwitchCameraButton,
  LeaveCallButton,
  ParticipantAvatar,
  HoldCallButton,
  AddParticipantButton,
  EndCallButton,
  SwitchButton,
};
