import { Grid, makeStyles } from "@material-ui/core";

import FormattedContactDisplayName from "@/components/FormattedContactDisplayName";
import TextPreview from "@/components/TextPreview";
import TagList from "./TagList";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "36px",
    padding: "0px 10px 0px 12px",
    borderRadius: "4px 4px 0px 0px",
    backgroundColor: theme.palette.textPrimary.main,
    color: theme.palette.common.white,
  },

  conversationData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "8px",
  },

  contactName: {
    flex: 1,
    minWidth: "40px",
    marginRight: "15px",
    fontSize: "1.23rem",
    fontWeight: "500",
    color: theme.palette.common.white,
  },

  groupNameText: {
    flex: 1,
    minWidth: "40px",
    fontSize: "0.92rem",
    fontStyle: "italic",
  },
}));

const Header = ({ contact, group, conversationTags }) => {
  const classes = useStyles();

  const contactDisplayName = contact?.displayName || "";

  return (
    <Grid
      className={classes.header}
      item
      container
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <TextPreview
        ContainerProps={{ className: classes.contactName }}
        TooltipV2Props={{
          BaseTooltipProps: {
            title: (
              <FormattedContactDisplayName displayName={contactDisplayName} />
            ),
            placement: "top",
          },
        }}
      >
        <FormattedContactDisplayName displayName={contactDisplayName} />
      </TextPreview>

      <div className={classes.conversationData}>
        <TagList conversationTags={conversationTags} />

        {group && (
          <TextPreview
            ContainerProps={{ className: classes.groupNameText }}
            TooltipV2Props={{
              BaseTooltipProps: { title: group.name, placement: "top" },
            }}
          >
            {group.name}&nbsp;
          </TextPreview>
        )}
      </div>
    </Grid>
  );
};

export default Header;
