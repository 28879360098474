import { Paper, makeStyles } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import clsx from "clsx";
import { useMemo } from "react";

import ErrorIcon from "@/assets/images/icon-alertWithBorder.svg";
import SuccessIcon from "@/assets/images/icon-success.svg";
import { componentVariantEnum, severityEnum } from "@/enums/styleVariantEnum";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: "5px",
    padding: "17px 30px 17px 15px",
    color: theme.palette.common.white,
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  filled: {
    backgroundColor: theme.palette.grey[1800],
  },
  transparent: {
    backgroundColor: "none",
  },
  success: {
    borderColor: theme.palette.primaryBorder.main,
  },

  iconContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    marginRight: "10px",
  },
}));

const Alert = ({
  children,
  severity,
  variant = componentVariantEnum.filled,
}) => {
  const classes = useStyles();

  const alertIcon = useMemo(() => {
    switch (severity) {
      case severityEnum.success:
        return <SuccessIcon fontSize="inherit" />;
      case severityEnum.error:
        return <ErrorIcon />;

      default:
        return null;
    }
  }, [severity]);

  return (
    <Grow in>
      <Paper
        elevation={0}
        className={clsx(
          classes.root,
          classes[`${severity}`],
          classes[`${variant}`],
        )}
      >
        {alertIcon && <div className={classes.iconContainer}>{alertIcon}</div>}

        {children}
      </Paper>
    </Grow>
  );
};

export default Alert;
