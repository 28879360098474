import { useMutation, useSubscription } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import * as hookActions from "@/actions/hookActions";
import * as hookMutations from "@/mutations/hookMutations";
import * as hookQueries from "@/queries/hookQueries";
import * as userSelectors from "@/selectors/userSelectors";

const HookSagaProvider = ({ children }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(userSelectors.accessToken);

  const [runHook] = useMutation(hookMutations.RUN_HOOK);

  useSubscription(hookQueries.REGISTER_HOOK_SUBSCRIPTION, {
    skip: !accessToken,
    errorPolicy: "all",
    onData: ({ data }) => {
      const hookData = data.data?.onRegisterHook;
      if (!hookData) return;
      dispatch(hookActions.registerHook({ ...hookData, runHook }));
    },
  });

  return <>{children}</>;
};

export default HookSagaProvider;
