import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import InfoIcon from "@/assets/images/icon-info-grey.svg";
import AlertIcon from "@/assets/images/msg-alert.svg";
import { serviceStatusSeverityEnum } from "@/enums/networkEnum";

const useStyles = makeStyles((theme) => ({
  severityIcon: {
    float: "inline-start",
    height: "18px",
    width: "18px",
    margin: "0px 8px",
    "&.minor": {
      "& path": {
        fill: theme.palette.primaryBackground[720],
      },
    },
    "&.major": {
      "& path": {
        fill: theme.palette.textSecondary[1000],
      },
    },
    "&.critical": {
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
}));

const SeverityIcon = ({ severity }) => {
  const classes = useStyles();

  const isMinor = severity === serviceStatusSeverityEnum.minor;
  const Icon = isMinor ? InfoIcon : AlertIcon;

  return <Icon className={clsx(classes.severityIcon, { [severity]: true })} />;
};

export default SeverityIcon;
