import * as conversationSidePaneActions from "@/actions/conversationSidePaneActions";
import { LOG_OUT_SUCCESS } from "@/actions/userActions";

const defaultState = Object.freeze({
  renderedChildId: null,
});

const conversationSidePaneReducer = (state = defaultState, action) => {
  switch (action.type) {
    case conversationSidePaneActions.TOGGLE_SECTION: {
      return Object.freeze({
        ...state,
        renderedChildId: action.renderedChildId,
      });
    }

    case conversationSidePaneActions.RESET_CONVERSATION_SIDE_PANE: {
      return defaultState;
    }

    case LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default conversationSidePaneReducer;
