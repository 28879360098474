const namespace = "@/voiceCallActions";

export const SET_CONVERSATION_STARTING_VOICE_CALL = `${namespace}/SET_CONVERSATION_STARTING_VOICE_CALL`;
export const SET_CONVERSATION_RECONNECTING_VOICE_CALL_MONITOR = `${namespace}/SET_CONVERSATION_RECONNECTING_VOICE_CALL_MONITOR`;
export const SET_CONVERSATION_ID_SWITCHING_VOICE_CALL_TO = `${namespace}/SET_CONVERSATION_ID_SWITCHING_VOICE_CALL_TO`;
export const SET_CONVERSATION_IS_ENDING_VOICE_CALL = `${namespace}/SET_CONVERSATION_IS_ENDING_VOICE_CALL`;
export const SET_ACTIVE_VOICE_CALL_CONVERSATION_ID = `${namespace}/SET_ACTIVE_VOICE_CALL_CONVERSATION_ID`;
export const SET_CANCEL_VOICE_CALL_INVITE_STATE = `${namespace}/SET_CANCEL_VOICE_CALL_INVITE_STATE`;

export const ADD_INITIATED_VOICE_CALL = `${namespace}/ADD_INITIATED_VOICE_CALL`;
export const UPDATE_ONGOING_VOICE_CALL_DATA = `${namespace}/UPDATE_ONGOING_VOICE_CALL_DATA`;
export const REMOVE_ONGOING_VOICE_CALL = `${namespace}/REMOVE_ONGOING_VOICE_CALL`;

export const setConversationStartingVoiceCall = ({ conversationId }) => ({
  type: SET_CONVERSATION_STARTING_VOICE_CALL,
  conversationId,
});

export const setConversationReconnectingVoiceCallMonitor = ({
  conversationId,
}) => ({
  type: SET_CONVERSATION_RECONNECTING_VOICE_CALL_MONITOR,
  conversationId,
});

export const setConversationIdSwitchingVoiceCallTo = ({ conversationId }) => ({
  type: SET_CONVERSATION_ID_SWITCHING_VOICE_CALL_TO,
  conversationId,
});

export const setConversationIsEndingCall = ({
  conversationId,
  isEndingCall,
}) => ({
  type: SET_CONVERSATION_IS_ENDING_VOICE_CALL,
  conversationId,
  isEndingCall,
});

export const setActiveVoiceCallConversationId = ({ conversationId }) => ({
  type: SET_ACTIVE_VOICE_CALL_CONVERSATION_ID,
  conversationId,
});

export const setCancelVoiceCallInviteState = ({
  conversationId,
  isCancelVoiceCallInviteShown,
  invitedAt,
}) => ({
  type: SET_CANCEL_VOICE_CALL_INVITE_STATE,
  conversationId,
  isCancelVoiceCallInviteShown,
  invitedAt,
});

export const addInitiatedVoiceCall = ({
  conversationId,
  voiceConversation,
}) => ({
  type: ADD_INITIATED_VOICE_CALL,
  callObj: { conversationId, voiceConversation, isEndingCall: false },
});

export const updateOngoingVoiceCall = ({
  conversationId,
  voiceConversation,
}) => ({
  type: UPDATE_ONGOING_VOICE_CALL_DATA,
  conversationId,
  voiceConversation,
});

export const removeOngoingVoiceCall = ({ conversationId }) => ({
  type: REMOVE_ONGOING_VOICE_CALL,
  conversationId,
});
