const namespace = "@/publicVideoCallActions";

export const SET_OPENTOK_SESSION = `${namespace}/SET_OPENTOK_SESSION`;
export const SET_CHANGED_STREAM = `${namespace}/SET_CHANGED_STREAM`;
export const ADD_CONNECTION = `${namespace}/ADD_CONNECTION`;
export const REMOVE_CONNECTION = `${namespace}/REMOVE_CONNECTION`;
export const ADD_STREAM = `${namespace}/ADD_STREAM`;
export const REMOVE_STREAM = `${namespace}/REMOVE_STREAM`;
export const RESET_VIDEO_CALL_STATES = `${namespace}/RESET_VIDEO_CALL_STATES`;

export const setOpentokSession = ({ opentokSession }) => ({
  type: SET_OPENTOK_SESSION,
  opentokSession,
});

export const setChangedStream = ({ changedStream }) => ({
  type: SET_CHANGED_STREAM,
  changedStream,
});

export const addConnection = ({ connection }) => ({
  type: ADD_CONNECTION,
  connection,
});

export const removeConnection = ({ connection }) => ({
  type: REMOVE_CONNECTION,
  connection,
});

export const addStream = ({ stream }) => ({
  type: ADD_STREAM,
  stream,
});

export const removeStream = ({ stream }) => ({
  type: REMOVE_STREAM,
  stream,
});

export const resetVideoCallStates = () => ({ type: RESET_VIDEO_CALL_STATES });
