import React from "react";

import AddParticipantIcon from "@/assets/images/icon-add-participant.svg";
import IconAvatar from "@/components/Call/CallControlBarIcons/IconAvatar";

const AddParticipantButton = ({
  isControlDisabled,
  TooltipProps = {},
  onClick,
}) => {
  return (
    <IconAvatar
      isEnabled={true}
      isControlDisabled={isControlDisabled}
      TooltipProps={TooltipProps}
      onClick={onClick}
    >
      <AddParticipantIcon />
    </IconAvatar>
  );
};

export default AddParticipantButton;
