import React, { useMemo } from "react";

import VideoOffIcon from "@/assets/images/icon-video-off.svg";
import VideoOnIcon from "@/assets/images/icon-video.svg";
import IconAvatar from "./IconAvatar";

const VideoButton = ({
  isPrivateCall,
  isControlDisabled,
  hasVideo,
  onClick,
}) => {
  const size = useMemo(() => {
    if (isPrivateCall && hasVideo) return { width: "20px", height: "15px" };
    if (isPrivateCall && !hasVideo) return { width: "18px", height: "14px" };
    if (!isPrivateCall && hasVideo) return { width: "26px", height: "21px" };
    if (!isPrivateCall && !hasVideo) return { width: "24px", height: "20px" };
  }, [isPrivateCall, hasVideo]);

  return (
    <IconAvatar
      size={isPrivateCall ? "36px" : "56px"}
      isEnabled={hasVideo}
      isControlDisabled={isControlDisabled}
      onClick={onClick}
    >
      {hasVideo ? <VideoOnIcon {...size} /> : <VideoOffIcon {...size} />}
    </IconAvatar>
  );
};

export default VideoButton;
