import * as Sentry from "@sentry/nextjs";
import { all, call, put, takeLatest } from "redux-saga/effects";

import * as userActions from "@/actions/userActions";

function* unregisterServiceWorker() {
  if (!("serviceWorker" in navigator)) return;

  try {
    const registration = yield navigator.serviceWorker.getRegistration();
    const subscription = yield registration?.pushManager?.getSubscription();
    if (subscription) yield subscription.unsubscribe();
  } catch (error) {
    console.error(error);
    return;
  }
}

function* logOut() {
  yield call(unregisterServiceWorker);
  localStorage.removeItem("token");
  yield put(userActions.logOutSuccess());
}

function* watchLogOut() {
  try {
    yield takeLatest(userActions.LOG_OUT_REQUEST, logOut);
  } catch (error) {
    Sentry.captureException(
      new Error("Unhandled error in userSaga: 'watchLogOut'"),
    );
  }
}

export default function* userSaga() {
  yield all([watchLogOut()]);
}
