import { useEffect, useMemo, useState } from "react";

import { useValueRef } from "@/utils/hookUtils";
import * as videoCallUtils from "@/utils/videoCallUtils";

const usePublisher = ({ opentokSession, containerId, changedStream }) => {
  const [publisher, setPublisher] = useState(null);
  const [stream, setStream] = useState(null);

  const layoutManager = useMemo(() => {
    return videoCallUtils.getLayoutManager(containerId);
  }, [containerId]);

  const handleDestroyed = () => {
    setPublisher(null);
  };

  const handleStreamCreated = ({ stream }) => {
    setStream(stream);
  };

  const handleStreamDestroyed = () => {
    setStream(null);
  };

  const publish = async ({ PublisherProps } = {}) => {
    try {
      /* Use dynamic import since Opentok requires window object & it's not available in SSR */
      const OT = await import("@opentok/client");

      /* Reference: https://tokbox.com/developer/guides/publish-stream/js/#create_publisher */
      const properties = {
        insertMode: "append",
        width: "100%",
        height: "100%",
        showControls: false,
        mirror: false,
        style: { buttonDisplayMode: "off" },
        ...PublisherProps,
      };

      const newPublisher = await new Promise((resolve, reject) => {
        const publisher = OT.initPublisher(containerId, properties, (error) => {
          if (error) reject(error);
          else resolve(publisher);
        });
      });

      /* Listen to publisher events - https://tokbox.com/developer/sdks/js/reference/Publisher.html */
      newPublisher.on("destroyed", handleDestroyed);
      newPublisher.on("streamCreated", handleStreamCreated);
      newPublisher.on("streamDestroyed", handleStreamDestroyed);

      await new Promise((resolve, reject) => {
        opentokSession.publish(newPublisher, (error) => {
          if (error) {
            newPublisher.off();
            reject(error);
          } else resolve();
        });
      });

      setPublisher(newPublisher);
    } catch (error) {
      console.error(error);

      if (error.name === "OT_USER_MEDIA_ACCESS_DENIED") {
        alert("Please enable camera and microphone access to continue.");
      }
    }
  };

  /* Used for unpublishing shared-screen publisher */
  const unpublish = async () => {
    if (publisher) opentokSession.unpublish(publisher);

    /* Resize the layout container after unpublishing */
    if (layoutManager) layoutManager.layout();
  };

  const valueRefs = useValueRef({ stream });

  /* Update stream whenever changedStream is updated eg. when call audio/video is toggled */
  useEffect(() => {
    const { stream } = valueRefs.current;

    if (!changedStream || !stream) return;
    if (changedStream.id !== valueRefs.current.stream.id) return;

    setStream(changedStream);
  }, [valueRefs, changedStream]);

  return {
    publisher,
    stream,
    publish,
    unpublish,
  };
};
export default usePublisher;
