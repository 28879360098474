import { useMutation } from "@apollo/client";
import moment from "moment";
import { createContext, useCallback, useContext, useMemo } from "react";

import { severityEnum } from "@/enums/styleVariantEnum";
import { CREATE_HELP_SCOUT_TOKEN } from "@/mutations/helpScoutMutations";
import { openInNewTab } from "@/utils/commonUtils";
import { useValueRef } from "@/utils/hookUtils";
import { useAppSnackbar } from "./Snackbar/SnackbarProvider";

const HelpScoutContext = createContext();

const HelpScoutProvider = ({ children }) => {
  const { onSetAppSnackbarProps } = useAppSnackbar();

  const getKnowledgeBaseUrl = ({ token }) => {
    return `https://talkhub.helpscoutdocs.com/authcallback?token=${token}`;
  };

  const [
    createHelpScoutToken,
    { data: { createHelpScoutToken: { token, expiresAt } = {} } = {} },
  ] = useMutation(CREATE_HELP_SCOUT_TOKEN, {
    onCompleted: ({ createHelpScoutToken }) => {
      const { token } = createHelpScoutToken;

      const url = getKnowledgeBaseUrl({ token });
      openInNewTab(url);
    },
    onError: ({ message }) => {
      onSetAppSnackbarProps({
        message: message,
        AlertProps: { severity: severityEnum.error },
      });
    },
  });

  const valueRefs = useValueRef({ token, expiresAt, createHelpScoutToken });

  const handleOpenKnowledgeBase = useCallback(() => {
    const { token, expiresAt, createHelpScoutToken } = valueRefs.current;

    const isMutationDataFetched = Boolean(token && expiresAt);
    const isHelpScoutTokenExpired = moment(expiresAt).isBefore(moment());

    /* Reuse the token if it's not expired yet in consecutive clicks */
    if (isMutationDataFetched && !isHelpScoutTokenExpired) {
      const url = getKnowledgeBaseUrl({ token });
      openInNewTab(url);
      return;
    }

    createHelpScoutToken();
  }, [valueRefs]);

  const helpScoutContextValue = useMemo(() => {
    return {
      onOpenKnowledgeBase: handleOpenKnowledgeBase,
    };
  }, [handleOpenKnowledgeBase]);

  return (
    <HelpScoutContext.Provider value={helpScoutContextValue}>
      {children}
    </HelpScoutContext.Provider>
  );
};

export const useHelpScout = () => useContext(HelpScoutContext);

export default HelpScoutProvider;
