import * as userActions from "@/actions/userActions";

const defaultState = Object.freeze({
  accessToken: null,
  isShortcutModalOpen: false,
  chatMessageEditorDrafts: {},
});

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case userActions.GET_ACCESS_TOKEN_SUCCESS: {
      return Object.freeze({
        ...state,
        accessToken: action.accessToken,
      });
    }

    case userActions.SET_SHORTCUT_MODAL_STATE: {
      return Object.freeze({
        ...state,
        isShortcutModalOpen: action.isShortcutModalOpen,
      });
    }

    case userActions.SET_CHAT_MESSAGE_EDITOR_DRAFT: {
      return Object.freeze({
        ...state,
        chatMessageEditorDrafts: {
          ...state.chatMessageEditorDrafts,
          [action.key]: action.value,
        },
      });
    }

    case userActions.LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
