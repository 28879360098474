import { makeStyles } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/nextjs";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

import FloatingVideoCallWidget from "@/components/Call/VideoCall/FloatingVideoCallWidget";
import FloatingVoiceCallWidget from "@/components/Call/VoiceCall/FloatingVoiceCallWidget";
import { usePrivateOngoingVideoCallSessionContext } from "@/contextProviders/CallProvider/PrivateVideoCallProvider";
import * as VoiceCallProvider from "@/contextProviders/CallProvider/VoiceCallProvider";
import * as privateVideoCallSelectors from "@/selectors/privateVideoCallSelectors";
import { getOngoingVideoCall } from "@/utils/videoCallUtils";
import FloatingVoiceCallWidgetError from "../VoiceCall/FloatingVoiceCallWidget/FloatingVoiceCallWidgetError";

const useStyles = makeStyles(() => ({
  floatingWidgetContainer: {
    position: "absolute",
    zIndex: 1000,
    bottom: "25px",
    right: "25px",
    width: "310px",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "0px 3px 6px",
  },
}));

const FloatingCallWidgetContainer = () => {
  const classes = useStyles();

  const isFloatingVideoCall = useSelector(
    privateVideoCallSelectors.isFloatingVideoCall,
  );

  const {
    ongoingVoiceCalls,
    onHandleConversationSwitch,
    onSetConversationIdStartAndSwitchVoiceCall,
  } = VoiceCallProvider.useFloatingCallWidgetContainerContext();

  const { ongoingVideoSession } = usePrivateOngoingVideoCallSessionContext();

  /* Check if there is an ongoing calls each time conversation is switched */
  const ongoingVideoCall = useMemo(() => {
    return getOngoingVideoCall({
      ongoingVideoSession,
      isFloatingVideoCall,
    });
  }, [ongoingVideoSession, isFloatingVideoCall]);

  const isHidden = isEmpty(ongoingVoiceCalls) && isEmpty(ongoingVideoCall);

  if (isHidden) return null;

  return (
    <Draggable bounds="parent">
      <div className={classes.floatingWidgetContainer}>
        {ongoingVideoCall && (
          <FloatingVideoCallWidget ongoingVideoCall={ongoingVideoCall} />
        )}

        <ErrorBoundary fallback={<FloatingVoiceCallWidgetError />}>
          <FloatingVoiceCallWidget
            ongoingVoiceCalls={ongoingVoiceCalls}
            onHandleConversationSwitch={onHandleConversationSwitch}
            onSetConversationIdStartAndSwitchVoiceCall={
              onSetConversationIdStartAndSwitchVoiceCall
            }
          />
        </ErrorBoundary>
      </div>
    </Draggable>
  );
};
export default FloatingCallWidgetContainer;
