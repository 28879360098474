export const crmBuiltInEntityTypeEnum = Object.freeze({
  agent: "Agent",
  contact: "Contact",
  contentTypeAllowedTag: "ContentTypeAllowedTag",
  messagingProvider: "MessagingProvider",
});

export const crmFieldDataTypeEnum = Object.freeze({
  SINGLE_LINE_TEXT: "SINGLE_LINE_TEXT",
  MULTI_LINE_TEXT: "MULTI_LINE_TEXT",
  EMAIL: "EMAIL",
  PHONE_NUMBER: "PHONE_NUMBER",
  BOOLEAN: "BOOLEAN",
  DATETIME: "DATETIME",
  DECIMAL: "DECIMAL",
  DATE: "DATE",
  ENUM: "ENUM" /* Single string */,
  FILE: "FILE",
  IMAGE: "IMAGE",
  URL: "URL",
  JSON: "JSON" /* Object */,
  FK: "FK" /* Object */,
  M2M_FK: "M2M_FK" /* Array */,
});

export const crmFieldScopeEnum = Object.freeze({
  /* Built-in fields that can be modified but not deleted by the user. */
  protected: "PROTECTED",

  /* Custom fields that can be modified and deleted by the user. */
  standard: "STANDARD",

  /*
    Built-in fields for system data that cannot be modified or deleted by the user.
    View only.
  */
  system: "SYSTEM",
});

export const relatedEventFilterContentTypeEnum = Object.freeze({
  conversation: "CONVERSATION",
  voiceconversation: "VOICECONVERSATION",
});

export const instanceFormEnum = Object.freeze({
  export: "export",
  import: "import",
  create: "create",
});

export const csvImportHistoryStatusEnum = Object.freeze({
  success: "SUCCESS",
  failed: "FAILED",

  /* Below are "ongoing" statuses. */
  received: "RECEIVED",
  started: "STARTED",
});

export const csvImportModeEnum = Object.freeze({
  update: "update",
  skip: "skip",
});

export const crmSelectedInstancesOperationEnum = Object.freeze({
  add: "add",
  remove: "remove",
});

export const csvImportListOperationEnum = Object.freeze({
  addToList: "add_to_list",
  removeFromList: "remove_from_list",
});

export const crmContactTagBulkOperationResponseEnum = Object.freeze({
  CRM_CONTACT_TAG_BULK_ADD_SUCCESS: "CRM_CONTACT_TAG_BULK_ADD_SUCCESS",
  CRM_CONTACT_TAG_BULK_ADD_FAILURE: "CRM_CONTACT_TAG_BULK_ADD_FAILURE",
  CRM_CONTACT_TAG_BULK_REMOVE_SUCCESS: "CRM_CONTACT_TAG_BULK_REMOVE_SUCCESS",
  CRM_CONTACT_TAG_BULK_REMOVE_FAILURE: "CRM_CONTACT_TAG_BULK_REMOVE_FAILURE",
});

export const crmInstanceListBulkOperationResponseEnum = Object.freeze({
  CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_SUCCESS:
    "CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_SUCCESS",
  CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_FAILURE:
    "CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_FAILURE",
  CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_SUCCESS:
    "CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_SUCCESS",
  CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_FAILURE:
    "CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_FAILURE",
});
