import { gql } from "@apollo/client";

import { agentOnlineDataDefinition } from "@/definitions/agentOnlineDataDefinition";
import { agentStatusConfigurationDefinition } from "@/definitions/agentStatus/agentStatusDefinition";

export const GET_AGENT_ONLINE_DATA = gql`
  query GetAgentOnlineData {
    agentOnlineData ${agentOnlineDataDefinition}
  }
`;

export const GET_AGENT_STATUS_CONFIGURATION = gql`
  query GetAgentStatusConfiguration {
    agentStatusConfiguration ${agentStatusConfigurationDefinition}
  }
`;
