import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  hintContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&.whiteOption": {
      fontWeight: "600",
    },
  },

  shortcutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    backgroundColor: theme.palette.common.black,
    padding: "5px",
    textTransform: "capitalize",
    "&.whiteOption": {
      fontWeight: "400",
      fontStyle: "italic",
      backgroundColor: "transparent",
    },
  },
}));

const Hint = ({ whiteOption, title, shortcut }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.hintContainer, { whiteOption })}>
      <div>{title}</div>
      {shortcut && (
        <div className={clsx(classes.shortcutContainer, { whiteOption })}>
          {shortcut}
        </div>
      )}
    </div>
  );
};

export default React.memo(Hint);
