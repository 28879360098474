import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import CloseIcon from "@/assets/images/btn-close-modal.svg";
import EmailIcon from "@/assets/images/icon-email.svg";
import { useServiceStatus } from "@/contextProviders/ServiceStatusProvider";
import SeverityIcon from "./SeverityIcon";

const useStyles = makeStyles((theme) => ({
  serviceStatusIndicatorContainer: {
    textAlign: "center",
    padding: "10px",
    "&.minor": {
      color: theme.palette.textPrimary.main,
      background: `${theme.palette.serviceStatus.minor} 0% 0% no-repeat padding-box`,
    },
    "&.major": {
      color: theme.palette.textSecondary[1000],
      background: `${theme.palette.serviceStatus.major} 0% 0% no-repeat padding-box`,
    },
    "&.critical": {
      color: theme.palette.common.white,
      background: `${theme.palette.serviceStatus.critical} 0% 0% no-repeat padding-box`,
    },
  },

  serviceStatusDescriptionContainer: {
    display: "flex",
    padding: "0px 99px 0px 130px",
    justifyContent: "center",
  },

  serviceStatusDescription: {
    lineHeight: "18px",
    textAlign: "center",
    maxWidth: "990px",
  },

  contactUsLink: {
    display: "inline",
    textDecoration: "none",
    fontWeight: "normal",
    padding: "0px 5px",
    color: theme.palette.link.main,
    "& svg": {
      margin: "0px 5px",
      "& path": {
        fill: theme.palette.link.main,
      },
    },
    "&.critical": {
      color: theme.palette.common.white,
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },

  closeButton: {
    float: "inline-end",
    padding: "unset",
    "&.minor": {
      "& path": {
        fill: theme.palette.primaryBackground[720],
      },
    },
    "&.major": {
      "& path": {
        fill: theme.palette.textSecondary[1000],
      },
    },
    "&.critical": {
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
}));

const ServiceStatusIndicator = () => {
  const classes = useStyles();

  const {
    serviceStatusData: { description, severity } = {},
    onCloseServiceStatusIndicator,
  } = useServiceStatus();

  return (
    <div
      className={clsx(classes.serviceStatusIndicatorContainer, {
        [severity]: true,
      })}
    >
      <IconButton
        className={clsx(classes.closeButton, {
          [severity]: true,
        })}
        onClick={onCloseServiceStatusIndicator}
      >
        <CloseIcon height={18} width={11} />
      </IconButton>

      <div className={classes.serviceStatusDescriptionContainer}>
        <p className={classes.serviceStatusDescription}>
          <SeverityIcon severity={severity} />
          {description}

          <a
            className={clsx(classes.contactUsLink, {
              [severity]: true,
            })}
            href="mailto:support@talkhub.io"
          >
            <EmailIcon />
            Contact us
          </a>
        </p>
      </div>
    </div>
  );
};

export default ServiceStatusIndicator;
