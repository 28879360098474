import { Grid, makeStyles } from "@material-ui/core";

import TextPreview from "@/components/TextPreview";
import AcceptButton from "./AcceptButton";
import DeclineButton from "./DeclineButton";

const useStyles = makeStyles((theme) => ({
  floatingInboundCallBody: {
    flex: 1,
    border: `1px solid ${theme.palette.primaryBorder[1800]}`,
    borderRadius: "0px 0px 4px 4px",
  },

  bodyHeader: {
    marginBottom: "4px",
    fontSize: "1.23rem",
    fontWeight: "700",
    lineHeight: "1.54rem",
    color: theme.palette.textPrimary.main,
  },

  voiceProviderName: {
    margin: "0 10px 10px",
    color: theme.palette.grey[1750],
  },

  actionButtons: {
    gap: "16px",
    marginBottom: "12px",
  },

  description: {
    textAlign: "center",
    color: theme.palette.grey[1750],
  },
}));

const Body = ({
  voiceProviderName,
  isAgentMonitoringAnyCall,
  isAnyVoiceCallStarting,
  isCallTransfer,
  isEndingCall,
  isStartingCall,
  isVideoCallInUse,
  onAnswerIncomingCall,
  onRejectIncomingCall,
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.floatingInboundCallBody}
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <TextPreview
        lineClamp={2}
        ContainerProps={{ className: classes.bodyHeader }}
      >
        {isCallTransfer ? "Call transferred" : "Incoming voice call"}&hellip;
      </TextPreview>

      <TextPreview
        ContainerProps={{ className: classes.voiceProviderName }}
        TooltipV2Props={{
          BaseTooltipProps: { title: voiceProviderName, placement: "left" },
        }}
      >
        {voiceProviderName}
      </TextPreview>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        className={classes.actionButtons}
      >
        <AcceptButton
          onClick={onAnswerIncomingCall}
          isDisabled={isAnyVoiceCallStarting || isVideoCallInUse}
          buttonText={isStartingCall ? "Joining" : "Accept"}
        />
        <DeclineButton
          onClick={onRejectIncomingCall}
          isDisabled={isEndingCall}
        />
      </Grid>

      <div className={classes.description}>
        {isAgentMonitoringAnyCall
          ? "You will leave the monitored call and accept incoming call."
          : "The call will start as soon as you accept."}
      </div>
    </Grid>
  );
};
export default Body;
