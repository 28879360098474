import { isEmpty } from "lodash";
import React from "react";

import ScreenshotIcon from "@/assets/images/icon-screencap.svg";
import IconAvatar from "./IconAvatar";

/* Avatar size never change because only used for public video call */
const ScreenshotButton = ({
  cameraSubscribers,
  isLoading,
  isControlDisabled,
  onClick,
}) => {
  if (isEmpty(cameraSubscribers)) return null;

  return (
    <IconAvatar
      size="36px"
      isEnabled={true}
      isLoading={isLoading}
      isControlDisabled={isControlDisabled}
      onClick={onClick}
    >
      <ScreenshotIcon width="18px" height="17px" />
    </IconAvatar>
  );
};

export default ScreenshotButton;
