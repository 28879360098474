import { useMemo } from "react";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import persistedRootReducer from "../reducers";
import rootSaga from "../sagas";

export let store;

function makeStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  store = createStore(
    persistedRootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? makeStore(preloadedState);

  /* 
    After navigating to a page with an initial Redux state, merge that state
    with the current state in the store, and create a new store 
  */
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    /* Reset the current store */
    store = undefined;
  }

  /* For SSG and SSR always create a new store */
  if (typeof window === "undefined") return _store;
  /* Create the store once in the client */
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
