import { useTheme } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";
import { useWatch } from "react-hook-form";

import Button from "@/components/Button";

const InviteParticipantActionButtons = ({
  control,
  loading,
  onAddParticipants,
  onModalClose,
}) => {
  const theme = useTheme();
  const voiceCallCandidates = useWatch({
    control,
    name: "voiceCallCandidates",
  });

  return (
    <>
      {!isEmpty(voiceCallCandidates) && (
        <Button
          width="146px"
          height="36px"
          fontSize="1rem"
          isLoading={loading}
          isIconAlignCenter={true}
          onClick={onAddParticipants}
        >
          {!loading && "Add to call"}
        </Button>
      )}
      <Button
        width="146px"
        height="36px"
        fontSize="1rem"
        backgroundColor={{
          normal: theme.palette.grey[1000],
          hover: theme.palette.grey[1500],
        }}
        onClick={onModalClose}
      >
        Cancel
      </Button>
    </>
  );
};

export default InviteParticipantActionButtons;
