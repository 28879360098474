import { makeStyles, useTheme } from "@material-ui/core";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

import * as conversationActions from "@/actions/conversationActions";
import * as privateVideoCallActions from "@/actions/privateVideoCallActions";
import InputBackIcon from "@/assets/images/btn-back-input.svg";
import FormattedContactDisplayName from "@/components/FormattedContactDisplayName";
import TextPreview from "@/components/TextPreview";
import allowedInboxViewEnum from "@/enums/allowedInboxViewEnum";
import { conversationStatusEnum } from "@/enums/conversation";
import {
  getCurrentConversationIdFromRouter,
  goToInboxPageWithRouter,
} from "@/utils/inboxPageRoutingUtils";
import CallDuration from "../CallDuration";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    height: "37px",
    margin: "0px 11px 0px 8px",
  },

  contactDisplayName: {
    display: "flex",
    alignItems: "center",
    maxWidth: "50%",
    padding: "0px 4px 2px 2px",
    borderBottom: `1px solid ${theme.palette.common.white}`,
  },

  icon: {
    width: "11px",
    height: "13px",
    marginRight: "8px",
    "& path": {
      fill: theme.palette.common.white,
    },
  },
}));

const Header = ({ contactDisplayName, conversationId, hasContactJoined }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();

  const { inboxViewSection } = router?.query || {};

  const handleConversationSwitch = () => {
    const currentConversationId = getCurrentConversationIdFromRouter({
      pathname: router.pathname,
      params: router.query.params,
      strict: true,
    });

    const isSameConversation = conversationId === currentConversationId;

    if (isSameConversation) {
      dispatch(
        privateVideoCallActions.setIsFloatingVideoCall({
          isFloatingVideoCall: false,
        }),
      );
      return;
    }

    const queryValuesToUpdate = {
      inboxViewSection,
      inboxViewId: allowedInboxViewEnum.me,
      status: conversationStatusEnum.active,
      params: conversationId ? [conversationId] : "",
    };

    goToInboxPageWithRouter({ router, queryValuesToUpdate });

    dispatch(conversationActions.resetConversation());

    dispatch(
      privateVideoCallActions.setIsFloatingVideoCall({
        isFloatingVideoCall: true,
      }),
    );
  };

  return (
    <div className={classes.header} onClick={handleConversationSwitch}>
      <div className={classes.contactDisplayName}>
        <div className={classes.icon}>
          <InputBackIcon />
        </div>

        <TextPreview
          ContainerProps={{
            style: {
              fontSize: "1.08rem",
              fontWeight: "500",
              color: theme.palette.common.white,
            },
          }}
          TooltipV2Props={{
            BaseTooltipProps: {
              title: (
                <FormattedContactDisplayName displayName={contactDisplayName} />
              ),
              placement: "top",
            },
          }}
        >
          <FormattedContactDisplayName displayName={contactDisplayName} />
        </TextPreview>
      </div>

      <CallDuration hasContactJoined={hasContactJoined} />
    </div>
  );
};
export default Header;
