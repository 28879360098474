import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";

import ContactIcon from "@/assets/images/contact-msg-icon.svg";
import FormattedContactDisplayName from "@/components/FormattedContactDisplayName";
import TextPreview from "@/components/TextPreview";
import { equalPropsWith } from "@/utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  option: {
    display: "flex",
    alignItems: "center",
  },

  contactDisplayName: {
    padding: "0px 6px 0px 8px",
  },

  contactIcon: {
    "& path": {
      fill: theme.palette.textPrimary.main,
    },
  },

  tooltip: {
    marginLeft: "auto",
  },
}));

const ContactOption = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  /* isDefault = the default/primary phone number of the contact in case contact have multiple phone numbers */
  const { contact, isDefault, phoneNumber } = data;

  return (
    <div className={classes.option}>
      <ContactIcon className={classes.contactIcon} />
      <TextPreview
        ContainerProps={{ className: classes.contactDisplayName }}
        TooltipV2Props={{
          BaseTooltipProps: {
            title: (
              <FormattedContactDisplayName displayName={contact?.displayName} />
            ),
          },
        }}
      >
        <FormattedContactDisplayName displayName={contact?.displayName} />
      </TextPreview>

      <TextPreview
        ContainerProps={{
          style: { fontSize: "0.85rem", color: theme.palette.grey[1710] },
        }}
        TooltipV2Props={{
          BaseTooltipProps: {
            title: <FormattedContactDisplayName displayName={phoneNumber} />,
          },
        }}
      >
        <FormattedContactDisplayName displayName={phoneNumber} />
        &nbsp;
        {isDefault && "(default)"}
      </TextPreview>
    </div>
  );
};

const isContactOptionEqual = (prevProps, nextProps) =>
  equalPropsWith(prevProps, nextProps, [
    "data.contact.id",
    "data.candidateType",
  ]);

export default React.memo(ContactOption, isContactOptionEqual);
