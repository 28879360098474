import * as inboxPageActions from "@/actions/inboxPageActions";
import * as userActions from "@/actions/userActions";

const defaultState = Object.freeze({
  shouldExcludeBlastConversations: false,
});

const inboxPageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case inboxPageActions.SET_EXCLUDE_BLAST_CONVERSATIONS_FILTER: {
      return Object.freeze({
        ...state,
        shouldExcludeBlastConversations: action.shouldExcludeBlastConversations,
      });
    }

    case inboxPageActions.RESET_INBOX_PAGE_REDUCER: {
      return defaultState;
    }

    case userActions.LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default inboxPageReducer;
