export const slashCommandDefinition = `{
    keyword
    parameters
    description
    hints {
      keyword
      parameters
      description
    }
    inputGroups {
      name
      keywords
    }
}`;
