import { gql } from "@apollo/client";

import {
  generateVideoSessionTokenMutationDefinition,
  videoSessionDefinition,
} from "@/definitions/videoCallDefinitions";

export const CREATE_VIDEO_SESSION = gql`
  mutation CreateVideoSession($input: CreateVideoSessionMutationInput!) {
    createVideoSession(input: $input) {
      instance ${videoSessionDefinition}
    }
  }
`;

export const END_VIDEO_SESSION = gql`
  mutation EndVideoSession($input: EndVideoSessionInput!) {
    endVideoSession(input: $input) {
      instance ${videoSessionDefinition}
    }
  }
`;

/* Token for authenticated user (agent) */
export const GENERATE_VIDEO_SESSION_TOKEN = gql`
  mutation GenerateVideoSessionToken($input: GenerateVideoSessionTokenInput!) {
    generateVideoSessionToken(input: $input) ${generateVideoSessionTokenMutationDefinition}
  }
`;

/* Token for unauthenticated user (can be contact or anyone) */
export const GENERATE_VIDEO_SESSION_TOKEN_UNAUTHENTICATED = gql`
  mutation GenerateVideoSessionTokenUnauthenticated($input: GenerateVideoSessionTokenUnauthenticatedInput!) {
    generateVideoSessionTokenUnauthenticated(input: $input) ${generateVideoSessionTokenMutationDefinition}
  }
`;

export const CREATE_VIDEO_SCREENSHOT = gql`
  mutation CreateVideoSessionScreenshotMutation(
    $input: CreateVideoSessionScreenshotMutationInput!
  ) {
    createVideoSessionScreenshot(input: $input) {
      instance {
        id
        media {
          uuid
          filename
        }
      }
    }
  }
`;
