import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { systemAgentStatusEnum } from "@/enums/agentStatusEnum";

import Available from "@/assets/images/available.svg";
import Away from "@/assets/images/away.svg";
import CallOnly from "@/assets/images/call-only.svg";
import Busy from "@/assets/images/dnd.svg";
import NoCall from "@/assets/images/no-call.svg";
import NoChat from "@/assets/images/no-chat.svg";
import NoVideo from "@/assets/images/no-video.svg";
import Offline from "@/assets/images/offline.svg";
import ChatOnly from "@/assets/images/text-only.svg";
import VideoOnly from "@/assets/images/video-only.svg";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon: {
    width: "14px",
    height: "14px",
  },

  availableIcon: {
    backgroundColor: theme.palette.success[1700],
    borderRadius: "100%",
  },

  awayIcon: {
    backgroundColor: theme.palette.tertiary[800],
    borderRadius: "100%",
  },
}));

const AvailableIcon = ({ canChat, canVoiceCall, canVideoCall }) => {
  const classes = useStyles();

  const allAvailable = canChat && canVoiceCall && canVideoCall;
  const noVideo = canChat && canVoiceCall && !canVideoCall;
  const chatOnly = canChat && !canVoiceCall && !canVideoCall;
  const noCall = canChat && !canVoiceCall && canVideoCall;
  const noChat = !canChat && canVoiceCall && canVideoCall;
  const callOnly = !canChat && canVoiceCall && !canVideoCall;
  const videoOnly = !canChat && !canVoiceCall && canVideoCall;

  if (allAvailable)
    return <Available className={clsx(classes.icon, classes.availableIcon)} />;
  else if (noVideo)
    return <NoVideo className={clsx(classes.icon, classes.availableIcon)} />;
  else if (chatOnly)
    return <ChatOnly className={clsx(classes.icon, classes.availableIcon)} />;
  else if (noCall)
    return <NoCall className={clsx(classes.icon, classes.availableIcon)} />;
  else if (noChat)
    return <NoChat className={clsx(classes.icon, classes.availableIcon)} />;
  else if (callOnly)
    return <CallOnly className={clsx(classes.icon, classes.availableIcon)} />;
  else if (videoOnly)
    return <VideoOnly className={clsx(classes.icon, classes.availableIcon)} />;
  else return null;
};

const Icon = ({ systemStatus, canChat, canVoiceCall, canVideoCall }) => {
  const classes = useStyles();

  switch (systemStatus) {
    case systemAgentStatusEnum.available: {
      return (
        <AvailableIcon
          canChat={canChat}
          canVoiceCall={canVoiceCall}
          canVideoCall={canVideoCall}
        />
      );
    }
    case systemAgentStatusEnum.away: {
      return <Away className={clsx(classes.icon, classes.awayIcon)} />;
    }
    case systemAgentStatusEnum.busy: {
      return <Busy />;
    }
    case systemAgentStatusEnum.wrapUp: {
      return <Busy />;
    }
    default: {
      return <Offline />;
    }
  }
};

const AgentStatusIcon = ({
  systemStatus,
  canChat,
  canVoiceCall,
  canVideoCall,
  iconContainerClassname,
}) => {
  const classes = useStyles();

  if (!systemStatus) return null;

  return (
    <div className={clsx(classes.iconContainer, iconContainerClassname)}>
      <Icon
        systemStatus={systemStatus}
        canChat={canChat}
        canVoiceCall={canVoiceCall}
        canVideoCall={canVideoCall}
      />
    </div>
  );
};

export default AgentStatusIcon;
