import * as conversationActions from "@/actions/conversationActions";
import { LOG_OUT_SUCCESS } from "@/actions/userActions";

const defaultState = Object.freeze({
  parentConversationId: null,
  isMediaPreviewShown: false,
  hasMoreMedia: true,
  shouldRefetchEvents: false,
  currentMediaPreviewId: null,
  firstItemIndex: null,
  firstConversationEventId: null,
  hasPreviousConversation: true,
  currentMediaCursorOffset: {
    rowIndex: 0,
    columnIndex: 0,
  },
  conversationInitiationState: {
    initiationStatus: null,
    showInitiationScreen: false,
    selectedGroup: null,
    selectedContact: null,
    selectedMPC: null,
    selectedMPA: null,
    selectedVPC: null,
    selectedVPA: null,
    initiationChatEditorData: null,
    paymentLinkInitiationData: null,
  },
  isVoiceCallWithOtherAgent: false,
  focusedConversationNextPositionData: {
    id: "",
    isPriority: false,
    nextPosition: null /* Can be negative/positive */,
  },
});

const conversationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case conversationActions.SET_FIRST_ITEM_INDEX: {
      return Object.freeze({
        ...state,
        firstItemIndex: action.firstItemIndex,
      });
    }

    case conversationActions.SET_FIRST_CONVERSATION_EVENT_ID: {
      return Object.freeze({
        ...state,
        firstConversationEventId: action.firstConversationEventId,
      });
    }

    case conversationActions.TOGGLE_MEDIA_PREVIEW: {
      const { isMediaPreviewShown, currentMediaPreviewId } = action;

      return Object.freeze({
        ...state,
        isMediaPreviewShown,
        currentMediaPreviewId,
      });
    }

    case conversationActions.SET_CURRENT_MEDIA_CURSOR_OFFSET: {
      return Object.freeze({
        ...state,
        currentMediaCursorOffset: action.currentMediaCursorOffset,
      });
    }

    case conversationActions.SET_HAS_MORE_MEDIA: {
      return Object.freeze({
        ...state,
        hasMoreMedia: action.hasMoreMedia,
      });
    }

    case conversationActions.SET_PARENT_CONVERSATION_ID: {
      return Object.freeze({
        ...state,
        parentConversationId: action.parentConversationId,
      });
    }

    case conversationActions.SET_HAS_PREVIOUS_CONVERSATION: {
      return Object.freeze({
        ...state,
        hasPreviousConversation: action.hasPreviousConversation,
      });
    }

    case conversationActions.RESET_CONVERSATION: {
      return Object.freeze({
        ...defaultState,
        firstItemIndex: action.firstItemIndex,
        parentConversationId: action.parentConversationId,
      });
    }

    case conversationActions.START_INITIATION: {
      return Object.freeze({
        ...defaultState,
        firstItemIndex: 0,
      });
    }

    case conversationActions.SET_CONVERSATION_INITIATION_STATE: {
      const newConversationInitiationState = action.conversationInitiationState
        ? {
            ...state.conversationInitiationState,
            ...action.conversationInitiationState,
          }
        : defaultState.conversationInitiationState;

      return Object.freeze({
        ...state,
        conversationInitiationState: newConversationInitiationState,
      });
    }

    case conversationActions.SET_SHOULD_REFETCH_EVENTS: {
      return Object.freeze({
        ...state,
        shouldRefetchEvents: action.shouldRefetchEvents,
      });
    }

    case conversationActions.SET_IS_VOICE_CALL_WITH_OTHER_AGENT: {
      return Object.freeze({
        ...state,
        isVoiceCallWithOtherAgent: action.isVoiceCallWithOtherAgent,
      });
    }

    case conversationActions.SET_FOCUSED_CONVERSATION_NEXT_POSITION_DATA: {
      return Object.freeze({
        ...state,
        focusedConversationNextPositionData: {
          ...state.focusedConversationNextPositionData,
          ...action.focusedConversationNextPositionData,
        },
      });
    }

    case LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default conversationReducer;
