import React from "react";

import ClearIcon from "@/assets/images/clear-input.svg";
import SearchIcon from "@/assets/images/icon-search.svg";

export const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...otherInnerProps },
  } = props;

  return (
    <div
      ref={ref}
      style={getStyles("clearIndicator", props)}
      {...otherInnerProps}
    >
      <ClearIcon style={{ width: "13px", marginRight: "9px" }} />
    </div>
  );
};

export const DropdownIndicator = (data) => {
  const { hasValue } = data;

  if (hasValue) return null;

  return <SearchIcon style={{ marginRight: "7px" }} />;
};
