import { gql } from "@apollo/client";

export const CREATE_HELP_SCOUT_TOKEN = gql`
  mutation CreateHelpScoutToken {
    createHelpScoutToken {
      token
      expiresAt
    }
  }
`;
