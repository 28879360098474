import FloatingCallWidgetContainer from "@/components/Call/FloatingCallWidgetContainer";
import InboxPageSubscriptions from "@/components/InboxPage/InboxPageSubscriptions";
import PushNotificationManager from "@/components/PushNotificationManager";
import ServiceStatusIndicator from "@/components/ServiceStatusIndicator";
import { useAuthContext } from "@/contextProviders/AuthProvider";
import { useServiceStatus } from "@/contextProviders/ServiceStatusProvider";
import { ErrorBoundary } from "@sentry/nextjs";

const AppLayout = ({ children }) => {
  const { isUserLoggedIn } = useAuthContext();
  const { isServiceStatusIndicatorShown } = useServiceStatus();

  return (
    <>
      {isServiceStatusIndicatorShown && (
        <ErrorBoundary fallback={null}>
          <ServiceStatusIndicator />
        </ErrorBoundary>
      )}

      {children}

      {isUserLoggedIn && (
        <>
          <ErrorBoundary fallback={null}>
            <InboxPageSubscriptions />
          </ErrorBoundary>

          <ErrorBoundary fallback={null}>
            <PushNotificationManager />
          </ErrorBoundary>

          {/* Need to render this here so the widget container can move across whole page */}
          <FloatingCallWidgetContainer />
        </>
      )}
    </>
  );
};

export default AppLayout;
