import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash";
import initLayoutContainer from "opentok-layout-js";
import { useEffect, useMemo, useRef } from "react";
import { AutoSizer } from "react-virtualized";

import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import { videoCallParticipantTypeEnum } from "@/enums/videoCall";
import { getContactInitials, getNameInitials } from "@/utils/contactUtils";
import { useDebounce, useValueRef } from "@/utils/hookUtils";
import { getLayoutManager } from "@/utils/videoCallUtils";
import DisabledVideoPoster from "./DisabledVideoPoster";
import ShareScreenOverlay from "./ShareScreenOverlay";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[3200],

    "& .OT_video-poster": {
      backgroundImage: "none",
    },

    "&.isPrivateCameraPublisher": {
      position: "absolute",
      bottom: "65px",
      width: "96px",
      height: "67px",
      marginLeft: "13px",
      zIndex: 3,
      backgroundColor: "transparent",
      boxShadow: "0px 3px 6px #00000029",
      "& .OT_widget-container": {
        background: "transparent",
        borderRadius: "4px",
      },
    },

    "&.isPublicCameraPublisher": {
      position: "absolute",
      bottom: "92px",
      width: "240px",
      height: "150px",
      marginLeft: "19px",
      zIndex: 3,
      boxShadow: "0px 3px 6px #00000029",
      "& .OT_widget-container": {
        background: "transparent",
        borderRadius: "4px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "120px",
        height: "160px",
        marginLeft: "7px",
      },
    },

    "&.isFloatingCameraPublisher": {
      position: "absolute",
      bottom: "0",
      right: "0",
      width: "72px",
      height: "72px",
      borderRadius: "4px",
      zIndex: 3,
      boxShadow: `-1px -1px 1px ${theme.palette.boxShadow.main}`,
      backgroundColor: "transparent",
      "& .OT_widget-container": {
        background: "transparent",
        borderRadius: "4px",
      },
    },

    "&.isFullScreen": {
      width: "100%",
      height: "100%",
    },
  },

  customDisplayName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    textTransform: "capitalize",
    transform: "translateX(-100%)",
    zIndex: 2,
    padding: "7px 12px",
    height: "28px",
    fontSize: "0.92rem",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

const getSubscriberInitials = ({
  customDisplayName,
  display_name,
  participant_type,
  shouldMaskPhoneNumber,
}) => {
  const isParticipantContact =
    participant_type === videoCallParticipantTypeEnum.CONTACT;

  if (isParticipantContact) {
    if (customDisplayName) return customDisplayName;

    return getContactInitials({
      displayName: display_name,
      shouldMaskPhoneNumber,
    });
  }

  return getNameInitials(display_name);
};

const getPositions = ({
  containerRef,
  isPublicCameraPublisher,
  subscribers,
}) => {
  if (!containerRef?.current) return null;

  const layout = initLayoutContainer({
    containerWidth: containerRef.current.clientWidth,
    containerHeight: containerRef.current.clientHeight,
  });

  if (isPublicCameraPublisher) {
    const { boxes } = layout.getLayout([
      {
        width: 240,
        height: 150,
        big: false,
      },
    ]);
    return boxes;
  }

  if (!isEmpty(subscribers)) {
    const { boxes } = layout.getLayout(
      subscribers.map((subscriber) => ({
        width: subscriber.stream?.videoDimensions?.width,
        height: subscriber.stream?.videoDimensions?.height,
        big: subscriber.stream?.videoType === "screen",
      })),
    );
    return boxes;
  }
};

/* Add OT_ignore class so that opentok-layout lib ignore the component during layout calculation. */

const LayoutContainer = ({
  elementId,
  cameraPublisher = {},
  screenPublisher = {},
  subscribers = [],
  customDisplayName,
  isFullScreen,
  isFloatingCameraPublisher,
  isPrivateCameraPublisher,
  isPublicCameraPublisher,
  isScreenSharing,
}) => {
  const classes = useStyles();

  const containerRef = useRef();

  const { shouldMaskPhoneNumber } = useAuthenticatedUserContext();

  const isCameraPublisher =
    isFloatingCameraPublisher ||
    isPrivateCameraPublisher ||
    isPublicCameraPublisher;

  const isPublisherVideoDisabled = cameraPublisher.stream?.hasVideo === false;

  const publisherInitials = useMemo(() => {
    if (!cameraPublisher.stream) return null;
    if (customDisplayName) return customDisplayName;

    const { display_name } = JSON.parse(cameraPublisher.stream.connection.data);
    return getNameInitials(display_name);
  }, [cameraPublisher, customDisplayName]);

  const positions = useMemo(() => {
    return getPositions({
      containerRef,
      isPublicCameraPublisher,
      subscribers,
    });
  }, [isPublicCameraPublisher, subscribers]);

  const handleStopShareScreen = () => screenPublisher.unpublish();

  const updateLayout = (elementId) => {
    const layoutManager = getLayoutManager(elementId);
    layoutManager?.layout();
  };

  const valueRefs = useValueRef({ elementId, isCameraPublisher });

  const handleResize = useDebounce({
    func: () => {
      /* Skip resize camera publisher layout since they have a fixed width & height */
      if (valueRefs.current.isCameraPublisher) return;

      updateLayout(valueRefs.current.elementId);
    },
    delay: 100,
  });

  /* Update layout when agent stop screen-sharing either by pressing our custom button or browser built-in button */
  useEffect(() => {
    updateLayout(elementId);
  }, [isScreenSharing, elementId]);

  return (
    <AutoSizer onResize={handleResize}>
      {({ width, height }) => {
        const shouldFullScreen = !isCameraPublisher;

        return (
          <div
            id={elementId}
            ref={containerRef}
            style={{
              ...(shouldFullScreen && { width, height }),
            }}
            className={clsx(
              classes.container,
              { isFullScreen },
              { isFloatingCameraPublisher },
              { isPrivateCameraPublisher },
              { isPublicCameraPublisher },
              { isScreenSharing },
            )}
          >
            {/* Audio-only publisher poster */}
            {isPublisherVideoDisabled && (
              <DisabledVideoPoster initials={publisherInitials} />
            )}

            {/* Audio-only subscribers poster */}
            {!isEmpty(subscribers) &&
              positions &&
              subscribers.map((subscriber, index) => {
                if (!subscriber.stream) return null;
                if (subscriber.stream?.hasVideo) return null;

                const { display_name, participant_type } = JSON.parse(
                  subscriber.stream.connection.data,
                );
                const subscriberInitials = getSubscriberInitials({
                  customDisplayName,
                  display_name,
                  participant_type,
                  shouldMaskPhoneNumber,
                });

                return (
                  <DisabledVideoPoster
                    key={subscriber.stream.id}
                    style={{ ...positions[index] }}
                    initials={subscriberInitials}
                  />
                );
              })}

            {/* Public video publisher name eg. "You" or videoProviderAccountName */}
            {customDisplayName &&
              positions?.map((position, index) => {
                return (
                  <div
                    key={`position-${index}`}
                    style={{
                      top: position.top,
                      left: position.left + position.width,
                    }}
                    className={clsx(classes.customDisplayName, "OT_ignore")}
                  >
                    {customDisplayName}
                  </div>
                );
              })}

            {isScreenSharing && (
              <ShareScreenOverlay onStopShareScreen={handleStopShareScreen} />
            )}
          </div>
        );
      }}
    </AutoSizer>
  );
};
export default LayoutContainer;
