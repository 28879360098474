import { makeStyles, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";

import StatusBadge from "@/components/StatusBadge";
import TextPreview from "@/components/TextPreview";
import { equalPropsWith, getAgentFullName } from "@/utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  option: {
    display: "flex",
    alignItems: "center",
  },

  agentName: {
    padding: "0px 6px 0px 8px",
    color: theme.palette.textPrimary.main,
  },

  contactIcon: {
    "& path": {
      fill: theme.palette.textPrimary.main,
    },
  },
}));

const AgentOption = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const agentFullName = useMemo(() => getAgentFullName(data), [data]);
  const groups = useMemo(() => {
    return data.groups.map(({ name }) => name).join(", ");
  }, [data.groups]);

  return (
    <div className={classes.option}>
      <StatusBadge status={data.agentCurrentStatus} />

      <TextPreview
        ContainerProps={{ className: classes.agentName }}
        TooltipV2Props={{ BaseTooltipProps: { title: agentFullName } }}
      >
        {agentFullName}
      </TextPreview>

      {groups && (
        <TextPreview
          ContainerProps={{
            style: { fontSize: "0.85rem", color: theme.palette.grey[1710] },
          }}
          TooltipV2Props={{ BaseTooltipProps: { title: groups } }}
        >
          {groups}
        </TextPreview>
      )}
    </div>
  );
};

const isAgentOptionEqual = (prevProps, nextProps) =>
  equalPropsWith(prevProps, nextProps, ["data.id", "data.candidateType"]);

export default React.memo(AgentOption, isAgentOptionEqual);
