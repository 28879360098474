import { ContentState, EditorState } from "draft-js";
import Fuse from "fuse.js";
import { trim } from "lodash";
import { v4 as uuidv4 } from "uuid";

import * as commonUtils from "@/utils/commonUtils";

export const getCompleteTitle = (subTitle) => {
  const baseTitle = "/slash command";
  if (subTitle) return `${baseTitle} - ${subTitle}`;
  return baseTitle;
};

export const createNewEditorWithContent = ({ text = "", messageEditor }) => {
  /* Get current editor */
  const { editors, currentTab } = messageEditor;
  const currentEditor = editors[currentTab];

  /* Create new EditorState with text in it */
  const newEditorState = EditorState.createWithContent(
    ContentState.createFromText(text),
  );

  const newEditor = {
    ...currentEditor,
    id: uuidv4(),
    editorStates: [EditorState.moveFocusToEnd(newEditorState)],
  };

  return {
    ...messageEditor,
    editors: commonUtils.replaceInArray({
      array: editors,
      index: currentTab,
      element: newEditor,
    }),
  };
};

const getFuseObject = (inputArray = []) => {
  return new Fuse(inputArray, {
    isCaseSensitive: false,
    shouldSort: true,
    ignoreLocation: true,
    useExtendedSearch: true,
    threshold: 0.3,
    keys: [
      { name: "keyword", weight: 2 },
      { name: "description", weight: 1 },
    ],
  });
};

const getGroupedOptions = ({ command, parsedHints }) => {
  const parsedHintsObject = {};

  parsedHints.forEach((parsedHint) => {
    parsedHintsObject[parsedHint.keyword] = parsedHint;
  });

  const groupedOptions = command.inputGroups.map((inputGroup) => {
    const { name, keywords } = inputGroup;
    const options = [];

    keywords.forEach((keyword) => {
      const key = `${command.keyword} ${keyword}`;
      const parsedHint = parsedHintsObject[key];

      if (!parsedHint) return;

      options.push(parsedHint);
      parsedHintsObject[key] = null;
    });

    const searchIndex = getFuseObject(options);

    return {
      keyword: `${command.keyword} ${name}`,
      description: "",
      searchIndex,
      folderOptions: options,
      subTitle: command.description,
      isGroupedOption: true,
      replacementInputText: command.keyword,
      name,
    };
  });

  return {
    folders: groupedOptions,
    all: [
      ...groupedOptions,
      ...Object.values(parsedHintsObject).filter((option) => !!option),
    ],
  };
};

export const createFuseObject = (slashCommands = []) => {
  const parsedSlashCommands = slashCommands.map((command) => {
    const { keyword, hints, description, inputGroups } = command;

    if (!hints) return command;

    /* Prepare the hints, giving them the full keyword */
    const parsedHints = hints.map((hint) => {
      const { keyword: hintKeyword, description: hintDescription } = hint;

      return {
        ...hint,
        keyword: `${keyword} ${hintKeyword}`,
        description: hintDescription,
        subTitle: description,
      };
    });

    const { indexArray, folderOptions } = (() => {
      if (!inputGroups) {
        return { indexArray: parsedHints, folderOptions: parsedHints };
      }

      /* Prepare grouped data (sub-folders) */
      const groupData = getGroupedOptions({ command, parsedHints });
      return {
        indexArray: [...groupData.folders, ...parsedHints],
        folderOptions: groupData.all,
      };
    })();

    const searchIndex = getFuseObject(indexArray);

    return {
      ...command,
      searchIndex,
      folderOptions,
      subTitle: description,
    };
  });

  const fuseObj = getFuseObject(parsedSlashCommands);
  const isSingleOption = parsedSlashCommands.length === 1;

  if (!isSingleOption) {
    return { searchIndex: fuseObj, folderOptions: parsedSlashCommands };
  }

  const option = parsedSlashCommands[0];
  if (option.searchIndex) {
    return {
      searchIndex: option.searchIndex,
      folderOptions: option.folderOptions,
    };
  } else {
    return { searchIndex: fuseObj, folderOptions: parsedSlashCommands };
  }
};

/* This function enforces consistency when creating slash command keywords in frontend */
export const handleSlashCommandKeywordChange = ({ event, onChange }) => {
  const { value } = event.target;

  /* Enforce keyword length is 20 chars at most */
  if (value.length > 20) return;

  /* Check if characters used for keyword are valid */
  const isValidKeyword = /^[a-z0-9_]*$/.test(value);
  if (!isValidKeyword) return;

  onChange(value);
};

/* Function used to get keyword and input params of slash commands */
export const getInputParams = ({ editorState, selectedCommand }) => {
  const { keyword } = selectedCommand;
  const text = editorState.getCurrentContent().getPlainText();

  const inputParams = text.slice(keyword.length + 1);
  const inputParamsSeparator = inputParams.includes(";") ? ";" : " ";
  const keywordArray = keyword.split(" ");
  const inputParamsArray = trim(inputParams, "; ")
    .split(inputParamsSeparator)
    .map(trim)
    .filter((item) => Boolean(item));

  return { keywordArray, inputParamsArray };
};
