import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import CallControlBarIcons from "@/components/Call/CallControlBarIcons";
import { callTypeEnum } from "@/enums/conversation";
import { empty } from "@/utils/noopUtils";
import LeaveVoiceCallModal from "../LeaveVoiceCallModal";
import VoiceCallInvitationModal from "../VoiceCallInvitationModal";
import ParticipantList from "./ParticipantList";
import RoomInfo from "./RoomInfo";

const {
  AudioButton,
  HoldCallButton,
  EndCallButton,
  AddParticipantButton,
  SwitchButton,
} = CallControlBarIcons;

const useStyles = makeStyles((theme) => ({
  controlBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "56px",
    backgroundColor: theme.palette.grey[3200],
    color: theme.palette.common.white,
    "& > div": {
      display: "flex",
    },
  },

  participantsInfoContainer: {
    gap: "14px",
    "&.isInactiveCall": {
      opacity: 0.5,
    },
  },

  actionButtons: {
    gap: "8px",
  },
}));

const ControlBar = ({
  containerClassName,
  contacts,
  agents,
  voiceProviderAccountName,
  isAgentMonitoringAnyCall,
  isActiveVoiceCall,
  isEndActionShown = false,
  isOnHold,
  isMicOn,
  isHoldActionLoading,
  isMuteActionLoading,
  isEndActionLoading,
  isSwitchActionLoading,
  isMyParticipantActive,
  hasMyParticipantAnswered,
  isAnsweredByMachine,
  callAnsweredTimestamp,
  lastOnHoldAt,
  isFloatingVoiceCall,
  onToggleMicrophone = empty,
  onToggleHoldCall = empty,
  onEndCall = empty,
  onSwitchActiveVoiceCall = empty,
  onOpenVoiceCallInvitationModal = empty,
}) => {
  const classes = useStyles();

  const showSwitchButton =
    !!callAnsweredTimestamp &&
    !isActiveVoiceCall &&
    hasMyParticipantAnswered &&
    !isAgentMonitoringAnyCall;

  const showCallActionButtons =
    !!callAnsweredTimestamp && isActiveVoiceCall && isMyParticipantActive;
  const isAudioButtonDisabled = isOnHold || isMuteActionLoading;
  const isHoldCallButtonDisabled = isHoldActionLoading || isAnsweredByMachine;
  const isAddParticipantButtonDisabled = isOnHold || isAnsweredByMachine;

  const switchButtonTooltipTitle = isSwitchActionLoading
    ? "Switching call in progress"
    : "Switch to the voice call";

  const addParticipantButtonTooltipTitle = !isAddParticipantButtonDisabled
    ? "Add participants"
    : "";

  const endCallButtonTooltipTitle = !isEndActionLoading ? "End call" : "";

  const audioButtonTooltipTitle = (() => {
    if (isAudioButtonDisabled) return "";
    if (isMicOn) return "Mute call";
    if (!isMicOn && isFloatingVoiceCall) return "Unmute call";
    return "";
  })();

  const holdCallButtonTooltipTitle = (() => {
    if (isHoldCallButtonDisabled) return "";
    if (!isOnHold) return "Hold call";
    if (isOnHold && isFloatingVoiceCall) return "Unhold call";
    return "";
  })();

  return (
    <div className={clsx(classes.controlBar, containerClassName)}>
      <div
        className={clsx(classes.participantsInfoContainer, {
          isInactiveCall: !isActiveVoiceCall,
        })}
      >
        <ParticipantList
          contacts={contacts}
          agents={agents}
          isOnHold={isOnHold}
          isFloatingVoiceCall={isFloatingVoiceCall}
        />

        {!isFloatingVoiceCall && (
          <RoomInfo
            callAnsweredTimestamp={callAnsweredTimestamp}
            lastOnHoldAt={lastOnHoldAt}
            voiceProviderAccountName={voiceProviderAccountName}
            isAnsweredByMachine={isAnsweredByMachine}
            isOnHold={isOnHold}
          />
        )}
      </div>

      {showSwitchButton && (
        <SwitchButton
          isSwitchActionLoading={isSwitchActionLoading}
          TooltipProps={{ title: switchButtonTooltipTitle }}
          onSwitchActiveVoiceCall={onSwitchActiveVoiceCall}
        />
      )}

      {isActiveVoiceCall && (
        <div className={classes.actionButtons}>
          {showCallActionButtons && (
            <>
              <AudioButton
                isPrivateCall
                type={callTypeEnum.VOICE_CALL}
                isControlDisabled={isAudioButtonDisabled}
                hasAudio={isMicOn}
                TooltipProps={{ title: audioButtonTooltipTitle }}
                onClick={onToggleMicrophone}
              />
              <HoldCallButton
                isOnHold={isOnHold}
                isControlDisabled={isHoldCallButtonDisabled}
                TooltipProps={{ title: holdCallButtonTooltipTitle }}
                onClick={onToggleHoldCall}
              />
              <AddParticipantButton
                isControlDisabled={isAddParticipantButtonDisabled}
                TooltipProps={{ title: addParticipantButtonTooltipTitle }}
                onClick={onOpenVoiceCallInvitationModal}
              />
            </>
          )}

          {isEndActionShown && (
            <EndCallButton
              isControlDisabled={isEndActionLoading}
              TooltipProps={{ title: endCallButtonTooltipTitle }}
              onClick={onEndCall}
            />
          )}
        </div>
      )}

      {showCallActionButtons && (
        <>
          <VoiceCallInvitationModal />

          <LeaveVoiceCallModal />
        </>
      )}
    </div>
  );
};

export default ControlBar;
