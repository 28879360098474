import { Avatar, Badge, makeStyles, useTheme } from "@material-ui/core";
import React from "react";

import MicOffIcon from "@/assets/images/icon-mic-muted.svg";
import CameraffIcon from "@/assets/images/icon-video-off.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: "1rem",
    fontWeight: "500",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,

    "&.hasIcon": {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
  },

  badge: {
    height: "16px",
    minWidth: "16px",
    padding: 0,
    backgroundColor: theme.palette.alert.main,
    "& svg": {
      width: "10px",
      height: "10px",
    },
  },
}));

const ParticipantAvatar = ({
  initials,
  hasAudio = true,
  hasVideo = true,
  icon = null,
  isParticipantDisabled,
  AvatarProps = {},
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    className,
    style,
    size = "36px",
    ...otherAvatarProps
  } = AvatarProps || {};

  const { backgroundColor, ...otherStyle } = style || {};

  const badgeContent = (() => {
    if (!hasAudio) return <MicOffIcon />;
    if (!hasVideo) return <CameraffIcon />;
  })();

  return (
    <Badge
      classes={{ badge: classes.badge }}
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={badgeContent}
      invisible={hasAudio && hasVideo}
    >
      <Avatar
        style={{
          width: size,
          height: size,
          backgroundColor: isParticipantDisabled
            ? theme.palette.grey[1450]
            : backgroundColor,
          ...otherStyle,
        }}
        className={clsx(classes.avatar, { hasIcon: !!icon }, className)}
        {...otherAvatarProps}
      >
        {icon}
        {initials}
      </Avatar>
    </Badge>
  );
};

export default ParticipantAvatar;
