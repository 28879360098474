import { gql } from "@apollo/client";

const webPushDeviceMutationDefinition = `{
  id
  name
  registrationId
  active
  dateCreated
  p256dh
  auth
  browser
}`;

export const CREATE_WEB_PUSH_DEVICE = gql`
  mutation CreateWebPushDevice($input: CreateWebPushDeviceMutationInput!) {
    createWebPushDevice(input: $input) ${webPushDeviceMutationDefinition}
  }
`;
