import { isEmpty } from "lodash";
import initLayoutContainer from "opentok-layout-js";

import {
  videoCallParticipantTypeEnum,
  videoStreamPropertyEnum,
} from "@/enums/videoCall";
import { floatingWidgetTypeEnum } from "@/enums/widgetEnum";
import { uuidValidateV4 } from "@/utils/commonUtils";
import { getNameInitials } from "./contactUtils";

export const videoCallCardData = {
  title: "Join HD Video call with our operator",
  description: "You’ll be taken to browser to start a call",
};

export const allowedStreamPropertyChangedEvents = [
  videoStreamPropertyEnum.hasAudio,
  videoStreamPropertyEnum.hasVideo,
];

const getCurrentAgent = (publisherStream) => {
  if (!publisherStream) return [];

  const { connection, hasAudio, hasVideo } = publisherStream;
  const { id, display_name } = JSON.parse(connection.data);
  return [{ id, hasAudio, hasVideo, initials: getNameInitials(display_name) }];
};

export const getAgentList = ({ publisherStream, subscriberStreams }) => {
  if (!publisherStream && isEmpty(subscriberStreams)) return [];

  const currentAgent = getCurrentAgent(publisherStream);

  const otherAgents = subscriberStreams
    .filter((stream) => {
      const { id, participant_type } = JSON.parse(stream.connection.data);

      return (
        id !== currentAgent.id &&
        participant_type === videoCallParticipantTypeEnum.AGENT
      );
    })
    .map((stream) => {
      const { connection, hasAudio, hasVideo } = stream;
      const { id, display_name } = JSON.parse(connection.data);
      return {
        id,
        hasAudio,
        hasVideo,
        initials: getNameInitials(display_name),
      };
    });

  return [...currentAgent, ...otherAgents];
};

export const getContactList = ({ streams, customDisplayName }) => {
  if (isEmpty(streams)) return [];

  return streams
    .filter((stream) => {
      if (stream) {
        const { participant_type } = JSON.parse(stream.connection.data);
        return participant_type === videoCallParticipantTypeEnum.CONTACT;
      }
    })
    .map((stream) => {
      const { connection, hasAudio, hasVideo } = stream;
      const { id, display_name } = JSON.parse(connection.data);

      return {
        id,
        customDisplayName,
        displayName: display_name,
        hasAudio,
        hasVideo,
      };
    });
};

export const dataUrlToPngFile = async (dataUrl, fileName) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
};

export const getLayoutManager = (container) => {
  const element = document.getElementById(container);

  if (!element) return null;

  return initLayoutContainer(element);
};

export const getOngoingVideoCall = ({
  ongoingVideoSession,
  isFloatingVideoCall,
}) => {
  /* 
    Only show the floating video widget if: 
    1. Connected to a video session (refer to session state in PrivateVideoCallProvider),
    2. Floating call is enabled
  */
  if (isEmpty(ongoingVideoSession) || !isFloatingVideoCall) return;
  return { ...ongoingVideoSession, type: floatingWidgetTypeEnum.VIDEO_CALL };
};

/* Only show the shared-screen and hide all other subscribers screen while screen-sharing is active */
export const getSubscribers = ({ subscribers, isScreenSharing }) => {
  return subscribers.filter((subscriber) => {
    const { videoType } = subscriber.stream || {};
    return isScreenSharing ? videoType === "screen" : videoType === "camera";
  });
};

export const checkIsVideoCallUrl = ({ url }) => {
  /* 
    URL format = https://BASE-URL/v/uuidv4 
    Take note that url with IP address can't be parsed by Microlink
  */
  if (!url) return false;
  if (!window) return false;

  const expectedPrefix = `${window.location.origin}/v/`;
  if (!url.startsWith(expectedPrefix)) return false;

  const videoCallUuid = url.substring(expectedPrefix.length);
  const isUuidV4 = uuidValidateV4(videoCallUuid);

  return isUuidV4;
};

export const addScreenSharingClassName = ({
  id,
  stream,
  layoutManager,
  isScreenSharing,
}) => {
  const element = document.getElementById(id);

  if (!element) return;

  if (stream?.videoType === "screen") {
    element.classList.add("OT_big");
    layoutManager.layout();
  } else {
    isScreenSharing
      ? element.classList.add("OT_ignore")
      : element.classList.remove("OT_ignore");
    element.style.display = isScreenSharing ? "none" : "block";
  }
};

export const toggleCamera = (cameraPublisher) => {
  cameraPublisher.publisher.publishVideo(!cameraPublisher.stream?.hasVideo);
};

export const toggleMicrophone = (cameraPublisher) => {
  cameraPublisher.publisher.publishAudio(!cameraPublisher.stream?.hasAudio);
};
