import { gql } from "@apollo/client";

import * as inboxViewPaneDefinition from "@/definitions/allowedInboxView/inboxViewPaneDefinition";
import * as conversationEventDefinitions from "@/definitions/conversation/event/conversationEventDefinitions";
import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";
import { initiateConversationMPCDefinition } from "@/definitions/messagingProviderContact/messagingProviderContactDefinitions";

export const CONVERSATION_LAST_READ = gql`
  mutation ConversationLastRead($input: ConversationLastReadInput) {
    conversationLastRead(input: $input) {
      lastReadAt
    }
  }
`;

export const RUN_SLASH_APP_COMMAND = gql`
  mutation RunSlashAppCommand($input: RunSlashCommandInput) {
    runSlashAppCommand(input: $input) {
      ok
    }
  }
`;

export const CREATE_CONVERSATION_MESSAGE = gql`
  mutation CreateConversationMessage($input: CreateConversationMessageMutationInput!)
  @serialize(key: ["SendMessageMutation"]) {
    createConversationMessage(input: $input) {
      instance ${conversationEventDefinitions.conversationMessageDefinition}
    }
  }
`;

export const CREATE_CONVERSATION_NOTE = gql`
  mutation CreateConversationNote($input: CreateConversationNoteMutationInput!)
  @serialize(key: ["SendMessageMutation"]) {
    createConversationNote(input: $input) {
      instance ${conversationEventDefinitions.conversationNoteDefinition}
    }
  }
`;

export const SEND_LANDING_PAGE_DOCUMENT_SHORT_LINK = gql`
  mutation SendLandingPageDocumentShortLinkMutation(
    $input: SendLandingPageDocumentShortLinkMutationInput!
  ) {
    sendLandingPageDocumentShortLinkMutation(input: $input) {
      conversationMessage ${conversationEventDefinitions.conversationMessageDefinition}
    }
  }
`;

export const TRANSFER_CONVERSATION = gql`
  mutation TransferConversation($input: TransferConversationInput!) {
    transferConversation(input: $input) {
      conversation {
        id
        conversationStatus: status
        allowedInboxView ${sharedConversationDefinitions.allowedInboxViewDefinition}
        assignee ${sharedConversationDefinitions.agentDefinition}
        group {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: UpdateContactMutationInput!) {
    updateContact(input: $input) {
      instance {
        id
        displayName
        fullName
        comment
        phoneNumber
      }
    }
  }
`;

export const RESOLVE_CONVERSATION = gql`
  mutation ResolveConversation($input: ResolveConversationInput) {
    resolveConversation(input: $input) {
      instance {
        id
        conversationStatus: status
        allowedInboxView ${sharedConversationDefinitions.allowedInboxViewDefinition}
      }
    }
  }
`;

export const INITIATE_CONVERSATION = gql`
  mutation InitiateConversation($input: InitiateConversationMutationInput!) {
    initiateConversation(input: $input) {
      instance {
        id
      }
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactMutationInput!) {
    createContact(input: $input) {
      instance {
        id
        fullName
        displayName
      }
    }
  }
`;

export const CREATE_MESSAGING_PROVIDER_CONTACT = gql`
  mutation CreateMessagingProviderContact(
    $input: CreateMessagingProviderContactMutationInput!
  ) {
    createMessagingProviderContact(input: $input) {
      instance ${initiateConversationMPCDefinition}
    }
  }
`;

export const SET_CONVERSATION_UNREAD = gql`
  mutation SetConversationUnread($input: SetConversationUnreadInput) {
    setConversationUnread(input: $input) {
      conversation {
        id
        globalUnreadConversationMessagesCount
      }
    }
  }
`;

export const SET_CONVERSATION_PRIORITY_MUTATION = gql`
  mutation SetConversationPriority($input: SetConversationPriorityInput) {
    setConversationPriority(input: $input) {
      conversation {
        id
        isPriority
      }
    }
  }
`;

export const SET_CONVERSATION_MESSAGING_CHANNEL = gql`
  mutation SetConversationMessagingChannel(
    $input: SetConversationMessagingChannelMutationInput!
  ) {
    setConversationMessagingChannel(input: $input) {
      instance {
        id
        messagingProviderAccount {
          id
          messagingProvider {
            id
            name
          }
        }
        messagingProviderContact {
          id
          accountId
          contact ${sharedConversationDefinitions.contactDefinition}
          messagingProvider {
            id
            accountIdLabel
            messagingProviderLabel
            name
          }
        }
      }
    }
  }
`;

export const CREATE_PAYMENT_SESSION = gql`
  mutation CreatePaymentSession($input: CreatePaymentSessionMutationInput!) {
    createPaymentSession(input: $input) {
      id
    }
  }
`;

export const UPDATE_INBOX_VIEW_PIN_STATE = gql`
  mutation UpdateInboxViewPinState(
    $input: UpdateConversationAllowedInboxViewPinStateInput!
  ) {
    updateConversationAllowedInboxViewPinState(input: $input) {
      group ${inboxViewPaneDefinition.groupDefinition}
      conversationTag ${inboxViewPaneDefinition.contentTypeAllowedTagDefinition}
      contactTag ${inboxViewPaneDefinition.contentTypeAllowedTagDefinition}
      agent ${inboxViewPaneDefinition.agentDefinition}
    }
  }
`;

export const CREATE_CONVERSATION_TAG = gql`
  mutation CreateConversationTag($input: CreateConversationTagMutationInput!) {
    createConversationTag(input: $input) {
      instance {
        id
      }
    }
  }
`;

export const DELETE_CONVERSATION_TAG = gql`
  mutation DeleteConversationTag($input: DeleteConversationTagInput) {
    deleteConversationTag(input: $input) {
      ok
    }
  }
`;

export const CREATE_CONTACT_TAG = gql`
  mutation CreateContactTag($input: CreateContactTagMutationInput!) {
    createContactTag(input: $input) {
      instance ${sharedConversationDefinitions.contactTagDefinition}
    }
  }
`;

export const DELETE_CONTACT_TAG = gql`
  mutation DeleteContactTag($input: DeleteContactTagInput!) {
    deleteContactTag(input: $input) {
      ok
    }
  }
`;
