import moment from "moment";
import React, { useMemo } from "react";

import * as commonUtils from "@/utils/commonUtils";
import { useStopwatch } from "@/utils/hookUtils";

const Timer = ({ startTimeStamp, onFormat }) => {
  const timer = useMemo(
    () => (startTimeStamp ? moment(startTimeStamp) : moment()),
    [startTimeStamp],
  );

  const seconds = useStopwatch(timer);
  const time = onFormat
    ? onFormat(seconds)
    : commonUtils.formatTime(seconds * 1000);

  return <>{time}</>;
};

export default Timer;
