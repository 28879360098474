import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";

const notificationConversationNoteDefinition = `{
  id
  clientReference
  mentions {
    id 
    conversationEvent {
      id
    }
    targetAgent ${sharedConversationDefinitions.mentionAgentDefinition}
  }
  conversation {
    id
    status
    contact {
      id
      displayName
    }
  }
  textConversationNote ${sharedConversationDefinitions.textConversationNoteDefinition}
  mediaConversationNote ${sharedConversationDefinitions.mediaConversationNoteDefinition}
  locationConversationNote ${sharedConversationDefinitions.locationConversationNoteDefinition}
}`;

const notificationConversationMessageDefinition = `{
  id
  clientReference
  messageContentType
  conversation {
    id
    status
    contact {
      id
      displayName
    }
  }
  textConversationMessage ${sharedConversationDefinitions.textConversationMessageDefinition}
  mediaConversationMessage ${sharedConversationDefinitions.mediaConversationMessageDefinition}
  locationConversationMessage ${sharedConversationDefinitions.locationConversationMessageDefinition}
  whatsappHsmConversationMessage ${sharedConversationDefinitions.whatsappHsmConversationMessageDefinition} 
}`;

export const notificationAgentIPCallDefinition = `{
  id
  batch {
    id
    callSequence {
      id
      voiceConversation {
        id
        conversation {
          id
          group {
            id
            name
          }
        }
        voiceProviderContact {
          id
          contact {
            id
            displayName
          }
        }
      }
    }
  }
}`;

export const notificationEventDefinition = `{
  id
  eventType
  conversation {
    id
    status
  }
  data

  actor {
    ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
  }

  actionObject {
    ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
    ... on AgentIPCallObject ${notificationAgentIPCallDefinition}
  }

  target {
    ... on ConversationNoteObject ${notificationConversationNoteDefinition}
    ... on ConversationMessageObject ${notificationConversationMessageDefinition}
  }
}`;
