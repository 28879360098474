import { gql } from "@apollo/client";

export const slashCommandWhatsappHsmTranslatedTemplatesDefinition = `{
    id
    headerType
    headerTextInHtml
    headerTextParams {
      id
      hint
    }
    
    bodyInHtml  
    bodyTextParams {
        id
        hint
    }
    
    footerInHtml

    buttons {
      id
      buttonType
      label
      phoneNumber
      urlInHtml
    }

    buttonUrlParams {
      id
      hint
    }
  }`;

export const SLASH_COMMAND_WHATSAPP_HSM_FRAGMENT = gql`
  fragment SlashCommandWhatsappHSMTranslatedTemplate on WhatsappHSMTranslatedTemplateObject ${slashCommandWhatsappHsmTranslatedTemplatesDefinition}
`;
