import { makeStyles } from "@material-ui/core";
import React from "react";

import CallDuration from "../../CallDuration";

const useStyles = makeStyles(() => ({
  roomInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "3px",
  },
  description: {
    fontSize: "0.85rem",
  },
}));

const RoomInfo = ({
  callAnsweredTimestamp,
  lastOnHoldAt,
  voiceProviderAccountName,
  isAnsweredByMachine,
  isOnHold,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.roomInfoContainer}>
      <CallDuration
        callAnsweredTimestamp={callAnsweredTimestamp}
        lastOnHoldAt={lastOnHoldAt}
        isAnsweredByMachine={isAnsweredByMachine}
        isOnHold={isOnHold}
      />
      {voiceProviderAccountName && (
        <div className={classes.description}> {voiceProviderAccountName} </div>
      )}
    </div>
  );
};

export default RoomInfo;
