import React, { useEffect, useMemo } from "react";

import {
  ongoingVoiceCallStatuses,
  voiceCallParticipantTypeEnum,
} from "@/enums/voiceCall";
import { useValueRef } from "@/utils/hookUtils";
import * as voiceCallUtils from "@/utils/voiceCallUtils";
import DefaultFloatingVoiceCall from "./DefaultFloatingVoiceCall";
import FloatingInboundCall from "./FloatingInboundCall";

const VoiceCallWidgetRenderer = ({
  userId,
  voiceCallObj,
  isAgentMonitoringAnyCall,
  isActiveVoiceCall,
  isAnyVoiceCallStarting,
  currentConversationId,
  isMuteActionLoading,
  isHoldActionLoading,
  isStartingCall,
  isReconnectingVoiceCallMonitor,
  isVideoCallInUse,
  onToggleMicrophone,
  onToggleHoldCall,
  onAnswerIncomingCall,
  onRejectIncomingCall,
  onEndVoiceCall,
  onReconnectCallMonitor,
  onHandleConversationSwitch,
  onSwitchActiveVoiceCall,
  onSetConversationIdStartAndSwitchVoiceCall,
  onOpenVoiceCallInvitationModal,
}) => {
  const {
    conversationId,
    voiceConversation = {},
    isEndingCall,
  } = voiceCallObj || {};

  const isVoiceCallOngoing = useMemo(() => {
    return ongoingVoiceCallStatuses.includes(
      voiceConversation?.voiceCallStatus,
    );
  }, [voiceConversation?.voiceCallStatus]);

  const isViewingCurrentConversation = currentConversationId === conversationId;

  const myParticipant = useMemo(() => {
    return voiceCallUtils.getParticipantFromVoiceCall({
      voiceCallParticipants: voiceConversation?.currentParticipants || [],
      participantType: voiceCallParticipantTypeEnum.agent,
      participantId: userId,
    });
  }, [voiceConversation?.currentParticipants, userId]);

  const hasMyParticipantAnswered = myParticipant?.isCallAnswered;

  const hideWidget =
    !isVoiceCallOngoing ||
    (isViewingCurrentConversation && hasMyParticipantAnswered);

  const valueRefs = useValueRef({ onSetConversationIdStartAndSwitchVoiceCall });

  /* Reset id starting call once call is answered */
  useEffect(() => {
    if (!isStartingCall) return;

    const shouldResetConversationStartingVoiceCall =
      voiceConversation.answeredAt && hasMyParticipantAnswered;

    if (shouldResetConversationStartingVoiceCall) {
      valueRefs.current.onSetConversationIdStartAndSwitchVoiceCall();
    }
  }, [
    valueRefs,
    isStartingCall,
    voiceConversation.answeredAt,
    hasMyParticipantAnswered,
  ]);

  if (hideWidget) return null;

  if (!hasMyParticipantAnswered) {
    return (
      <FloatingInboundCall
        voiceConversation={voiceConversation}
        isAgentMonitoringAnyCall={isAgentMonitoringAnyCall}
        isAnyVoiceCallStarting={isAnyVoiceCallStarting}
        isEndingCall={isEndingCall}
        isStartingCall={isStartingCall}
        isVideoCallInUse={isVideoCallInUse}
        onAnswerIncomingCall={onAnswerIncomingCall}
        onRejectIncomingCall={onRejectIncomingCall}
      />
    );
  }

  return (
    <DefaultFloatingVoiceCall
      userId={userId}
      isAgentMonitoringAnyCall={isAgentMonitoringAnyCall}
      isAnyVoiceCallStarting={isAnyVoiceCallStarting}
      isReconnectingVoiceCallMonitor={isReconnectingVoiceCallMonitor}
      isEndingCall={isEndingCall}
      voiceConversation={voiceConversation}
      isActiveVoiceCall={isActiveVoiceCall}
      isMuteActionLoading={isMuteActionLoading}
      isHoldActionLoading={isHoldActionLoading}
      onToggleMicrophone={onToggleMicrophone}
      onToggleHoldCall={onToggleHoldCall}
      onEndVoiceCall={onEndVoiceCall}
      onReconnectCallMonitor={onReconnectCallMonitor}
      onHandleConversationSwitch={onHandleConversationSwitch}
      onSwitchActiveVoiceCall={onSwitchActiveVoiceCall}
      onOpenVoiceCallInvitationModal={onOpenVoiceCallInvitationModal}
    />
  );
};

export default VoiceCallWidgetRenderer;
