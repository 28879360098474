import React from "react";
import { Controller } from "react-hook-form";

import Selector from "@/components/Selector";
import { validateNonEmptyValue } from "@/utils/formValidationUtils";

const SelectInput = ({
  control,
  controlName,
  displayName,
  required,
  error,
  ControllerProps = {},
  SelectProps = {},
  customValidateFunctions = {},
}) => {
  const { rules = {}, ...otherControllerProps } = ControllerProps;

  const validateRequired = (value) => {
    const message = displayName
      ? `${displayName} is required`
      : "This field is required";

    if (required) return validateNonEmptyValue(value, message);
    return true;
  };

  return (
    <Controller
      control={control}
      name={controlName}
      render={({ value, onChange, ref }) => {
        return (
          <Selector
            selectorRef={ref}
            value={value}
            onChange={onChange}
            error={error}
            {...SelectProps}
          />
        );
      }}
      rules={{
        validate: { validateRequired, ...customValidateFunctions },
        ...rules,
      }}
      {...otherControllerProps}
    />
  );
};

export default SelectInput;
