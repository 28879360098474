import { gql } from "@apollo/client";

export const UPDATE_AGENT_CURRENT_STATUS = gql`
  mutation UpdateAgentCurrentStatus(
    $input: UpdateAgentCurrentStatusMutationInput!
  ) {
    updateAgentCurrentStatus(input: $input) {
      ok
    }
  }
`;
