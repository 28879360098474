import { makeStyles, useTheme } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";

import CaretDown from "@/assets/images/caret-down.svg";
import TextPreview from "@/components/TextPreview";
import { validateNonEmptyString } from "@/utils/formValidationUtils";

const useStyles = makeStyles({
  tooltip: { marginTop: "10px" },
});

const getCustomStyles = ({ customStyles, isDisabled, error, theme }) => ({
  container: (provided, state) => {
    const { fullWidth } = state.selectProps;

    return {
      ...provided,
      width: fullWidth ? "100%" : "auto",
    };
  },
  option: (provided, state) => {
    const { isMulti, isFocused, isSelected } = state;

    return {
      ...provided,
      fontSize: "1rem",
      cursor: "pointer",
      wordBreak: "break-word",
      backgroundColor:
        isFocused || (isMulti && isSelected)
          ? theme.palette.primaryAction.main
          : "unset",
      ":active": { backgroundColor: theme.palette.primaryAction.main },
      color: theme.palette.textPrimary.main,
    };
  },

  multiValueRemove: (provided) => ({
    ...provided,
    cursor: "pointer",
    ":hover": { backgroundColor: "unset" },
  }),

  control: (provided, state) => {
    const { isFocused } = state;
    const { controlWidth } = state.selectProps;
    const borderColor = (() => {
      if (isFocused) return theme.palette.primary.main;
      if (error) return theme.palette.alert.main;
      return theme.palette.primaryBorder.main;
    })();

    return {
      display: "flex",
      minHeight: "32px",
      minWidth: controlWidth,
      border: `1px solid ${borderColor}`,
      borderRadius: "4px",
      backgroundColor: isDisabled
        ? theme.palette.disabledInput.main
        : theme.palette.common.white,
      ":hover": { borderColor: theme.palette.primaryBorder.main },
    };
  },

  menu: (provided, { selectProps: { menuWidth } }) => ({
    position: "absolute",
    zIndex: 9999,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.customShadows.selectorMenu,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.primaryBorder.main}`,
    width: menuWidth ? menuWidth : "100%",
    marginTop: "5px",
  }),

  menuList: (provided, { selectProps: { menuHeight } }) => ({
    ...provided,
    maxHeight: menuHeight || "146px",
  }),

  indicatorSeparator: () => ({}),

  dropdownIndicator: () => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 10px",
    "& path": {
      fill: theme.palette.textSecondary[2500],
    },
  }),

  clearIndicator: () => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: theme.palette.textSecondary.main,
    ":hover": {
      color: theme.palette.textSecondary.main,
    },
  }),

  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: "1rem",
  }),

  singleValue: (provided) => ({
    ...provided,
    color: theme.palette.textPrimary.main,
  }),

  ...customStyles,
});

const MultiValueLabel = (props) => {
  const classes = useStyles();

  const { children, ...otherProps } = props;

  return (
    <components.MultiValueLabel {...otherProps}>
      <TextPreview
        TooltipV2Props={{
          BaseTooltipProps: {
            title: children,
            classes: {
              tooltip: classes.tooltip,
            },
          },
        }}
      >
        {children}
      </TextPreview>
    </components.MultiValueLabel>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown />
    </components.DropdownIndicator>
  );
};

const noOptionsMessage = ({ inputValue }) => {
  const message = validateNonEmptyString(inputValue)
    ? "No options found"
    : "Type to start search";

  return message;
};

const Selector = ({
  components = {},
  menuWidth = null,
  customStyles = {},
  selectorRef,
  placeholder = "",
  isMulti,
  isAsync,
  isDisabled,
  isCreatable,
  error,
  shouldForceLoadOptionsOnFocus,
  loadOptions,
  ...otherProps
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const closeMenuOnSelect = !isMulti;

  const styles = useMemo(
    () => getCustomStyles({ customStyles, isDisabled, error, theme }),
    [customStyles, isDisabled, error, theme],
  );

  const handleDefaultOptions = (options) => {
    setDefaultOptions(options);
    setIsLoading(false);
  };

  const onAsyncFocus = () => {
    const hasDefaultValues = !isEmpty(defaultOptions);
    if (hasDefaultValues && !shouldForceLoadOptionsOnFocus) return;

    setDefaultOptions([]);
    setIsLoading(true);
    loadOptions("", handleDefaultOptions);
  };

  const onAsyncBlur = () => {
    if (shouldForceLoadOptionsOnFocus) setDefaultOptions([]);
    setIsLoading(false);
  };

  const onInputChange = () => {
    if (!isLoading) return;
    setDefaultOptions([]);
    setIsLoading(false);
  };

  /*
    TODO: Try with react-select-async-paginate 
    * in case not all search results are returned within the limit.
  */
  if (isAsync) {
    const SelectComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect;

    return (
      <SelectComponent
        ref={selectorRef}
        styles={styles}
        defaultOptions={defaultOptions}
        placeholder={placeholder}
        isMulti={isMulti}
        isLoading={isLoading}
        isDisabled={isDisabled}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          MultiValueLabel,
          DropdownIndicator,
          ...components,
        }}
        noOptionsMessage={noOptionsMessage}
        onFocus={onAsyncFocus}
        onBlur={onAsyncBlur}
        onInputChange={onInputChange}
        loadOptions={loadOptions}
        {...otherProps}
      />
    );
  }

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <SelectComponent
      ref={selectorRef}
      placeholder={placeholder}
      menuPlacement="bottom"
      styles={styles}
      menuWidth={menuWidth}
      isMulti={isMulti}
      isDisabled={isDisabled}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        MultiValueLabel,
        DropdownIndicator,
        ...components,
      }}
      {...otherProps}
    />
  );
};

export default Selector;
