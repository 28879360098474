import React from "react";

import SwitchCameraIcon from "@/assets/images/icon-switch-camera.svg";
import IconAvatar from "./IconAvatar";

/* Only used in public video call. Avatar size never change */
const SwitchCameraButton = ({ isControlDisabled, onClick }) => {
  return (
    <IconAvatar
      size="56px"
      isEnabled={true}
      isControlDisabled={isControlDisabled}
      onClick={onClick}
    >
      <SwitchCameraIcon width="28px" height="24px" />
    </IconAvatar>
  );
};

export default SwitchCameraButton;
