import { Divider, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import MonitorCallIcon from "@/assets/images/icon-ear-grey.svg";
import AvatarGroupList from "@/components/AvatarGroupList";
import ParticipantAvatar from "@/components/Call/CallControlBarIcons/ParticipantAvatar";
import FormattedContactDisplayName from "@/components/FormattedContactDisplayName";
import Tooltip from "@/components/Tooltip";
import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import { getContactInitials } from "@/utils/contactUtils";

const useStyles = makeStyles((theme) => ({
  participantListContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },

  divider: {
    backgroundColor: theme.palette.grey[1890],
    height: "80%",
  },

  moreIcon: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.white}`,
    fontWeight: "bold",
    "&.shouldRenderSmallAvatar": {
      fontSize: "0.85rem",
      width: "22px",
      height: "22px",
    },
  },
}));

const MoreAvatarsTooltipTitle = ({ hiddenItems }) =>
  hiddenItems.map((item) => <div key={item.id}>{item.tooltipTitle}</div>);

const MoreAvatarsIcon = ({ hiddenItems, shouldRenderSmallAvatar }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<MoreAvatarsTooltipTitle hiddenItems={hiddenItems} />}
      whiteOption
    >
      <div className={clsx(classes.moreIcon, { shouldRenderSmallAvatar })}>
        +{hiddenItems.length}
      </div>
    </Tooltip>
  );
};

const ParticipantList = ({
  contacts,
  agents,
  isOnHold,
  isFloatingVoiceCall,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { shouldMaskPhoneNumber } = useAuthenticatedUserContext();

  const shouldRenderSmallAvatar =
    isFloatingVoiceCall && contacts.length + agents.length >= 3;

  const participantAvatarSize = shouldRenderSmallAvatar ? "22px" : "36px";

  const commonParticipantAvatarStyle = {
    fontSize: shouldRenderSmallAvatar ? "0.85rem" : "1rem",
  };

  const maxItemsDisplayedBeforeTruncate = isFloatingVoiceCall ? 1 : 3;

  return (
    <div className={classes.participantListContainer}>
      <AvatarGroupList
        data={contacts}
        maxItemsDisplayedBeforeTruncate={maxItemsDisplayedBeforeTruncate}
        AvatarRenderer={({ data: contact }) => {
          return (
            <Tooltip
              whiteOption
              title={
                <FormattedContactDisplayName
                  displayName={contact.displayName}
                />
              }
            >
              <ParticipantAvatar
                initials={getContactInitials({
                  displayName: contact.displayName,
                  shouldMaskPhoneNumber,
                })}
                hasAudio={contact.hasAudio}
                isParticipantDisabled={!contact.isActive || isOnHold}
                AvatarProps={{
                  size: participantAvatarSize,
                  style: {
                    backgroundColor: theme.palette.primary.main,
                    ...commonParticipantAvatarStyle,
                  },
                }}
              />
            </Tooltip>
          );
        }}
        MoreAvatarsIcon={({ hiddenItems }) => (
          <MoreAvatarsIcon
            hiddenItems={hiddenItems}
            shouldRenderSmallAvatar={shouldRenderSmallAvatar}
          />
        )}
      />

      {isFloatingVoiceCall && (
        <Divider className={classes.divider} orientation="vertical" />
      )}

      <AvatarGroupList
        data={agents}
        maxItemsDisplayedBeforeTruncate={maxItemsDisplayedBeforeTruncate}
        AvatarRenderer={({ data: agent }) => {
          const isMonitorIconShown =
            !isFloatingVoiceCall && agent.isCallMonitor;

          return (
            <Tooltip whiteOption title={agent.tooltipTitle}>
              <ParticipantAvatar
                initials={agent.initials}
                hasAudio={agent.hasAudio}
                isParticipantDisabled={!agent.isActive || isOnHold}
                icon={isMonitorIconShown ? <MonitorCallIcon /> : null}
                AvatarProps={{
                  size: participantAvatarSize,
                  style: {
                    backgroundColor: agent.isCallMonitor
                      ? "transparent"
                      : theme.palette.tertiary.dark,
                    border: `1px solid ${theme.palette.grey[1755]}`,
                    ...commonParticipantAvatarStyle,
                  },
                }}
              />
            </Tooltip>
          );
        }}
        MoreAvatarsIcon={({ hiddenItems }) => (
          <MoreAvatarsIcon
            hiddenItems={hiddenItems}
            shouldRenderSmallAvatar={shouldRenderSmallAvatar}
          />
        )}
      />
    </div>
  );
};

export default ParticipantList;
