import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { isNil } from "lodash";
import React from "react";

import CallControlBarIcons from "@/components/Call/CallControlBarIcons";
import { videoCallControlEnum } from "@/enums/videoCall";
import * as commonUtils from "@/utils/commonUtils";
import ParticipantList from "../ControlBar/ParticipantList";
import MoreButton from "./ActionButtons/MoreButton";
import RoomInfo from "./RoomInfo";

const useStyles = makeStyles((theme) => ({
  controlBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "56px",
    backgroundColor: theme.palette.grey[3200],
    color: theme.palette.common.white,
    "& > div": {
      display: "flex",
    },
    "&.isSmallScreen": {
      justifyContent: "center",
    },
  },

  actionButtons: {
    gap: "7px",
    paddingRight: "12px",
  },
}));

const {
  AudioButton,
  VideoButton,
  ScreenshotButton,
  ShareScreenButton,
  SwitchCameraButton,
  LeaveCallButton,
} = CallControlBarIcons;

const ControlBar = ({
  controls,
  containerClassName,
  cameraPublisher = {},
  screenPublisher = {},
  cameraSubscribers = {},
  contacts,
  agents,
  hasContactJoined,
  showRoomInfo,
  isPrivateCall,
  isControlDisabled,
  isParticipantDisabled,
  isScreenshotLoading,
  onLeaveCall,
  onScreenshot,
  onShareScreen,
  onSwitchCamera,
  onToggleCamera,
  onToggleMicrophone,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileDevice = commonUtils.isMobileDevice();

  return (
    <>
      <div
        className={clsx(classes.controlBar, containerClassName, {
          isSmallScreen,
        })}
      >
        <div>
          {!isSmallScreen && (
            <ParticipantList
              contacts={contacts}
              agents={agents}
              isPrivateCall={isPrivateCall}
              isParticipantDisabled={isParticipantDisabled}
            />
          )}

          {showRoomInfo && <RoomInfo hasContactJoined={hasContactJoined} />}
        </div>

        <div className={classes.actionButtons}>
          {controls[videoCallControlEnum.AUDIO] && (
            <AudioButton
              hasAudio={cameraPublisher.stream?.hasAudio}
              isPrivateCall={isPrivateCall}
              isControlDisabled={isNil(cameraPublisher.stream?.hasAudio)}
              onClick={onToggleMicrophone}
            />
          )}

          {controls[videoCallControlEnum.VIDEO] && (
            <VideoButton
              hasVideo={cameraPublisher.stream?.hasVideo}
              isPrivateCall={isPrivateCall}
              isControlDisabled={isNil(cameraPublisher.stream?.hasVideo)}
              onClick={onToggleCamera}
            />
          )}

          {controls[videoCallControlEnum.SHARE_SCREEN] && (
            <ShareScreenButton
              isScreenSharing={Boolean(screenPublisher.stream)}
              onClick={onShareScreen}
            />
          )}

          {controls[videoCallControlEnum.SCREENSHOT] && (
            <ScreenshotButton
              cameraSubscribers={cameraSubscribers}
              isControlDisabled={isScreenshotLoading}
              isLoading={isScreenshotLoading}
              onClick={onScreenshot}
            />
          )}

          {isMobileDevice &&
            cameraPublisher.stream?.hasVideo &&
            controls[videoCallControlEnum.SWITCH_CAMERA] && (
              <SwitchCameraButton
                isControlDisabled={isControlDisabled}
                onClick={onSwitchCamera}
              />
            )}

          {controls[videoCallControlEnum.OPTIONS] && (
            <MoreButton
              cameraPublisher={cameraPublisher}
              isPrivateCall={isPrivateCall}
              isControlDisabled={isControlDisabled}
            />
          )}
        </div>

        {!isSmallScreen && controls[videoCallControlEnum.LEAVE_CALL] && (
          <LeaveCallButton onClick={onLeaveCall} />
        )}
      </div>

      {isSmallScreen && controls[videoCallControlEnum.LEAVE_CALL] && (
        <LeaveCallButton margin="18px 0px 0px 13px" />
      )}
    </>
  );
};

export default ControlBar;
