import { useMutation, useQuery } from "@apollo/client";
import { isEmpty, isFinite, pick } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useIdleTimer } from "react-idle-timer";

import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { systemAgentStatusEnum } from "@/enums/agentStatusEnum";
import { severityEnum } from "@/enums/styleVariantEnum";
import { UPDATE_AGENT_CURRENT_STATUS } from "@/mutations/agentStatusProviderMutations";
import * as agentStatusProviderQueries from "@/queries/agentStatusProviderQueries";
import { useValueRef } from "@/utils/hookUtils";
import { useAuthContext } from "./AuthProvider";
import { useLeaderElection } from "./LeaderElectionProvider";

export const AgentStatusContext = createContext({});

const AgentStatusProvider = ({ children }) => {
  const { onSetAppSnackbarProps } = useAppSnackbar();
  const { isLeaderTab } = useLeaderElection();
  const { isUserLoggedIn } = useAuthContext();

  const { data: agentOnlineData } = useQuery(
    agentStatusProviderQueries.GET_AGENT_ONLINE_DATA,
    { skip: !isUserLoggedIn },
  );

  const { data: agentStatusConfigurationData } = useQuery(
    agentStatusProviderQueries.GET_AGENT_STATUS_CONFIGURATION,
    { skip: !isUserLoggedIn },
  );

  const [
    updateAgentCurrentStatus,
    { loading: updateAgentCurrentStatusLoading },
  ] = useMutation(UPDATE_AGENT_CURRENT_STATUS, {
    onError: ({ message }) => {
      onSetAppSnackbarProps({
        message,
        AlertProps: { severity: severityEnum.error },
      });
    },
  });

  const { agentCurrentStatus } = agentOnlineData?.agentOnlineData || {};
  const { agentStatusConfiguration } = agentStatusConfigurationData || {};

  const valueRefs = useValueRef({
    isUserLoggedIn,
    updateAgentCurrentStatusLoading,
    isLeaderTab,
    agentCurrentStatus,
  });

  const timeout = useMemo(() => {
    const isIdleTimeoutValid =
      isFinite(agentStatusConfiguration?.idleTimeout) &&
      agentStatusConfiguration?.idleTimeout > 0;

    /* "timeout" given to useIdleTimer must be a number greater than 0 */
    if (!isIdleTimeoutValid) return undefined;
    return agentStatusConfiguration.idleTimeout * 60 * 1000;
  }, [agentStatusConfiguration?.idleTimeout]);

  const handleUpdateAgentCurrentStatus = useCallback(
    (input) => {
      if (valueRefs.current.updateAgentCurrentStatusLoading) {
        return;
      }
      if (!valueRefs.current.isUserLoggedIn) {
        return;
      }

      updateAgentCurrentStatus({ variables: { input } });
    },
    [valueRefs, updateAgentCurrentStatus],
  );

  const handleOnIdle = () => {
    if (!valueRefs.current.isLeaderTab) return;
    handleUpdateAgentCurrentStatus({ toIdleStatus: true });
  };

  const handleOnActive = () => {
    if (!valueRefs.current.isLeaderTab) return;
    if (isEmpty(valueRefs.current.agentCurrentStatus)) return;

    const {
      previousStatus,
      previousCanChat,
      previousCanVoiceCall,
      previousCanVideoCall,
    } = valueRefs.current.agentCurrentStatus;

    if (previousStatus?.systemStatus === systemAgentStatusEnum.available) {
      handleUpdateAgentCurrentStatus({
        agentStatusId: previousStatus.id,
        canChat: previousCanChat,
        canVoiceCall: previousCanVoiceCall,
        canVideoCall: previousCanVideoCall,
      });
    } else {
      handleUpdateAgentCurrentStatus({ toIntialStatus: true });
    }
  };

  const { start, pause } = useIdleTimer({
    startManually: true,
    timeout,
    throttle: 200,
    crossTab: true,
    syncTimers: 200,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
  });

  /* This effect is responsible for starting and stopping the idle timer */
  useEffect(() => {
    const shouldStartTimer = isUserLoggedIn && !!timeout;
    shouldStartTimer ? start() : pause();
  }, [isUserLoggedIn, timeout, start, pause]);

  const agentCurrentStatusData = useMemo(() => {
    if (isEmpty(agentCurrentStatus)) return null;

    return pick(agentCurrentStatus, [
      "canChat",
      "canVideoCall",
      "canVoiceCall",
      "status",
    ]);
  }, [agentCurrentStatus]);

  const agentStatusContextValue = useMemo(() => {
    return {
      agentCurrentStatusData,
      onUpdateAgentCurrentStatus: handleUpdateAgentCurrentStatus,
    };
  }, [agentCurrentStatusData, handleUpdateAgentCurrentStatus]);

  return (
    <AgentStatusContext.Provider value={agentStatusContextValue}>
      {children}
    </AgentStatusContext.Provider>
  );
};

export const useAgentStatus = () => useContext(AgentStatusContext);

export default AgentStatusProvider;
