import { isEmpty } from "lodash";
import { useMemo } from "react";

import AvatarGroupList from "@/components/AvatarGroupList";
import Tag from "@/components/Tag";
import MoreAvatarsIcon from "./MoreAvatarsIcon";

const TagList = ({ conversationTags }) => {
  const tags = useMemo(() => {
    if (isEmpty(conversationTags)) return [];

    return conversationTags.map((conversationTag) => ({
      value: conversationTag.contentTypeAllowedTag.tag.id,
      label: conversationTag.contentTypeAllowedTag.tag.name,
    }));
  }, [conversationTags]);

  return (
    <AvatarGroupList
      data={tags}
      AvatarRenderer={({ data }) => <Tag tag={data} />}
      MoreAvatarsIcon={MoreAvatarsIcon}
    />
  );
};

export default TagList;
