import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import * as videoCallUtils from "@/utils/videoCallUtils";

const useSubscriber = ({
  opentokSession,
  containerId,
  isScreenSharingPublisher,
}) => {
  const [subscribedStreams, setSubscribedStreams] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  const initializeSubscriber = async (stream) => {
    try {
      /* Reference: https://tokbox.com/developer/guides/subscribe-stream/js/#subscribing */
      const properties = {
        insertMode: "append",
        width: "100%",
        height: "100%",
        showControls: false,
      };

      /* When opentokSession.subscribe() returns an error. Subscriber object would be undefined */
      const newSubscriber = await new Promise((resolve, reject) => {
        const subscriber = opentokSession.subscribe(
          stream,
          containerId,
          properties,
          (error) => {
            if (error) reject(error);
            else resolve(subscriber);
          },
        );
      });

      return newSubscriber;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const updateSubscribers = (newSubscriber) => {
    setSubscribers((existingSubscribers) => {
      /* Remove the old subscriber after switching video call mode */
      const subscribers = existingSubscribers.filter((existingSubscriber) => {
        return existingSubscriber.streamId !== newSubscriber.streamId;
      });
      return [...subscribers, newSubscriber];
    });
  };

  const getUpdatedStreamsData = (streams) => {
    const subscribedStreamIds = subscribedStreams.map((stream) => {
      return stream.id;
    });
    const existingStreamIds = streams.map((stream) => {
      return stream.id;
    });
    const newSubscriberStreams = streams.filter((stream) => {
      return !subscribedStreamIds.includes(stream.id);
    });
    const removedSubscriberStreams = subscribedStreams.filter((stream) => {
      return !existingStreamIds.includes(stream.id);
    });
    const isExistingStreams =
      isEmpty(newSubscriberStreams) && isEmpty(removedSubscriberStreams);

    return {
      newSubscriberStreams,
      removedSubscriberStreams,
      isExistingStreams,
    };
  };

  const subscribeToStreams = async (streams) => {
    const {
      newSubscriberStreams,
      removedSubscriberStreams,
      isExistingStreams,
    } = getUpdatedStreamsData(streams);

    const subscriberStreams = isExistingStreams
      ? streams
      : newSubscriberStreams;

    /*
      Remove subscribers without a stream when user leave the call.
      When user leave a session, their stream is destroyed but the subscriber object is still retained. 
      Some fields of the subscriber object are then changed automatically by Opentok to null eg. session, stream, streamId. 
    */
    if (!isEmpty(removedSubscriberStreams) || isExistingStreams) {
      setSubscribers((prevSubscribers) => {
        return prevSubscribers.filter((subscriber) => {
          return !isEmpty(subscriber.stream);
        });
      });
    }

    setSubscribedStreams(streams);

    for (const stream of subscriberStreams) {
      const newSubscriber = await initializeSubscriber(stream);

      /* Skip update when the subscriber object is null (failed to subscribe) */
      if (!newSubscriber) return;

      updateSubscribers(newSubscriber);
    }
  };

  /* Used to add proper OT CSS classnames for the screen-sharing element  */
  useEffect(() => {
    const layoutManager = videoCallUtils.getLayoutManager(containerId);

    if (layoutManager) {
      const isScreenSharingSubscriber = subscribers.some(
        (subscriber) => subscriber?.stream?.videoType === "screen",
      );

      const isScreenSharing =
        isScreenSharingPublisher || isScreenSharingSubscriber;

      subscribers.forEach((subscriber) => {
        const { id, stream } = subscriber;

        videoCallUtils.addScreenSharingClassName({
          id,
          stream,
          layoutManager,
          isScreenSharing,
        });
      });
      layoutManager.layout();
    }
  }, [subscribers, isScreenSharingPublisher, containerId]);

  return {
    subscribeToStreams,
    subscribers,
  };
};
export default useSubscriber;
