import React, { useMemo } from "react";

import MicOffIcon from "@/assets/images/icon-mic-muted.svg";
import MicOnIcon from "@/assets/images/icon-mic.svg";
import { callTypeEnum } from "@/enums/conversation";
import IconAvatar from "./IconAvatar";

const AudioButton = ({
  type = callTypeEnum.VIDEO_CALL,
  isPrivateCall,
  isControlDisabled,
  hasAudio,
  TooltipProps,
  onClick,
}) => {
  const size = useMemo(() => {
    const isVideoCall = type === callTypeEnum.VIDEO_CALL;
    const isVoiceCall = type === callTypeEnum.VOICE_CALL;

    if (isVideoCall && isPrivateCall && hasAudio)
      return { width: "12px", height: "18px" };
    if (isVideoCall && isPrivateCall && !hasAudio)
      return { width: "23px", height: "18px" };
    if (isVideoCall && !isPrivateCall && hasAudio)
      return { width: "16px", height: "24px" };
    if (isVideoCall && !isPrivateCall && !hasAudio)
      return { width: "27px", height: "24px" };
    if (isVoiceCall && hasAudio) return { width: "12px", height: "18px" };
    if (isVoiceCall && !hasAudio) return { width: "19px", height: "19px" };
  }, [type, isPrivateCall, hasAudio]);

  return (
    <IconAvatar
      size={isPrivateCall ? "36px" : "56px"}
      isEnabled={hasAudio}
      isControlDisabled={isControlDisabled}
      TooltipProps={TooltipProps}
      onClick={onClick}
    >
      {hasAudio ? <MicOnIcon {...size} /> : <MicOffIcon {...size} />}
    </IconAvatar>
  );
};

export default AudioButton;
