import { Avatar, CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import Tooltip from "@/components/Tooltip";

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: "pointer",
    backgroundColor: theme.palette.alert.main,
    "&.isEnabled": {
      backgroundColor: theme.palette.grey[1900],
    },
    "&.isControlDisabled": {
      backgroundColor: theme.palette.grey[2900],
      cursor: "initial",
      "& path": {
        fill: theme.palette.grey[1900],
      },
    },
  },
}));

const IconAvatar = ({
  children,
  className,
  backgroundColor,
  size = "36px",
  isEnabled,
  isLoading,
  isControlDisabled,
  TooltipProps = {},
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Tooltip whiteOption {...TooltipProps}>
      <Avatar
        style={{ width: size, height: size, backgroundColor }}
        className={clsx(
          classes.avatar,
          { isEnabled, isControlDisabled },
          className,
        )}
        onClick={!isControlDisabled ? onClick : undefined}
      >
        {isLoading ? <CircularProgress color="white" size={20} /> : children}
      </Avatar>
    </Tooltip>
  );
};

export default IconAvatar;
