import { gql } from "@apollo/client";

import { agentOnlineDataDefinition } from "@/definitions/agentOnlineDataDefinition";
import * as inboxViewPaneDefinition from "@/definitions/allowedInboxView/inboxViewPaneDefinition";
import {
  allowedInboxViewsSpecialsCountDefinition,
  conversationListConversationDefinition,
} from "@/definitions/conversation/conversationListConversationDefinitions";
import {
  contactDefinition,
  conversationProviderConversationDefinition,
} from "@/definitions/conversation/conversationProviderConversationDefinitions";
import { conversationEventDefinition } from "@/definitions/conversation/event/conversationEventDefinitions";
import { conversationEventSubscriptionDefinition } from "@/definitions/conversation/event/conversationEventSubscriptionDefinitions";
import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";
import { messageTemplateDefinition } from "@/definitions/messageTemplate/messageTemplateDefinition";
import { initiateConversationMPCDefinition } from "@/definitions/messagingProviderContact/messagingProviderContactDefinitions";
import * as inboxPagePaymentSessionDefinition from "@/definitions/paymentSessionDefinitions/inboxPagePaymentSessionDefinition";
import { slashCommandDefinition } from "@/definitions/slashCommandDefinitions";
import { slashCommandWhatsappHsmTranslatedTemplatesDefinition } from "@/definitions/whatsappHsmTranslatedTemplate/slashCommandWhatsappHsmTranslatedTemplatesDefinitions";
import { MAX_PAGINATION_LIMIT } from "@/settings";

export const GET_SLASH_COMMANDS = gql`
  query GetSlashCommands {
    slashCommands ${slashCommandDefinition}
    paymentConfigurations(limit: ${MAX_PAGINATION_LIMIT}) {
      results{ 
        id
        name
        keyword
      }
    }
    allApprovedWhatsappHsmKeywords {
      keyword
      whatsappHsmTranslatedTemplateId
	  }
    allLandingPageDocumentKeywords{
      id
      name
      keyword
    }
  }
`;

export const GET_CONTACT_SIMPLE = gql`
  query GetContact($id: ID!) {
    contact(id: $id) {
      id
      displayName
    }
  }
`;

export const GET_SIDE_PANEL_CONTACT = gql`
  query GetContact($id: ID!) {
    contact(id: $id) ${contactDefinition}
  }
`;

export const GET_CONTACTS = gql`
  query GetContacts(
    $search: String
    $orderBy: String
    $limit: Int
    $offset: Int
  ) {
    contacts(
      search: $search
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      totalCount
      results {
        id
        displayName
        phoneNumber
      }
    }
  }
`;

export const GET_INITIATION_CONTACT = gql`
  query GetContacts($id: ID) {
    contacts(id: $id) {
      totalCount
      results {
        id
        phoneNumber
        fullName
        messagingProviderContacts ${initiateConversationMPCDefinition}
        voiceProviderContacts {
          id
          accountId
          voiceProvider {
            id
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATION_INITIATION_DATA = gql`
  query GetConversationInitiationData {
    me {
      id
      groups {
        id
        name
      }
    }

    messagingProviderAccounts {
      id
      name
      messagingProvider {
        id
        name
      }
    }
  }
`;

export const GET_PAST_CONVERSATION_ID = gql`
  query GetPastConversationId(
    $orderBy: String
    $limit: Int
    $offset: Int
    $contactId: ID
    $statusIn: [ConversationStatusEnum]
    $createdLt: DateTime
  ) {
    conversations(
      orderBy: $orderBy
      limit: $limit
      offset: $offset
      contactId: $contactId
      statusIn: $statusIn
      createdLt: $createdLt
    ) {
      results {
        id
      }
    }
  }
`;

export const GET_AGENT_ONLINE_DATA = gql`
  query GetAgentOnlineData {
    agentOnlineData ${agentOnlineDataDefinition}
  }
`;

export const GET_AGENT_GROUPS = gql`
  query GetAgentGroups($id: ID) {
    agents(id: $id) {
      results {
        id
        groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_TRANSFER_OPTIONS = gql`
  query GetTransferOptions(
    $nameSearch: String
    $withoutMe: Boolean
    $limit: Int
  ) {
    groups(nameSearch: $nameSearch, limit: $limit) {
      id
      name
    }
    agents(nameSearch: $nameSearch, limit: $limit, withoutMe: $withoutMe) {
      results {
        id
        state
        user {
          firstName
          lastName
          email
        }
        agentCurrentStatus {
          canChat
          canVideoCall
          canVoiceCall
          status {
            id
            systemStatus
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATION_LIST_DATA = gql`
  query GetConversationListData(
    $id: ID
    $orderBy: String
    $limit: Int
    $offset: Int
    $isPriority: Boolean
    $contactId: ID
    $allowedInboxViewInput: AllowedInboxViewInput
    $statusIn: [ConversationStatusEnum]
    $createdSourceEx: [ConversationCreatedSourceEnum]
  ) {
    conversations(
      id: $id
      orderBy: $orderBy
      limit: $limit
      offset: $offset
      isPriority: $isPriority
      contactId: $contactId
      allowedInboxViewInput: $allowedInboxViewInput
      statusIn: $statusIn
      createdSourceEx: $createdSourceEx
      ) {
      totalCount
      results ${conversationListConversationDefinition}
    }
  }
`;

export const GET_CHAT_PANEL_CONVERSATION_DATA = gql`
  query GetChatPanelConversationData($conversationId: ID) {
    conversations(id: $conversationId) {
      results ${conversationProviderConversationDefinition}
    }
  }
`;

export const GET_FALLBACK_EVENT = gql`
  query GetFallbackEvent($id: ID!) {
    fallbackEvent(id: $id) ${conversationEventSubscriptionDefinition}
  }
`;

export const GET_CONVERSATION_EVENTS = gql`
  query GetConversationEvents(
    $orderBy: String
    $conversationId: ID
    $pointer: ID
    $limit: Int
    $offset: Int
    $eventTypeEx: [EventEventTypeEnum]
    $eventTypeIn: [EventEventTypeEnum]
    $hasActionObjectWithMediaContent: Boolean
  ) {
    conversations (id: $conversationId) {
      totalCount
      results {
        id
        events(
          orderBy: $orderBy
          pointer: $pointer
          limit: $limit
          offset: $offset
          eventTypeEx: $eventTypeEx
          eventTypeIn: $eventTypeIn
          hasActionObjectWithMediaContent: $hasActionObjectWithMediaContent
        ) {
          offset
          totalCount
          results ${conversationEventDefinition}
        }
      }
    }
  }
`;

/* 
  Get active conversation for specified contact.
  For use in components outside of inbox.
*/
export const GET_CONTACT_ACTIVE_CONVERSATION = gql`
  query GetContactActiveConversation($id: ID!) {
    contact(id: $id) {
      id
      displayName
      conversations(limit: 1, offset: 0, statusIn: [ACTIVE, PENDING]) {
        id
        assignee {
          id
        }
      }
    }
  }
`;

export const GET_ALLOWED_INBOX_VIEWS = gql`
  query GetAllowedInboxViews {
    allowedInboxViews {
      groups (orderBy: "name") ${inboxViewPaneDefinition.groupDefinition}
      conversationTags (orderBy: "tag__name") ${inboxViewPaneDefinition.contentTypeAllowedTagDefinition}
      contactTags (orderBy: "tag__name") ${inboxViewPaneDefinition.contentTypeAllowedTagDefinition}
      agents (orderBy: "firstName") ${inboxViewPaneDefinition.agentDefinition}
    }
  }
`;

export const GET_SPECIAL_ALLOWED_INBOX_VIEWS_ACTIVE_COUNT = gql`
  query GetSpecialInboxViewsCount($statusIn: [ConversationStatus]) {
    allowedInboxViews(statusIn: $statusIn) {
      specialsCount ${allowedInboxViewsSpecialsCountDefinition}
    }
  }
`;

export const GET_MENTION_SUGGESTIONS = gql`
  query GetMentionSuggestions(
    $conversationId: ID,
    $search: String,
    $limit: Int, 
    $offset: Int, 
    $orderBy: String
  ) {
    mentionSuggestions (
      conversationId: $conversationId,
      search: $search, 
      limit: $limit,     
      offset: $offset, 
      orderBy: $orderBy
    ) {
      agent ${sharedConversationDefinitions.mentionAgentDefinition}
      canViewConversation
    }
  }
`;

export const GET_PAYMENT_CONFIGURATION = gql`
  query GetPaymentConfiguration($id: ID) {
    paymentConfigurations(id: $id) {
      results {
        id
        name
        currency {
          id
          alphaCode
          minorUnit
        }
        paymentConfigurationFields {
          id
          fieldType
          displayName
          defaultValue
          isRequired
          isSystemField
          isShownInReceipt
          maxCharactersLimit
          paymentConfigurationFieldChoices {
            id
            choiceValue
          }
        }

        whatsappHsmTemplate {
          id
          whatsappHsmTranslatedTemplates ${inboxPagePaymentSessionDefinition.whatsappHsmTranslatedTemplatesDefinition}
        }
      }
    }
  }
`;

export const GET_PAYMENT_CONFIGURATION_FIELDS_SIMPLE = gql`
  query GetPaymentConfigurationFieldsSimple($id: ID) {
    paymentConfigurations(id: $id) {
      results {
        id
        paymentConfigurationFields {
          ${inboxPagePaymentSessionDefinition.sharedPaymentConfigurationFieldDefinition}
          paymentConfigurationFieldChoices {
            id
            choiceValue
          }
        }
        
        whatsappHsmTemplate {
          id
          whatsappHsmTranslatedTemplates {
            id
            headerType
            headerTextParams {
              id
              hint
            }
            bodyTextParams {
              id
              hint
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTACT_PAYMENT_SESSIONS = gql`
  query GetContactPaymentSessions($offset: Int, $limit: Int, $contactId: ID) {
    paymentSessions(offset: $offset, limit: $limit, contactId: $contactId) {
      totalCount
      results ${inboxPagePaymentSessionDefinition.paymentSessionDefinition}
    }
  }
`;

export const GET_WHATSAPP_HSM_SLASH_COMMANDS = gql`
  query GetWhatsappHsmTranslatedTemplates($id: ID, $idIn: [ID]) {
    whatsappHsmTranslatedTemplates(id: $id, idIn: $idIn) {
      totalCount
      results ${slashCommandWhatsappHsmTranslatedTemplatesDefinition}
    }
  }
`;

export const GET_CONTACT_PAYMENT_SESSIONS_STATUS_IN_COUNT = gql`
  query GetContactPaymentSessionsStatusInCount(
    $contactId: ID
    $statusIn: [PaymentSessionStatusEnum]
  ) {
    paymentSessions(contactId: $contactId, statusIn: $statusIn) {
      totalCount
    }
  }
`;

export const GET_LANDING_PAGE_DOCUMENT_VISIT_LOGS = gql`
  query GetLandingPageDocumentVisitLogs(
    $contactId: ID
    $orderBy: String
    $offset: Int
    $limit: Int
  ) {
    landingPageDocumentVisitLogs(
      contactId: $contactId
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      totalCount
      results {
        id
        created
        totalDwellTime
        landingPageDocumentVersion {
          id
          landingPageDocument {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_DOCUMENT_VISIT_LOG_DETAIL = gql`
  query GetLandingPageDocumentVisitLogDetail($id: ID, $contactId: ID) {
    landingPageDocumentVisitLogs(id: $id, contactId: $contactId) {
      totalCount
      results {
        id
        created
        totalDwellTime
        shortLinkVisitLog {
          id
          userAgent
          device
          country
          cityName
          ipAddress
          contact {
            id
            displayName
          }
        }

        landingPageDocumentVersion {
          id
          versionNumber
          landingPageDocument {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_DOCUMENT_VISITS_COUNT = gql`
  query GetLandingPageDocumentVisitsCount(
    $contactId: ID
    $createdGte: DateTime
  ) {
    landingPageDocumentVisitLogs(
      contactId: $contactId
      createdGte: $createdGte
    ) {
      totalCount
    }
  }
`;

export const GET_LANDING_PAGE_DOCUMENT_SHORT_LINKS = gql`
  query GetLandingPageDocumentShortLinks(
    $contactId: ID
    $orderBy: String
    $offset: Int
    $limit: Int
  ) {
    landingPageDocumentShortLinks(
      contactId: $contactId
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      totalCount
      results {
        id
        created
        allVisitTotalDwellTime
        landingPageDocument {
          id
          name
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_DOCUMENT_SHORT_LINK_DETAIL = gql`
  query GetLandingPageDocumentShortLinkDetail($id: ID) {
    landingPageDocumentShortLinks(id: $id) {
      totalCount
      results {
        id
        numberOfVisit
        numberOfForwarded
        landingPageDocument {
          id
          name
          latestDocumentVersion {
            id
            versionNumber
          }
        }

        shortLink {
          id
          conversationMessage {
            id
            created

            senderBotUser {
              name
            }

            senderAgent {
              id
              user {
                email
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TAG_OPTIONS = gql`
  query GetTagOption($limit: Int, $contentType: String, $nameSearch: String) {
    contentTypeAllowedTags(
      limit: $limit
      contentType: $contentType
      nameSearch: $nameSearch
    ) {
      id
      tag {
        id
        name
      }
    }
  }
`;

export const GET_LANDING_PAGE_DOCUMENT_SHORT_LINKS_SENT_COUNT = gql`
  query GetLandingPageDocumentShortLinksSentCount(
    $contactId: ID
    $createdGte: DateTime
  ) {
    landingPageDocumentShortLinks(
      contactId: $contactId
      createdGte: $createdGte
    ) {
      totalCount
    }
  }
`;

export const PENDING_PAYMENT_SESSIONS_COUNT_SUBSCRIPTION = gql`
  subscription onPendingPaymentSessionsCount($contactId: ID) {
    onPendingPaymentSessionsCount(contactId: $contactId) {
      pendingPaymentSessionsCount
    }
  }
`;

export const AGENT_ONLINE_DATA_SUBSCRIPTION = gql`
  subscription onAgentOnlineData {
    onAgentOnlineData {
      agentOnlineData ${agentOnlineDataDefinition}
    }
  }
`;

export const CONVERSATION_MESSAGE_STATUS_LIST_SUBSCRIPTION = gql`
  subscription onUpdateConversationMessagesStatus {
    onUpdateConversationMessagesStatus {
      conversationMessages ${sharedConversationDefinitions.conversationMessageStatusSubscriptionDefinition}
    }
  }
`;

export const CONVERSATION_EVENTS_LIST_SUBSCRIPTION = gql`
  subscription onNewConversationEvents {
    onNewConversationEvents {
      events ${conversationEventSubscriptionDefinition}
    }
  }
`;

export const AGENT_CURRENT_STATUS_SUBSCRIPTION = gql`
  subscription onAgentCurrentStatus {
    onAgentCurrentStatus {
      agentId
      agentCurrentStatus ${inboxViewPaneDefinition.agentCurrentStatusDefinition}
    }
  }
`;

export const CONVERSATION_SPECIAL_ALLOWED_INBOX_VIEWS_COUNT_SUBSCRIPTION = gql`
  subscription onUpdateAllowedInboxViewConversationCount {
    onUpdateAllowedInboxViewConversationCount {
      allowedInboxViewSpecialsCount ${allowedInboxViewsSpecialsCountDefinition}
    }
  }
`;

export const MEDIA_TRANSCRIPTION_SUBSCRIPTION = gql`
  subscription onMediaTranscriptionSubscription {
    onMediaTranscriptionSubscription {
      media {
        uuid
        transcriptions {
          id
          status
          text
        }
      }
    }
  }
`;

export const UPDATE_EDITOR_SUBSCRIPTION = gql`
  subscription onUpdateEditor($conversationId: ID!) {
    onUpdateEditor(conversationId: $conversationId) {
      messageTemplate ${messageTemplateDefinition}
    }
  }
`;
