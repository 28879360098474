export const meUserDefinition = `{
    id
    nickname
    user {
        email
        firstName
        lastName
    }
}
`;

export const meUserIdDefinition = `{
    id
}
`;
