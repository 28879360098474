import { makeStyles } from "@material-ui/core";
import SwitchIcon from "@material-ui/icons/SyncAlt";
import clsx from "clsx";
import React from "react";

import Button from "@/components/Button";
import Tooltip from "@/components/Tooltip";

const useStyles = makeStyles(() => ({
  switchButton: {
    "&.isSwitchActionLoading": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },

  startIcon: {
    margin: "0px 5px 0px 0px",
  },
}));

const SwitchButton = ({
  isSwitchActionLoading,
  TooltipProps = {},
  onSwitchActiveVoiceCall,
}) => {
  const classes = useStyles();

  return (
    <Tooltip disableHoverListener={isSwitchActionLoading} {...TooltipProps}>
      <Button
        className={clsx(classes.switchButton, { isSwitchActionLoading })}
        classes={{ startIcon: classes.startIcon }}
        width="80px"
        height="36px"
        fontSize="1.08rem"
        startIcon={<SwitchIcon />}
        onClick={isSwitchActionLoading ? undefined : onSwitchActiveVoiceCall}
      >
        Switch
      </Button>
    </Tooltip>
  );
};

export default SwitchButton;
