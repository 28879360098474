import axios from "axios";

import { getServerSettings } from "@/utils/serverUtils";

class MediaApi {
  constructor({ baseUrl }) {
    this.baseUrl = baseUrl;
  }

  uploadMedia = async ({
    file,
    token,
    onSuccess,
    onError,
    onCancel,
    onUploadProgress,
  }) => {
    const formData = new FormData();
    formData.append("media_file", file);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    const abortController = new AbortController();
    if (onCancel) onCancel(() => abortController.abort());
    const { signal } = abortController;

    try {
      const { data } = await axios({
        url: this.baseUrl,
        method: "post",
        data: formData,
        headers,
        signal,
        onUploadProgress,
      });

      onSuccess && onSuccess(data);
    } catch (error) {
      if (axios.isCancel(error)) return;

      onError && onError(error);
    }
  };
}

const { mediaUrl, landingPageMediaUrl } = getServerSettings();

export const landingPageMediaApi = new MediaApi({
  baseUrl: landingPageMediaUrl,
});

export default new MediaApi({ baseUrl: mediaUrl });
