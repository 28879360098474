/* Component for the most common button style. */
import { Button, makeStyles, useTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import React, { forwardRef, useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },

  iconContainer: ({ alignIcon, iconPosition }) => ({
    display: "flex",
    position: iconPosition,
    alignItems: "center",
    ...(alignIcon === "left" && { left: "8px" }),
    ...(alignIcon === "right" && { right: "8px" }),
  }),

  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },

  loading: {
    color: "white",
  },

  link: {
    position: "relative",
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: "normal",
    marginTop: "20px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&.MuiButton-text": {
      padding: "unset",
    },
  },

  button: ({ currentBackgroundColor, backgroundColor }) => {
    return {
      position: "relative",
      textTransform: "none",
      display: "flex",
      alignItems: "center",

      /* Override MuiButton-root default minWidth to avoid the container being stretched unexpectedly */
      minWidth: "0px",
      backgroundColor: currentBackgroundColor || theme.palette.primary.main,
      "&:hover": {
        backgroundColor: backgroundColor.hover || theme.palette.primary.dark,
      },

      "&.Mui-disabled": {
        backgroundColor:
          currentBackgroundColor || theme.palette.disabledInput.main,
      },
    };
  },
}));

const CustomButton = forwardRef(
  (
    {
      children,
      color,
      width = null,
      height = null,
      margin = 0,
      textTransform = "none",
      backgroundColor = {
        normal: undefined,
        selected: undefined,
        hover: undefined,
        disabled: undefined,
      },
      isLink = false,
      isSelected = false,
      isLoading = false,
      isIconAlignCenter = false,
      disabled,
      icon,
      alignIcon = "left",
      fontSize = null,
      loadingSize = 20,
      padding,
      iconPosition = "relative",
      classes = null,
      labelClassName = null,
      buttonClassName = null,
      iconClassName = null,
      labelRef = null,
      style = {},
      ...otherProps
    },
    ref,
  ) => {
    const currentBackgroundColor = useMemo(() => {
      if (isSelected) return backgroundColor.selected;
      if (disabled) return backgroundColor.disabled;
      return backgroundColor.normal;
    }, [isSelected, disabled, backgroundColor]);

    const theme = useTheme();
    const customStyles = useStyles({
      alignIcon,
      currentBackgroundColor,
      backgroundColor,
      iconPosition,
    });

    return (
      <Button
        ref={ref}
        fullWidth
        disableElevation
        variant={isLink ? "text" : "contained"}
        disabled={disabled}
        classes={{
          root: isLink
            ? clsx(customStyles.link, buttonClassName)
            : clsx(customStyles.button, buttonClassName),
          ...classes,
        }}
        style={{
          fontSize,
          width,
          height: height || "40px",
          color: isLink
            ? color || theme.palette.link.main
            : color || theme.palette.common.white,
          margin,
          padding,
          textTransform,
          ...style,
        }}
        {...otherProps}
      >
        <div
          className={clsx(customStyles.container, labelClassName)}
          ref={labelRef}
        >
          {Boolean(icon) && (
            <div className={clsx(customStyles.iconContainer, iconClassName)}>
              {icon}
            </div>
          )}
          {children}
          {isLoading && !isIconAlignCenter && (
            <CircularProgress
              className={customStyles.loading}
              size={loadingSize}
              style={{
                marginLeft: "5px",
                position: "absolute",
                right: "20px",
              }}
            />
          )}
          {isLoading && isIconAlignCenter && (
            <div className={customStyles.loadingContainer}>
              <CircularProgress
                className={customStyles.loading}
                size={loadingSize}
              />
            </div>
          )}
        </div>
      </Button>
    );
  },
);

export default CustomButton;
