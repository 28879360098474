import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import ShareScreenIcon from "@/assets/images/icon-share-screen.svg";
import Button from "@/components/Button";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[3200],
    width: "100%",
    height: "100%",
    zIndex: 2,
    opacity: "70%",
  },

  description: {
    color: theme.palette.common.white,
    padding: "9px 0px 18px",
  },
}));

const ShareScreenOverlay = ({ onStopShareScreen }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={clsx(classes.container, "OT_ignore")}>
      <ShareScreenIcon />
      <div className={classes.description}>You are sharing a browser tab</div>
      <Button
        fullWidth={false}
        width="96px"
        height="36px"
        fontSize="1rem"
        margin="unset"
        backgroundColor={{
          normal: theme.palette.secondary.border,
          selected: theme.palette.secondary.border,
          hover: theme.palette.secondary.border,
        }}
        onClick={onStopShareScreen}
      >
        Stop Share
      </Button>
    </div>
  );
};

export default ShareScreenOverlay;
