import { Tooltip as BaseTooltip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { forwardRef } from "react";

import Hint from "./Hint";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[1800],
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    border: `1px solid ${theme.palette.grey[600]}`,
    padding: "10px 8px",
    maxWidth: "100%",
  },

  whiteTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.textSecondary[1740],
    fontSize: "0.8rem",
    border: `1px solid ${theme.palette.grey[600]}`,
    padding: "5px",
    maxWidth: "100%",
  },

  children: {
    display: "flex",
  },
}));

const Tooltip = forwardRef(
  (
    {
      children,
      placement,
      arrow,
      title = "",
      shortcut,
      whiteOption = false,
      rootClassName,
      childrenClassName,
      HintProps = {},
      ...props
    },
    ref,
  ) => {
    const { classes: tooltipClasses, ...otherProps } = props;

    const classes = useStyles();

    const tooltipTitle =
      shortcut || !isEmpty(HintProps) ? (
        <Hint
          title={title}
          shortcut={shortcut}
          whiteOption={whiteOption}
          {...HintProps}
        />
      ) : (
        title
      );

    return (
      <BaseTooltip
        ref={ref}
        placement={placement}
        arrow={arrow}
        title={tooltipTitle}
        classes={{
          tooltip: clsx(
            whiteOption ? classes.whiteTooltip : classes.tooltip,
            rootClassName,
          ),
          ...tooltipClasses,
        }}
        {...otherProps}
      >
        <div className={clsx(classes.children, childrenClassName)}>
          {children}
        </div>
      </BaseTooltip>
    );
  },
);

export default Tooltip;
