import { alpha, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useMemo } from "react";

import { voiceCallParticipantTypeEnum } from "@/enums/voiceCall";
import * as voiceCallUtils from "@/utils/voiceCallUtils";
import ReconnectCallMonitorButton from "../../ActionBanner/ReconnectCallMonitorButton";
import ControlBar from "../../ControlBar";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  floatingVoiceCallContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "4px",
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.22)}`,
    backgroundColor: theme.palette.grey[3200],
    color: theme.palette.common.white,
  },

  controlBarContainer: {
    height: "unset",
    borderRadius: "4px",
    padding: "9px 8px 10px 11px",
  },
}));

const DefaultFloatingVoiceCall = ({
  userId,
  isAgentMonitoringAnyCall,
  isAnyVoiceCallStarting,
  isReconnectingVoiceCallMonitor,
  isEndingCall,
  voiceConversation,
  isActiveVoiceCall,
  isMuteActionLoading,
  isHoldActionLoading,
  onToggleMicrophone,
  onToggleHoldCall,
  onEndVoiceCall,
  onReconnectCallMonitor,
  onHandleConversationSwitch,
  onSwitchActiveVoiceCall,
  onOpenVoiceCallInvitationModal,
}) => {
  const classes = useStyles();
  const {
    currentParticipants,
    isOnHold,
    answeredAt,
    answeredByMachineAt,
    lastOnHoldAt,
    conversation,
  } = voiceConversation || {};

  const isAnsweredByMachine = !!answeredByMachineAt;
  const callAnsweredTimestamp = answeredAt || answeredByMachineAt;

  const parsedParticipants = useMemo(
    () => voiceCallUtils.parseVoiceCallParticipants(currentParticipants),
    [currentParticipants],
  );

  const myParticipant = useMemo(
    () =>
      voiceCallUtils.getParticipantFromVoiceCall({
        voiceCallParticipants: currentParticipants,
        participantType: voiceCallParticipantTypeEnum.agent,
        participantId: userId,
      }),
    [currentParticipants, userId],
  );

  const hasNewAgentParticipant = useMemo(() => {
    const otherAgentsInCall = voiceCallUtils.getOtherActiveAgentsFromCall({
      currentParticipants,
      userId,
      returnAsAgentObjects: false,
    });

    return otherAgentsInCall.some((participant) => {
      return moment(myParticipant?.voiceLeg?.created).isBefore(
        participant.voiceLeg.created,
      );
    });
  }, [currentParticipants, myParticipant?.voiceLeg?.created, userId]);

  const isReconnectCallMonitorButtonShown =
    myParticipant?.isCallMonitor && hasNewAgentParticipant;

  return (
    <div className={classes.floatingVoiceCallContainer}>
      <Header
        contact={conversation?.contact}
        callAnsweredTimestamp={callAnsweredTimestamp}
        lastOnHoldAt={lastOnHoldAt}
        isAnsweredByMachine={isAnsweredByMachine}
        isOnHold={isOnHold}
        onHandleConversationSwitch={onHandleConversationSwitch}
      />

      <ControlBar
        containerClassName={clsx(classes.controlBarContainer)}
        contacts={parsedParticipants.contacts}
        agents={parsedParticipants.agents}
        isFloatingVoiceCall
        isAgentMonitoringAnyCall={isAgentMonitoringAnyCall}
        isEndActionShown={myParticipant?.isActive}
        isActiveVoiceCall={isActiveVoiceCall}
        isOnHold={isOnHold}
        isMicOn={!myParticipant?.isMuted}
        isMyParticipantActive={myParticipant?.isActive}
        hasMyParticipantAnswered={myParticipant?.isCallAnswered}
        isAnsweredByMachine={isAnsweredByMachine}
        callAnsweredTimestamp={callAnsweredTimestamp}
        isMuteActionLoading={isMuteActionLoading}
        isHoldActionLoading={isHoldActionLoading}
        isEndActionLoading={isEndingCall}
        isSwitchActionLoading={isAnyVoiceCallStarting}
        onToggleHoldCall={onToggleHoldCall}
        onToggleMicrophone={onToggleMicrophone}
        onEndCall={onEndVoiceCall}
        onSwitchActiveVoiceCall={onSwitchActiveVoiceCall}
        onOpenVoiceCallInvitationModal={onOpenVoiceCallInvitationModal}
      />

      {isReconnectCallMonitorButtonShown && (
        <ReconnectCallMonitorButton
          isReconnectingVoiceCallMonitor={isReconnectingVoiceCallMonitor}
          onClick={onReconnectCallMonitor}
        />
      )}
    </div>
  );
};

export default DefaultFloatingVoiceCall;
