import React, { useEffect, useMemo, useState } from "react";

import MoreIcon from "@/assets/images/icon-more.svg";
import IconAvatar from "@/components/Call/CallControlBarIcons/IconAvatar";
import { videoCallDeviceEnum } from "@/enums/videoCall";
import MoreOptions from "./MoreOptions";

/*
    Audio input = Microphone
    Audio output = Speaker
    Video input = Camera
*/

const MoreButton = ({ cameraPublisher, isPrivateCall, isControlDisabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [defaultMicrophone, setDefaultMicrophone] = useState();
  const [defaultSpeaker, setDefaultSpeaker] = useState();
  const [defaultCamera, setDefaultCamera] = useState();
  const [devices, setDevices] = useState();

  const size = useMemo(() => {
    if (isPrivateCall) return { width: "14px", height: "4px" };

    return { width: "23px", height: "7px" };
  }, [isPrivateCall]);

  const handleMicrophoneChange = (device) => {
    cameraPublisher.publisher.setAudioSource(device.deviceId);
    setDefaultMicrophone(device);
  };

  const handleSpeakerChange = async (device) => {
    const OT = await import("@opentok/client");
    await OT.setAudioOutputDevice(device.deviceId);
    setDefaultSpeaker(device);
  };

  const handleCameraChange = async (device) => {
    cameraPublisher.publisher.setVideoSource(device.deviceId);
    setDefaultCamera(device);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async function () {
      const OT = await import("@opentok/client");

      /*
        Opentok returned undefined deviceId if the camera and microphone has not been granted access.
        Wait until getUserMedia promise is resolved a.k.a when permissions are granted.
        Reference: https://tokbox.com/developer/sdks/js/reference/OT.html#getUserMedia
      */
      await OT.getUserMedia();
      const speakers = await OT.getAudioOutputDevices();
      const defaultSpeaker = await OT.getActiveAudioOutputDevice();

      setDefaultSpeaker(defaultSpeaker);

      OT.getDevices((err, devices) => {
        if (err) console.error(err);

        const cameras = devices.filter(
          (device) => device.kind === "videoInput",
        );
        const microphones = devices.filter(
          (device) => device.kind === "audioInput",
        );

        setDevices({
          [videoCallDeviceEnum.CAMERA]: cameras,
          [videoCallDeviceEnum.MICROPHONE]: microphones,
          [videoCallDeviceEnum.SPEAKER]: speakers,
        });
      });
    })();
  }, []);

  useEffect(() => {
    if (cameraPublisher.publisher && cameraPublisher.stream) {
      const defaultMicrophone = cameraPublisher.publisher.getAudioSource();
      const defaultCamera = cameraPublisher.publisher.getVideoSource();
      setDefaultMicrophone(defaultMicrophone);
      setDefaultCamera(defaultCamera);
    }
  }, [cameraPublisher.publisher, cameraPublisher.stream]);

  return (
    <>
      <IconAvatar
        size={isPrivateCall ? "36px" : "56px"}
        isEnabled
        isControlDisabled={isControlDisabled}
        onClick={handleMenuOpen}
      >
        <MoreIcon {...size} />
      </IconAvatar>
      <MoreOptions
        anchorEl={anchorEl}
        defaultMicrophone={defaultMicrophone}
        defaultSpeaker={defaultSpeaker}
        defaultCamera={defaultCamera}
        devices={devices}
        onMicrophoneChange={handleMicrophoneChange}
        onSpeakerChange={handleSpeakerChange}
        onCameraChange={handleCameraChange}
        onClose={handleMenuClose}
      />
    </>
  );
};

export default MoreButton;
