import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";

import SelectInput from "@/components/GenericFormFieldInputs/SelectInput";
import { meUserIdDefinition } from "@/definitions/meDefinitions";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import { getAgentFullName } from "@/utils/commonUtils";
import * as voiceCallUtils from "@/utils/voiceCallUtils";

const useStyles = makeStyles((theme) => ({
  selectorContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "15px",
  },

  selectorLabel: {
    color: theme.palette.textPrimary.main,
    fontSize: "0.92rem",
  },
}));

const VoiceConversationTransferSelector = ({
  control,
  currentParticipants,
}) => {
  const classes = useStyles();

  const { data: meQueryData } = useMeQuery({ objectShape: meUserIdDefinition });
  const { id: userId } = meQueryData?.me || {};

  const conversationTransferSelectorOptions = useMemo(() => {
    const otherAgentsInCall = voiceCallUtils.getOtherActiveAgentsFromCall({
      currentParticipants,
      userId,
    });

    const transferToOtherAgentOptions = otherAgentsInCall.map((agent) => {
      const agentFullName = getAgentFullName(agent);
      return { label: `Transfer to ${agentFullName}`, value: agent.id };
    });

    const options = [
      voiceCallUtils.defaultConversationTransferSelectorOption,
      ...transferToOtherAgentOptions,
    ];

    return options;
  }, [currentParticipants, userId]);

  return (
    <div className={classes.selectorContainer}>
      <label
        className={classes.selectorLabel}
        htmlFor="conversationTransferSelector"
      >
        Transfer the task?
      </label>

      <SelectInput
        control={control}
        controlName="conversationTransferOption"
        SelectProps={{
          options: conversationTransferSelectorOptions,
          inputId: "conversationTransferSelector",
          isSearchable: false,
          controlWidth: "253px",
        }}
      />
    </div>
  );
};

export default VoiceConversationTransferSelector;
