import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import * as sleekplanMutations from "@/mutations/sleekplanMutations";
import { useValueRef } from "@/utils/hookUtils";
import { getServerSettings } from "@/utils/serverUtils";
import { useAuthContext } from "./AuthProvider";

/* 
  Link to sleekplan single sign on docs:
  https://sleekplan.com/docs/single-sign-on/#_1-get-your-sso-secret-key
*/

const SleekplanProvider = ({ children }) => {
  const [isSleekplanRegistered, setIsSleekplanRegistered] = useState(false);
  const [isSleekplanLoaded, setIsSleekplanLoaded] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const { isUserLoggedIn } = useAuthContext();

  const [createSleekplanToken] = useMutation(
    sleekplanMutations.CREATE_SLEEKPLAN_TOKEN,
    {
      onCompleted: ({ createSleekplanToken }) => {
        const { token } = createSleekplanToken || {};
        /* Set the user token when data is fetched */
        window.$sleek?.setUser({ token });
      },
    },
  );

  const registerSleekplan = () => {
    const { sleekplanProductId } = getServerSettings();
    window.$sleek = [];
    window.SLEEK_PRODUCT_ID = sleekplanProductId;

    const script = document.createElement("script");
    script.src = "https://client.sleekplan.com/sdk/e.js";
    script.async = 1;
    document.querySelector("head").appendChild(script);
    setIsSleekplanRegistered(true);
  };

  const setSleekplanReady = () => {
    setIsSleekplanLoaded(true);
  };

  /* 
    Add open & close listener for Sleekplan widget to avoid system trying to close a widget under "close" status.
    Calling .close() when the widget is not open can cause the app to crash on logout
  */
  useEffect(() => {
    const openCallback = () => {
      setIsWidgetOpen(true);
    };
    const closeCallback = () => {
      setIsWidgetOpen(false);
    };

    /* Let us know when sleekplan sdk has been loaded */
    window.document.addEventListener("sleek:init", setSleekplanReady);

    window.document.addEventListener("sleek:open", openCallback);
    window.document.addEventListener("sleek:close", closeCallback);

    return () => {
      window.document.removeEventListener("sleek:init", setSleekplanReady);
      window.document.removeEventListener("sleek:open", openCallback);
      window.document.removeEventListener("sleek:close", closeCallback);
    };
  }, []);

  const valueRefs = useValueRef({ isWidgetOpen, createSleekplanToken });

  useEffect(() => {
    const { isWidgetOpen, createSleekplanToken } = valueRefs.current;

    if (!isUserLoggedIn) {
      /* Close widget if it was open when user logs out */
      if (isSleekplanLoaded) {
        if (isWidgetOpen) window.$sleek?.close();
        window.$sleek?.setUser({ token: undefined });
      }
      return;
    }

    /* Register sleekplan sdk if it hasn't been already */
    if (!isSleekplanRegistered) {
      registerSleekplan();
      return;
    }

    if (isSleekplanLoaded) createSleekplanToken();
  }, [valueRefs, isUserLoggedIn, isSleekplanRegistered, isSleekplanLoaded]);

  return children;
};

export default SleekplanProvider;
