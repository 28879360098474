import { gql } from "@apollo/client";

export const REGISTER_HOOK_SUBSCRIPTION = gql`
  subscription onRegisterHook {
    onRegisterHook {
      extensionAppId
      watchEvent
      cancelEvents
      data
    }
  }
`;
