import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import InfoIcon from "@/assets/images/icon-info-grey.svg";
import { useCountdown, useValueRef } from "@/utils/hookUtils";
import clsx from "clsx";
import ActionBanner from "./ActionBanner";

const useStyles = makeStyles((theme) => ({
  actionBannerContainer: {
    justifyContent: "center",
    margin: "3px 0px 12px",

    "&.isWrapper": {
      justifyContent: "end",
    },
  },

  actionBanner: {
    display: "contents",
    alignItems: "center",
    gap: "5px",
    fontWeight: "700",
    backgroundColor: "transparent",

    "&.isWrapper": {
      display: "flex",
      gap: "unset",
      borderRadius: "15px",
      margin: "8px 8px 8px 12px",
      color: theme.palette.grey[3200],
      backgroundColor: theme.palette.common.white,
      boxShadow: `0px 3px 6px 0px ${theme.palette.boxShadow.main}`,
    },
  },

  refreshCountdownContainer: {
    display: "flex",
    alignItems: "center",
  },

  reconnectButton: {
    cursor: "pointer",
    color: theme.palette.link.main,
  },

  infoIcon: {
    marginRight: "6px",
  },

  transparentIcon: {
    "& path": {
      fill: theme.palette.common.white,
    },
  },
}));

const ReconnectCallMonitorButton = ({
  isWrapper = false,
  isReconnectingVoiceCallMonitor,
  onClick,
}) => {
  const classes = useStyles();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { countdown, isCountdownCompleted } = useCountdown(3);

  const isReconnectCallMonitorFailed =
    (isButtonClicked || isCountdownCompleted) &&
    !isReconnectingVoiceCallMonitor;

  const handleReconnectCallMonitor = () => {
    setIsButtonClicked(true);
    onClick();
  };

  const valueRef = useValueRef({ handleReconnectCallMonitor });

  useEffect(() => {
    if (!isCountdownCompleted || isButtonClicked) return;

    valueRef.current.handleReconnectCallMonitor();
  }, [valueRef, isCountdownCompleted, isButtonClicked]);

  return (
    <ActionBanner
      containerClassName={clsx(classes.actionBannerContainer, { isWrapper })}
      className={clsx(classes.actionBanner, { isWrapper })}
    >
      <div className={classes.refreshCountdownContainer}>
        <InfoIcon
          className={clsx(classes.infoIcon, {
            [classes.transparentIcon]: !isWrapper,
          })}
        />

        {!isCountdownCompleted && !isButtonClicked && (
          <div style={{ marginRight: "15px" }}>
            Refresh in {countdown}s&hellip;
          </div>
        )}
      </div>

      {isReconnectCallMonitorFailed && (
        <div style={{ marginRight: "15px" }}>Failed to reconnect</div>
      )}

      {(isCountdownCompleted || isButtonClicked) &&
      isReconnectingVoiceCallMonitor ? (
        <div>Reconnecting&hellip;</div>
      ) : (
        <span
          className={classes.reconnectButton}
          onClick={handleReconnectCallMonitor}
        >
          Reconnect now
        </span>
      )}
    </ActionBanner>
  );
};

export default ReconnectCallMonitorButton;
