import { makeStyles } from "@material-ui/core";
import React from "react";

import EndCallIcon from "@/assets/images/icon-call-ended.svg";
import IconAvatar from "./IconAvatar";

const useStyles = makeStyles((theme) => ({
  container: {
    "&:hover": {
      filter: "brightness(80%)",
    },
  },

  icon: {
    "& path": {
      fill: theme.palette.common.white,
    },
  },
}));

const EndCallButton = ({ isControlDisabled, TooltipProps = {}, onClick }) => {
  const classes = useStyles();

  return (
    <IconAvatar
      className={classes.container}
      isControlDisabled={isControlDisabled}
      TooltipProps={TooltipProps}
      onClick={onClick}
    >
      <EndCallIcon className={classes.icon} />
    </IconAvatar>
  );
};

export default EndCallButton;
