import React from "react";

import HoldIcon from "@/assets/images/icon-hold.svg";
import IconAvatar from "./IconAvatar";

const HoldCallButton = ({
  isOnHold,
  isControlDisabled,
  TooltipProps = {},
  onClick,
}) => {
  return (
    <IconAvatar
      isEnabled={!isOnHold}
      isControlDisabled={isControlDisabled}
      TooltipProps={TooltipProps}
      onClick={onClick}
    >
      {isOnHold ? <HoldIcon /> : <HoldIcon />}
    </IconAvatar>
  );
};

export default HoldCallButton;
