import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import ParticipantAvatar from "@/components/Call/CallControlBarIcons/ParticipantAvatar";

const useStyles = makeStyles((theme) => ({
  isVideoDisabled: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.common.black,
    width: "100%",
    height: "100%",
    borderRadius: "4px",
  },
}));

/* 
  Opentok shows a default video poster if video is disabled but only allow us to change its background image.
  In order to show a custom UI, add component with a greater z-index to cover up the default poster.
*/

const DisabledVideoPoster = ({ style, initials }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div style={style} className={clsx(classes.isVideoDisabled, "OT_ignore")}>
      <ParticipantAvatar
        initials={initials}
        AvatarProps={{
          style: { fontSize: "1rem", color: theme.palette.primary.dark },
        }}
      />
    </div>
  );
};

export default DisabledVideoPoster;
