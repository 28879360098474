import { useEffect, useRef } from "react";

import * as VoiceCallProvider from "@/contextProviders/CallProvider/VoiceCallProvider";
import { meUserIdDefinition } from "@/definitions/meDefinitions";
import {
  voiceCallParticipantTypeEnum,
  voiceLegTypeEnum,
} from "@/enums/voiceCall";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import * as voiceCallUtils from "@/utils/voiceCallUtils";
import VoiceCallWidgetRenderer from "./VoiceCallWidgetRenderer";

const FloatingVoiceCallWidget = ({
  ongoingVoiceCalls,
  onHandleConversationSwitch,
  onSetConversationIdStartAndSwitchVoiceCall,
}) => {
  const audioRef = useRef();

  const { data: meQueryData } = useMeQuery({ objectShape: meUserIdDefinition });
  const { id: userId } = meQueryData?.me || {};

  const {
    conversationIdStartingVoiceCall,
    currentConversationId,
    isAgentMonitoringAnyCall,
    isMuteActionLoading,
    isHoldActionLoading,
    isVideoCallInUse,
    activeVoiceCallConversationId,
    isSwitchingActiveVoiceCall,
    isAnyVoiceCallStarting,
    isReconnectingVoiceCallMonitor,
    onStartVoiceCall,
    onEndVoiceCall,
    onToggleMicrophone,
    onToggleHoldCall,
    onAnswerIncomingCall,
    onSwitchActiveVoiceCall,
  } = VoiceCallProvider.useVoiceCallContext();

  const { onOpenVoiceCallInvitationModal } =
    VoiceCallProvider.useVoiceCallInvitationModalContext();

  const handleToggleMic = (conversationId) => {
    onToggleMicrophone({
      conversationId,
      participantType: voiceCallParticipantTypeEnum.agent,
      participantId: userId,
    });
  };

  const playRingTone = async () => {
    try {
      const audio = audioRef.current;

      if (audio && audio.paused) {
        audio.currentTime = 0;
        await audio.play();
      }
    } catch (error) {
      return;
    }
  };

  const stopRingTone = async () => {
    try {
      const audio = audioRef.current;

      if (audio && !audio.paused) {
        await audio.pause();
        audio.currentTime = 0;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    ongoingVoiceCalls.forEach((voiceCallObj) => {
      const { voiceConversation = {}, conversationId } = voiceCallObj || {};

      /* Skip if we have an active call and the conversation id doesn't match */
      const shouldSkip =
        activeVoiceCallConversationId &&
        activeVoiceCallConversationId !== conversationId;

      if (shouldSkip) return;

      const myParticipant = voiceCallUtils.getParticipantFromVoiceCall({
        voiceCallParticipants: voiceConversation?.currentParticipants || [],
        participantType: voiceCallParticipantTypeEnum.agent,
        participantId: userId,
      });
      const hasMyParticipantAnswered = myParticipant?.isCallAnswered;

      /*
        Ringtone is played when:
        1. Call has not been answered yet
        2. When my participant had not answered the call (needed for when the agent is invited to a call)
        Notes: Stop playing ringtone when switching to an inactive on-hold call while there is an incoming call
      */
      const isVoiceConversationAnswered =
        voiceConversation.answeredAt || voiceConversation.answeredByMachineAt;
      const shouldPlayRingTone =
        !isSwitchingActiveVoiceCall &&
        (!isVoiceConversationAnswered || !hasMyParticipantAnswered);

      if (shouldPlayRingTone) {
        playRingTone();
      } else {
        stopRingTone();
      }
    });
  }, [
    ongoingVoiceCalls,
    userId,
    activeVoiceCallConversationId,
    isSwitchingActiveVoiceCall,
  ]);

  return (
    <>
      {ongoingVoiceCalls.map((voiceCallObj) => {
        const { conversationId, voiceConversation = {} } = voiceCallObj || {};
        const isActiveVoiceCall =
          conversationId === activeVoiceCallConversationId;
        const isStartingCall =
          conversationIdStartingVoiceCall === conversationId;

        return (
          <VoiceCallWidgetRenderer
            key={conversationId}
            userId={userId}
            voiceCallObj={voiceCallObj}
            isAgentMonitoringAnyCall={isAgentMonitoringAnyCall}
            isActiveVoiceCall={isActiveVoiceCall}
            isAnyVoiceCallStarting={isAnyVoiceCallStarting}
            currentConversationId={currentConversationId}
            isMuteActionLoading={isMuteActionLoading}
            isHoldActionLoading={isHoldActionLoading}
            isStartingCall={isStartingCall}
            isReconnectingVoiceCallMonitor={isReconnectingVoiceCallMonitor}
            isVideoCallInUse={isVideoCallInUse}
            onToggleMicrophone={() => handleToggleMic(conversationId)}
            onToggleHoldCall={() => onToggleHoldCall(conversationId)}
            onAnswerIncomingCall={() => onAnswerIncomingCall(conversationId)}
            onRejectIncomingCall={() =>
              onEndVoiceCall({ conversationId, isCallRejection: true })
            }
            onEndVoiceCall={() => onEndVoiceCall({ conversationId })}
            onReconnectCallMonitor={() =>
              onStartVoiceCall({
                callType: voiceLegTypeEnum.CALL_MONITOR,
                conversationId,
                voiceConversationId: voiceConversation.id,
                voiceProviderContactId: "",
              })
            }
            onHandleConversationSwitch={() =>
              onHandleConversationSwitch(conversationId)
            }
            onOpenVoiceCallInvitationModal={onOpenVoiceCallInvitationModal}
            onSwitchActiveVoiceCall={() =>
              onSwitchActiveVoiceCall(conversationId)
            }
            onSetConversationIdStartAndSwitchVoiceCall={
              onSetConversationIdStartAndSwitchVoiceCall
            }
          />
        );
      })}

      <audio ref={audioRef} loop>
        <source src="/sounds/voice_ringing_tone.ogg" type="audio/ogg" />
      </audio>
    </>
  );
};

export default FloatingVoiceCallWidget;
