import React from "react";
import { components } from "react-select";

import { voiceCallCandidateTypeEnum } from "@/enums/voiceCall";
import AgentOption from "./AgentOption";
import ContactOption from "./ContactOption";
import NewContactOption from "./NewContactOption";

const optionRendererMapping = {
  [voiceCallCandidateTypeEnum.agent]: AgentOption,
  [voiceCallCandidateTypeEnum.contact]: ContactOption,
  [voiceCallCandidateTypeEnum.newContact]: NewContactOption,
};

const VoiceCandidateOption = (props) => {
  const { data } = props;
  const OptionRenderer = optionRendererMapping[data.candidateType];

  if (!OptionRenderer) return null;

  return (
    <components.Option {...props}>
      <OptionRenderer data={data} />
    </components.Option>
  );
};

export default VoiceCandidateOption;
