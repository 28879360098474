import { gql } from "@apollo/client";

export const agentCurrentStatusDefinition = `{
    canChat
    canVideoCall
    canVoiceCall
    status {
        id
        name
        systemStatus
    }

    previousCanChat
    previousCanVoiceCall
    previousCanVideoCall 
    previousStatus {
        id
        name
        systemStatus
    }   
}`;

export const agentOnlineDataDefinition = `{
    id
    globalUnreadConversationCount
    agentCurrentStatus ${agentCurrentStatusDefinition}
}`;

export const AGENT_ONLINE_DATA_FRAGMENT = gql`
  fragment AgentOnlineData on AgentOnlineDataObject ${agentOnlineDataDefinition}
`;
