import { makeStyles } from "@material-ui/core";
import React from "react";

import IconPhoneFilled from "@/assets/images/icon-phone-filled.svg";

const useStyles = makeStyles((theme) => ({
  option: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: theme.palette.textPrimary.main,
  },

  phoneIcon: {
    "& path": {
      fill: theme.palette.textPrimary.main,
    },
  },
}));

const NewContactOption = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.option}>
      <IconPhoneFilled className={classes.phoneIcon} />
      <span>
        Add <b>{data.value}</b> to the call
      </span>
    </div>
  );
};

export default NewContactOption;
