import { ListItemIcon, MenuItem, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import InputBackIcon from "@/assets/images/btn-back-input.svg";
import CheckedIcon from "@/assets/images/icon-checked.svg";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.textPrimary.main,
    lineHeight: "15px",
    padding: "7px 12px",
    "&.isBackButton": {
      padding: "9px 12px",
    },
    "&.isBold": {
      fontWeight: "700",
    },
    "&.isTitle": {
      padding: "0px 12px 2px",
    },
  },

  listItemIcon: {
    minWidth: "24px",
  },
}));

const DeviceMenuItem = ({
  device,
  text,
  isBackButton,
  isButton = true,
  isBold,
  isSelected,
  isTitle,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <MenuItem
      classes={{
        root: clsx(classes.menuItem, { isBackButton, isBold, isTitle }),
      }}
      button={isButton}
      onClick={() => onClick(device)}
    >
      {!isTitle && (
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          {isSelected && <CheckedIcon />}
          {isBackButton && <InputBackIcon style={{ cursor: "pointer" }} />}
        </ListItemIcon>
      )}
      {text}
    </MenuItem>
  );
};

export default DeviceMenuItem;
