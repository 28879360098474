export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAILED = "UPLOAD_FAILED";
export const RESET_UPLOADS = "RESET_UPLOADS";

export const setUploadProgress = ({
  uploadPercentage,
  blobURL,
  editorName,
}) => ({
  type: SET_UPLOAD_PROGRESS,
  uploadPercentage,
  blobURL,
  editorName,
});

export const uploadSuccess = ({ uuid, blobURL, editorName }) => ({
  type: UPLOAD_SUCCESS,
  uuid,
  blobURL,
  editorName,
});

export const uploadFailed = ({ error, blobURL, editorName }) => ({
  type: UPLOAD_FAILED,
  error,
  blobURL,
  editorName,
});

export const resetUploads = ({ editorName }) => ({
  type: RESET_UPLOADS,
  editorName,
});
