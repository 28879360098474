export const searchForAsyncSelectOptions = ({
  client,
  query,
  variables,
  onSuccessCallback,
  onErrorCallback,
}) => {
  client
    .query({
      query,
      variables,
      fetchPolicy: "no-cache",
    })
    .then(({ data }) => onSuccessCallback && onSuccessCallback(data))
    .catch((error) => onErrorCallback && onErrorCallback(error));
};
