import * as privateVideoCallActions from "@/actions/privateVideoCallActions";
import * as userActions from "@/actions/userActions";

const defaultState = Object.freeze({
  opentokSession: null,
  changedStream: null,
  streams: [],
  connections: [],
  previousPublisher: null,
  callTimer: null,
  waitingTimer: null,
  conversationIdStartingVideoCall: null,
  ongoingVideoCallConversationId: null,
  hasVideoSession: false,
  isFloatingVideoCall: false,
  isVideoSessionDataLoading: false,
  isScreenshotLoading: false,
});

const privateVideoCallReducer = (state = defaultState, action) => {
  switch (action.type) {
    case privateVideoCallActions.SET_OPENTOK_SESSION: {
      return Object.freeze({
        ...state,
        opentokSession: action.opentokSession,
      });
    }

    case privateVideoCallActions.SET_CHANGED_STREAM: {
      return Object.freeze({
        ...state,
        changedStream: action.changedStream,
      });
    }

    case privateVideoCallActions.ADD_CONNECTION: {
      return Object.freeze({
        ...state,
        connections: [...state.connections, action.connection],
      });
    }

    case privateVideoCallActions.REMOVE_CONNECTION: {
      return Object.freeze({
        ...state,
        connections: state.connections.filter(
          (connection) => connection.id !== action.connection.id,
        ),
      });
    }

    case privateVideoCallActions.ADD_STREAM: {
      const isExistingStream = state.streams.some(
        (currentStream) => currentStream.id === action.stream.id,
      );

      if (isExistingStream) return;

      return Object.freeze({
        ...state,
        streams: [...state.streams, action.stream],
      });
    }

    case privateVideoCallActions.REMOVE_STREAM: {
      return Object.freeze({
        ...state,
        streams: state.streams.filter(
          (stream) => stream.id !== action.stream.id,
        ),
      });
    }

    case privateVideoCallActions.SET_PREVIOUS_PUBLISHER: {
      return Object.freeze({
        ...state,
        previousPublisher: action.previousPublisher,
      });
    }

    case privateVideoCallActions.SET_CALL_TIMER: {
      return Object.freeze({
        ...state,
        callTimer: action.callTimer,
      });
    }

    case privateVideoCallActions.SET_WAITING_TIMER: {
      return Object.freeze({
        ...state,
        waitingTimer: action.waitingTimer,
      });
    }

    case privateVideoCallActions.SET_HAS_VIDEO_SESSION: {
      return Object.freeze({
        ...state,
        hasVideoSession: action.hasVideoSession,
      });
    }

    case privateVideoCallActions.SET_CONVERSATION_STARTING_VIDEO_CALL: {
      return Object.freeze({
        ...state,
        conversationIdStartingVideoCall: action.conversationIdStartingVideoCall,
      });
    }

    case privateVideoCallActions.SET_ONGOING_VIDEO_CALL_CONVERSATION_ID: {
      return Object.freeze({
        ...state,
        ongoingVideoCallConversationId: action.ongoingVideoCallConversationId,
      });
    }

    case privateVideoCallActions.SET_IS_FLOATING_VIDEO_CALL: {
      return Object.freeze({
        ...state,
        isFloatingVideoCall: action.isFloatingVideoCall,
      });
    }

    case privateVideoCallActions.SET_IS_VIDEO_SESSION_DATA_LOADING: {
      return Object.freeze({
        ...state,
        isVideoSessionDataLoading: action.isVideoSessionDataLoading,
      });
    }

    case privateVideoCallActions.SET_IS_SCREENSHOT_LOADING: {
      return Object.freeze({
        ...state,
        isScreenshotLoading: action.isScreenshotLoading,
      });
    }

    case privateVideoCallActions.RESET_VIDEO_CALL_STATES: {
      return defaultState;
    }

    case userActions.LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default privateVideoCallReducer;
