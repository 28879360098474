import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { components } from "react-select";

import BtnCloseWhite from "@/assets/images/btn-close-white.svg";
import ContactIcon from "@/assets/images/contact-msg-icon.svg";
import StatusBadge from "@/components/StatusBadge";
import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import { voiceCallCandidateTypeEnum } from "@/enums/voiceCall";
import { getFormattedPhoneNumber } from "@/utils/contactUtils";

const useStyles = makeStyles((theme) => ({
  contactIcon: {
    width: "10px",
    "& path": {
      fill: theme.palette.common.white,
    },
  },

  multiValueLabel: {
    display: "flex",
    gap: "5px",
    color: theme.palette.common.white,
    fontSize: "0.92rem",
  },
}));

const iconMapping = {
  [voiceCallCandidateTypeEnum.agent]: StatusBadge,
  [voiceCallCandidateTypeEnum.contact]: ContactIcon,
};

export const VoiceCandidateMultiValueLabel = (props) => {
  const classes = useStyles();
  const { shouldMaskPhoneNumber } = useAuthenticatedUserContext();

  const { data } = props;
  const Icon = iconMapping[data.candidateType];

  const multiValueLabel = useMemo(() => {
    return data.candidateType === voiceCallCandidateTypeEnum.contact
      ? getFormattedPhoneNumber({
          displayName: data?.label,
          shouldMaskPhoneNumber,
        })
      : data?.label;
  }, [data, shouldMaskPhoneNumber]);

  return (
    <components.MultiValueLabel {...props}>
      <div className={classes.multiValueLabel}>
        {Icon && (
          <Icon
            className={classes.contactIcon}
            status={data.agentCurrentStatus}
          />
        )}
        {multiValueLabel}
      </div>
    </components.MultiValueLabel>
  );
};

export const VoiceCandidateMultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <BtnCloseWhite width="8px" height="10px" />
    </components.MultiValueRemove>
  );
};
