import { useMemo } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";

import HookSagaProvider from "@/components/HookSagaProvider";
import AgentStatusProvider from "@/contextProviders/AgentStatusProvider";
import AuthProvider from "@/contextProviders/AuthProvider";
import PrivateVideoCallProvider from "@/contextProviders/CallProvider/PrivateVideoCallProvider";
import VoiceCallProvider from "@/contextProviders/CallProvider/VoiceCallProvider";
import HelpScoutProvider from "@/contextProviders/HelpScoutProvider";
import LeaderElectionProvider from "@/contextProviders/LeaderElectionProvider";
import ServiceStatusProvider from "@/contextProviders/ServiceStatusProvider";
import SleekplanProvider from "@/contextProviders/SleekplanProvider";
import SnackbarProvider from "@/contextProviders/Snackbar/SnackbarProvider";
import ClientNetworkProvider from "@/src/contextProviders/ClientNetworkProvider";
import { useStore } from "@/src/store";
import AppLayout from "./AppLayout";

const AppLayoutContainer = ({ children }) => {
  const store = useStore();
  const persistor = useMemo(() => persistStore(store), [store]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClientNetworkProvider>
          <LeaderElectionProvider>
            <ServiceStatusProvider>
              <SnackbarProvider>
                <AuthProvider>
                  <HookSagaProvider>
                    <HelpScoutProvider>
                      <SleekplanProvider>
                        <PrivateVideoCallProvider>
                          <VoiceCallProvider>
                            <AgentStatusProvider>
                              <AppLayout>{children}</AppLayout>
                            </AgentStatusProvider>
                          </VoiceCallProvider>
                        </PrivateVideoCallProvider>
                      </SleekplanProvider>
                    </HelpScoutProvider>
                  </HookSagaProvider>
                </AuthProvider>
              </SnackbarProvider>
            </ServiceStatusProvider>
          </LeaderElectionProvider>
        </ClientNetworkProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppLayoutContainer;
