import { alpha, makeStyles } from "@material-ui/core";

import ErrorBoundaryFallback from "@/components/ErrorBoundaryFallback";

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    width: "100%",
    height: "174px",
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 3px 6px ${alpha(theme.palette.common.black, 0.22)}`,
  },
}));

const FloatingVoiceCallWidgetError = () => {
  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <ErrorBoundaryFallback />
    </div>
  );
};
export default FloatingVoiceCallWidgetError;
