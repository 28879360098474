import { gql } from "@apollo/client";

import { notificationDefinition } from "@/definitions/notification/notificationDefinitions";

export const GET_NOTIFICATION_LIST_DATA = gql`
  query GetNotifications(
    $orderBy: String
    $isUnread: Boolean
    $limit: Int
    $offset: Int
  ) {
    notifications(
      orderBy: $orderBy
      isUnread: $isUnread
      limit: $limit
      offset: $offset
    ) {
      totalCount
      results ${notificationDefinition}
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnNewNotification {
    onNewNotification {
      notification ${notificationDefinition}
      newNotificationsCount
    }
  }
`;

export const GET_NEW_NOTIFICATIONS_COUNT = gql`
  query NewNotificationsCount {
    newNotificationsCount
  }
`;
