export const conversationIdStartingVoiceCall = (state) =>
  state.voiceCall.conversationIdStartingVoiceCall;

export const conversationIdSwitchingCallTo = (state) =>
  state.voiceCall.conversationIdSwitchingCallTo;

export const conversationIdReconnectingVoiceCallMonitor = (state) =>
  state.voiceCall.conversationIdReconnectingVoiceCallMonitor;

export const activeVoiceCallConversationId = (state) =>
  state.voiceCall.activeVoiceCallConversationId;

export const ongoingVoiceCalls = (state) => state.voiceCall.ongoingVoiceCalls;

export const ongoingVoiceCall = (conversationId) => (state) => {
  const currentOngoingVoiceCalls = ongoingVoiceCalls(state);

  return currentOngoingVoiceCalls.find(
    (item) => item.conversationId === conversationId,
  );
};

export const isVoiceCallInUse = (state) => {
  const isStartingVoiceCall = conversationIdStartingVoiceCall(state);
  const isSwitchingVoiceCall = conversationIdSwitchingCallTo(state);
  const activeVoiceCallId = activeVoiceCallConversationId(state);
  const hasVoiceCall = ongoingVoiceCalls(state).length > 0;

  const isUsingVoiceCall = Boolean(
    isStartingVoiceCall ||
      isSwitchingVoiceCall ||
      activeVoiceCallId ||
      hasVoiceCall,
  );

  return isUsingVoiceCall;
};

export const endedVoiceConversationsMap = (state) =>
  state.voiceCall.endedVoiceConversationsMap;
