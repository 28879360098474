import * as development from "./development";
import * as production from "./production";

export const useStore = (...args) => {
  if (process.env.NODE_ENV === "production") {
    return production.useStore(...args);
  } else {
    return development.useStore(...args);
  }
};
