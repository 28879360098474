import { gql } from "@apollo/client";
import { notificationEventDefinition } from "./event/notificationEventDefinitions";

export const partialNotificationDefinition = `
  id
  created
  lastReadAt
  isMarkedUnread
`;

export const notificationDefinition = `{
  ${partialNotificationDefinition}
  event ${notificationEventDefinition}
}
`;

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on NotificationObject ${notificationDefinition}
`;
