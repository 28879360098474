import hexTransparencyEnum from "@/enums/hexTransparencyEnum";

export const getTagThemeColor = (theme, tagType) => {
  const tagColors = {
    default: {
      text: theme.palette.common.white,
      background: theme.palette.primaryBackground.darkest,
      transparentBackground: `${theme.palette.primaryBackground.darkest}${hexTransparencyEnum[0]}`,
    },
    conversation: {
      text: theme.palette.common.white,
      background: theme.palette.primaryBackground.darkest,
      transparentBackground: `${theme.palette.primaryBackground.darkest}${hexTransparencyEnum[0]}`,
    },
    contact: {
      text: theme.palette.common.white,
      background: theme.palette.secondary.main,
      transparentBackground: `${theme.palette.secondary.main}${hexTransparencyEnum[0]}`,
    },
    corporate: {
      text: theme.palette.common.white,
      background: theme.palette.tertiary.light,
      transparentBackground: `${theme.palette.tertiary.light}${hexTransparencyEnum[0]}`,
    },
  };

  return tagColors[tagType];
};
