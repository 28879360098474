export const TOGGLE_SECTION = "TOGGLE_SECTION";

export const RESET_CONVERSATION_SIDE_PANE = "RESET_CONVERSATION_SIDE_PANE";

export const toggleSection = ({ renderedChildId }) => ({
  type: TOGGLE_SECTION,
  renderedChildId,
});

export const resetConversationSidePane = () => {
  return { type: RESET_CONVERSATION_SIDE_PANE };
};
