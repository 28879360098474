import Bowser from "bowser";

import { getServerSettings } from "@/utils/serverUtils";
import { getAgentFullName } from "./commonUtils";

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  [...rawData].forEach(
    (data, index) => (outputArray[index] = rawData.charCodeAt(index)),
  );

  return outputArray;
};

export const getPublicVapidKey = () => {
  const { vapidPublicApplicationServerKey } = getServerSettings();
  return urlBase64ToUint8Array(vapidPublicApplicationServerKey);
};

const convertToBase64 = (key) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
};

/* Currently backend only supports Chrome, Firefox, Edge, and Opera */
const getBrowserName = () => {
  /* Find out more user agents at https://www.whatismybrowser.com/guides/the-latest-user-agent/ */
  const parser = Bowser.getParser(window.navigator.userAgent);
  const browserName = parser.getBrowserName();

  /* bowser-js returned "Microsoft Edge" but django-push-notifications expected "EDGE"  */
  if (browserName === "Microsoft Edge") return "EDGE";

  return browserName.toUpperCase();
};

export const prepareCreateWebPushDeviceInput = ({ agent, subscription }) => {
  return {
    active: true,
    name: getAgentFullName(agent),
    browser: getBrowserName(),
    registrationId: subscription.endpoint,
    p256dh: convertToBase64(subscription.getKey("p256dh")),
    auth: convertToBase64(subscription.getKey("auth")),
  };
};
