import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  avatarGroupListContainer: {
    display: "flex",
    gap: "5px",
  },
}));

const AvatarGroupList = ({
  data = [],
  maxItemsDisplayedBeforeTruncate = 1,
  AvatarRenderer,
  MoreAvatarsIcon,
  getKey = (item) => item.id,
}) => {
  const classes = useStyles();
  const overflowLimit = maxItemsDisplayedBeforeTruncate + 1;
  const hasMoreItems = data.length > overflowLimit;
  const itemsToShow = hasMoreItems
    ? data.slice(0, maxItemsDisplayedBeforeTruncate)
    : data;

  return (
    <div className={classes.avatarGroupListContainer}>
      {itemsToShow.map((item) => {
        if (!AvatarRenderer) return null;

        return (
          <div key={getKey(item)}>
            <AvatarRenderer data={item} />
          </div>
        );
      })}

      {hasMoreItems && MoreAvatarsIcon && (
        <div>
          <MoreAvatarsIcon
            hiddenItems={data.slice(maxItemsDisplayedBeforeTruncate)}
          />
        </div>
      )}
    </div>
  );
};

export default AvatarGroupList;
