import { Dialog, Grid, makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

import Header from "@/components/Call/VoiceCall/FloatingVoiceCallWidget/FloatingInboundCall/Header";
import TextPreview from "@/components/TextPreview";
import * as VoiceCallProvider from "@/contextProviders/CallProvider/VoiceCallProvider";
import * as voiceCallUtils from "@/utils/voiceCallUtils";
import LeaveCallActionButtons from "./LeaveCallActionButtons";
import VoiceConversationTransferSelector from "./VoiceConversationTransferSelector";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    alignItems: "center",
    minWidth: "298px",
    maxWidth: "640px",
    minHeight: "278px",
  },

  dialogBodyContainer: {
    flex: 1,
    padding: "0px 50px",
  },

  titleText: {
    padding: "12px 0px 4px",
    fontSize: "1.23rem",
    fontWeight: "700",
    color: theme.palette.textPrimary.main,
  },

  voiceProviderNameText: {
    fontSize: "0.92rem",
    color: theme.palette.grey[1750],
  },

  footerText: {
    paddingBottom: "15px",
    fontSize: "0.92rem",
    lineHeight: "1.4rem",
    color: theme.palette.grey[1750],
  },
}));

const LeaveVoiceCallModal = () => {
  const classes = useStyles();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      conversationTransferOption:
        voiceCallUtils.defaultConversationTransferSelectorOption,
    },
  });

  const { onEndVoiceCall } = VoiceCallProvider.useVoiceCallContext();

  const {
    activeConversationVoiceCall,
    activeVoiceCallConversationId,
    isLeaveVoiceCallModalShown,
    isAgentTransferLoading,
    onCloseLeaveVoiceCallModal,
  } = VoiceCallProvider.useLeaveVoiceCallModalContext();

  const {
    conversation,
    currentParticipants = [],
    initiatorVoiceLeg,
  } = activeConversationVoiceCall || {};
  const { contact, group, conversationTags } = conversation || {};
  const voiceProviderName = initiatorVoiceLeg?.voiceProviderAccount?.name;

  const handleLeaveCall = ({ conversationTransferOption }) => {
    const { value: agentId } = conversationTransferOption;
    onEndVoiceCall({
      conversationId: activeVoiceCallConversationId,
      agentId,
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isLeaveVoiceCallModalShown}
      onClose={onCloseLeaveVoiceCallModal}
    >
      <Header
        contact={contact}
        group={group}
        conversationTags={conversationTags}
      />

      <Grid
        className={classes.dialogBodyContainer}
        container
        direction="column"
        alignItems="center"
      >
        <TextPreview ContainerProps={{ className: classes.titleText }}>
          Leave call?
        </TextPreview>

        <TextPreview
          ContainerProps={{ className: classes.voiceProviderNameText }}
        >
          {voiceProviderName}
        </TextPreview>

        <VoiceConversationTransferSelector
          control={control}
          currentParticipants={currentParticipants}
        />

        <LeaveCallActionButtons
          isAgentTransferLoading={isAgentTransferLoading}
          onLeaveCall={handleSubmit(handleLeaveCall)}
          onModalClose={onCloseLeaveVoiceCallModal}
        />

        <TextPreview ContainerProps={{ className: classes.footerText }}>
          The call will continue without you, you can rejoin anytime.
        </TextPreview>
      </Grid>
    </Dialog>
  );
};

export default LeaveVoiceCallModal;
