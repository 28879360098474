import { makeStyles } from "@material-ui/core";
import React from "react";

import VideoCameraIcon from "@/assets/images/icon-video.svg";
import { usePrivateOngoingVideoCallSessionContext } from "@/contextProviders/CallProvider/PrivateVideoCallProvider";
import CallDuration from "../CallDuration";

const useStyles = makeStyles(() => ({
  roomInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "4px",
    paddingLeft: "13px",
  },

  description: {
    fontSize: "0.85rem",
  },
}));

const RoomInfo = ({ hasContactJoined }) => {
  const classes = useStyles();
  const { ongoingVideoSession } = usePrivateOngoingVideoCallSessionContext();

  return (
    <div className={classes.roomInfoContainer}>
      <div>
        <VideoCameraIcon
          style={{ width: "14px", height: "10px", marginRight: "5px" }}
        />
        <CallDuration hasContactJoined={hasContactJoined} />
      </div>

      <div className={classes.description}>
        {hasContactJoined
          ? ongoingVideoSession?.id
          : "Video call invitation sent"}
      </div>
    </div>
  );
};

export default RoomInfo;
