import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { videoCallControlEnum } from "@/enums/videoCall";
import AudioOptions from "./AudioOptions";
import VideoOptions from "./VideoOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[600]}`,
    boxShadow: `0px 3px 6px 0px ${theme.palette.boxShadow.main}`,
    minWidth: "215px",
    "& .MuiMenu-list": {
      padding: "6px 0px",
    },
  },

  menuItem: {
    lineHeight: "15px",
    padding: "6px 13px",
  },
}));

const optionTypes = {
  [videoCallControlEnum.AUDIO]: AudioOptions,
  [videoCallControlEnum.VIDEO]: VideoOptions,
};

const MoreOptions = ({
  anchorEl,
  defaultMicrophone,
  defaultSpeaker,
  defaultCamera,
  devices,
  onMicrophoneChange,
  onSpeakerChange,
  onCameraChange,
  onClose,
}) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleCloseDeviceOptions = () => {
    setSelectedOption(null);
  };

  const DeviceOptions = optionTypes[selectedOption];

  if (selectedOption)
    return (
      <DeviceOptions
        anchorEl={anchorEl}
        open={Boolean(selectedOption)}
        defaultMicrophone={defaultMicrophone}
        defaultSpeaker={defaultSpeaker}
        defaultCamera={defaultCamera}
        devices={devices}
        onMicrophoneChange={onMicrophoneChange}
        onSpeakerChange={onSpeakerChange}
        onCameraChange={onCameraChange}
        onClose={handleCloseDeviceOptions}
      />
    );

  return (
    <Menu
      variant="menu"
      classes={{ paper: classes.paper }}
      MenuListProps={{ disablePadding: true }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          transform: "translateY(-14px)",
        },
      }}
      getContentAnchorEl={null}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => handleOptionClick(videoCallControlEnum.AUDIO)}
      >
        Audio Options
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => handleOptionClick(videoCallControlEnum.VIDEO)}
      >
        Video Options
      </MenuItem>
    </Menu>
  );
};

export default MoreOptions;
