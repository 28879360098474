import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import RecordingIcon from "./RecordingIcon";

const useStyles = makeStyles((theme) => ({
  recordingIndicatorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    left: "12px",
    borderRadius: "2px",
    padding: "8px 6px",
    zIndex: "4",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },

  recordingText: {
    marginRight: "12px",
    letterSpacing: "-0.25px",
  },

  controlIcon: {
    cursor: "pointer",
  },
}));

const RecordingIndicator = ({ ContainerProps = {} }) => {
  const classes = useStyles();

  const { className, ...otherContainerProps } = ContainerProps;

  return (
    <div
      className={clsx(classes.recordingIndicatorContainer, className)}
      {...otherContainerProps}
    >
      <RecordingIcon />
      <span className={classes.recordingText}>Recording...</span>
    </div>
  );
};

export default RecordingIndicator;
