import React, { useMemo } from "react";

import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import { getFormattedPhoneNumber } from "@/utils/contactUtils";

const FormattedContactDisplayName = ({ displayName }) => {
  const { shouldMaskPhoneNumber } = useAuthenticatedUserContext();

  const formattedContactDisplayName = useMemo(
    () =>
      getFormattedPhoneNumber({
        displayName,
        shouldMaskPhoneNumber,
      }),
    [displayName, shouldMaskPhoneNumber],
  );

  return formattedContactDisplayName || "-";
};

export default React.memo(FormattedContactDisplayName);
