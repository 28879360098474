import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import AppSnackbar from "./AppSnackbar";
import MediaUploadSnackbar from "./MediaUploadSnackbar";

const AppSnackbarContext = createContext();
const MediaUploadSnackbarContext = createContext();

const defaultSnackbarProps = {
  message: null,
  SnackbarProps: {},
  AlertProps: {},
};

const SnackbarProvider = ({ children }) => {
  const [appSnackbarProps, setAppSnackbarProps] =
    useState(defaultSnackbarProps);
  const [mediaUploadSnackbarProps, setMediaUploadSnackbarProps] =
    useState(defaultSnackbarProps);

  const handleClearAppSnackbar = useCallback(() => {
    setAppSnackbarProps(defaultSnackbarProps);
  }, []);

  const handleClearMediaSnackbar = useCallback(() => {
    setMediaUploadSnackbarProps(defaultSnackbarProps);
  }, []);

  const appSnackbarContextValue = useMemo(() => {
    return {
      onSetAppSnackbarProps: setAppSnackbarProps,
      onClearAppSnackbar: handleClearAppSnackbar,
    };
  }, [setAppSnackbarProps, handleClearAppSnackbar]);

  const MediaUploadSnackbarContextValue = useMemo(() => {
    return {
      onSetMediaUploadSnackbarProps: setMediaUploadSnackbarProps,
      onClearMediaSnackbar: handleClearMediaSnackbar,
    };
  }, [setMediaUploadSnackbarProps, handleClearMediaSnackbar]);

  return (
    <AppSnackbarContext.Provider value={appSnackbarContextValue}>
      <MediaUploadSnackbarContext.Provider
        value={MediaUploadSnackbarContextValue}
      >
        {children}
        <MediaUploadSnackbar {...mediaUploadSnackbarProps} />
        <AppSnackbar {...appSnackbarProps} />
      </MediaUploadSnackbarContext.Provider>
    </AppSnackbarContext.Provider>
  );
};

export const useAppSnackbar = () => useContext(AppSnackbarContext);
export const useMediaUploadSnackbar = () =>
  useContext(MediaUploadSnackbarContext);

export default SnackbarProvider;
