import { Divider, makeStyles, useTheme } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";

import ParticipantAvatar from "@/components/Call/CallControlBarIcons/ParticipantAvatar";
import { useAuthenticatedUserContext } from "@/contextProviders/AuthProvider";
import { getContactInitials } from "@/utils/contactUtils";

const useStyles = makeStyles((theme) => ({
  participantListContainer: {
    display: "flex",
    gap: "10px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      padding: "unset",
    },
  },

  participantList: {
    display: "flex",
    gap: "10px",
  },

  divider: {
    backgroundColor: theme.palette.grey[1890],
    margin: "auto",
    height: "16px",
  },
}));

const ParticipantList = ({
  contacts,
  agents,
  isPrivateCall,
  isParticipantDisabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { shouldMaskPhoneNumber } = useAuthenticatedUserContext();

  return (
    <div className={classes.participantListContainer}>
      {!isEmpty(contacts) && (
        <div className={classes.participantList}>
          {contacts.map(
            (
              { id, customDisplayName, displayName, hasAudio, hasVideo },
              index,
            ) => {
              return (
                <ParticipantAvatar
                  key={`${id}-${index}`}
                  initials={
                    customDisplayName ||
                    getContactInitials({
                      displayName,
                      shouldMaskPhoneNumber,
                    })
                  }
                  hasAudio={hasAudio}
                  hasVideo={hasVideo}
                  isParticipantDisabled={isParticipantDisabled}
                  AvatarProps={{
                    size: isPrivateCall ? "36px" : "56px",
                    style: {
                      fontSize: isPrivateCall ? "1rem" : "1.23rem",
                      backgroundColor: theme.palette.primary[700],
                    },
                  }}
                />
              );
            },
          )}
        </div>
      )}

      {!isEmpty(contacts) && !isEmpty(agents) && (
        <Divider className={classes.divider} orientation="vertical" />
      )}

      {!isEmpty(agents) && (
        <>
          <div className={classes.participantList}>
            {agents.map(({ id, initials, hasAudio, hasVideo }, index) => {
              return (
                <ParticipantAvatar
                  key={`${id}-${index}`}
                  initials={initials}
                  hasAudio={hasAudio}
                  hasVideo={hasVideo}
                  isParticipantDisabled={isParticipantDisabled}
                  AvatarProps={{
                    size: isPrivateCall ? "36px" : "56px",
                    style: {
                      fontSize: isPrivateCall ? "1rem" : "1.23rem",
                      backgroundColor: theme.palette.tertiary.dark,
                    },
                  }}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ParticipantList;
