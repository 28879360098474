import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import { createStorage } from "@/utils/serverUtils";
import conversationReducer from "./conversationReducer";
import conversationSidePaneReducer from "./conversationSidePaneReducer";
import crmPageReducer from "./crmPageReducer";
import inboxPageReducer from "./inboxPageReducer";
import mediaReducer from "./mediaReducer";
import privateVideoCallReducer from "./privateVideoCallReducer";
import publicVideoCallReducer from "./publicVideoCallReducer";
import userReducer from "./userReducer";
import voiceCallReducer from "./voiceCallReducer";

const storage = createStorage();

/* 
  https://github.com/rt2zz/redux-persist#transforms  
  https://github.com/rt2zz/redux-persist#nested-persists
*/

const conversationPersistConfig = {
  key: "conversation",
  storage,
  whitelist: ["focusedConversationNextPositionData"],
};

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["accessToken"],
};

const inboxPagePersistConfig = {
  key: "inboxPage",
  storage,
  whitelist: ["shouldExcludeBlastConversations"],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  conversation: persistReducer(conversationPersistConfig, conversationReducer),
  inboxPage: persistReducer(inboxPagePersistConfig, inboxPageReducer),
  media: mediaReducer,
  conversationSidePane: conversationSidePaneReducer,
  privateVideoCall: privateVideoCallReducer,
  publicVideoCall: publicVideoCallReducer,
  voiceCall: voiceCallReducer,
  crmPage: crmPageReducer,
});

export default rootReducer;
