import { gql } from "@apollo/client";

import { videoSessionDefinition } from "@/definitions/videoCallDefinitions";

export const GET_VIDEO_PROVIDER_ACCOUNT = gql`
  query GetVideoProviderAccount {
    videoProviderAccounts {
      id
      videoProvider {
        id
        name
      }
    }
  }
`;

export const GET_VIDEO_PROVIDER_ACCOUNT_NAME = gql`
  query GetVideoProviderAccountName($id: ID) {
    videoSessionUnauthenticated(id: $id) {
      id
      videoProviderAccountName
      videoProviderAccountShouldAutoArchive
    }
  }
`;

export const GET_VIDEO_SESSION_DATA = gql`
  query GetVideoSessionData(
    $id: ID
    ) {
    conversations(
      id: $id
      ) {
      results {
        id
        assignee {
          id
        }
        ongoingVideoSession ${videoSessionDefinition}
      }
    }
  }
`;
