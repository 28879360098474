import { gql } from "@apollo/client";

import { voiceCallInvitationDefinition } from "@/definitions/conversation/voiceConversation/voiceCallInvitationDefinition";

export const CREATE_VOICE_JWT = gql`
  mutation CreateVonageJWT {
    createVonageJwt {
      ok
      jwt
      expiresIn
    }
  }
`;

export const MUTE_VOICE_LEG = gql`
  mutation MuteVoiceLeg($input: MuteVoiceLegInput!) {
    muteVoiceLeg(input: $input) {
      ok
    }
  }
`;

export const UNMUTE_VOICE_LEG = gql`
  mutation UnmuteVoiceLeg($input: UnmuteVoiceLegInput!) {
    unmuteVoiceLeg(input: $input) {
      ok
    }
  }
`;

export const HOLD_VOICE_CONVERSATION = gql`
  mutation HoldVoiceConversation($input: HoldVoiceConversationInput!) {
    holdVoiceConversation(input: $input) {
      ok
    }
  }
`;

export const UN_HOLD_VOICE_CONVERSATION = gql`
  mutation UnHoldVoiceConversation($input: UnholdVoiceConversationInput!) {
    unholdVoiceConversation(input: $input) {
      ok
    }
  }
`;

export const INVITE_TO_VOICE_CONVERSATION = gql`
  mutation InviteToVoiceConversation($input: InviteToVoiceConversationInput) {
    inviteToVoiceConversation(input: $input) ${voiceCallInvitationDefinition}
  }
`;

export const BULK_CANCEL_VOICE_CONVERSATION_INVITE = gql`
  mutation BulkCancelVoiceConversationInvite($input: BulkCancelVoiceConversationInviteInput) {
    bulkCancelVoiceConversationInvite(input: $input) ${voiceCallInvitationDefinition}
  }
`;

export const SWITCH_AWAY_FROM_VOICE_CONVERSATION = gql`
  mutation SwitchAwayFromVoiceConversation(
    $input: SwitchAwayFromVoiceConversationInput!
  ) {
    switchAwayFromVoiceConversation(input: $input) {
      ok
    }
  }
`;

export const SWITCH_TO_VOICE_CONVERSATION = gql`
  mutation SwitchToVoiceConversation($input: SwitchToVoiceConversationInput!) {
    switchToVoiceConversation(input: $input) {
      ok
    }
  }
`;

export const CREATE_CONVERSATION_WITH_PHONE_NUMBER = gql`
  mutation CreateConversationWithPhoneNumber(
    $input: CreateConversationWithPhoneNumberInput
  ) {
    createConversationWithPhoneNumber(input: $input) {
      conversation {
        id
      }
    }
  }
`;
