import { useTheme } from "@material-ui/core";

import Button from "@/components/Button";

const LeaveCallActionButtons = ({
  isAgentTransferLoading,
  onLeaveCall,
  onModalClose,
}) => {
  const theme = useTheme();

  return (
    <div style={{ display: "flex", gap: "12px", padding: "17px 0px 15px" }}>
      <Button
        width="120px"
        height="36px"
        fontSize="1.08rem"
        isLoading={isAgentTransferLoading}
        isIconAlignCenter={true}
        backgroundColor={{
          normal: theme.palette.alert[400],
          hover: theme.palette.alert.main,
        }}
        onClick={onLeaveCall}
      >
        {!isAgentTransferLoading && "Leave"}
      </Button>

      <Button
        width="120px"
        height="36px"
        fontSize="1.08rem"
        backgroundColor={{
          normal: theme.palette.grey[1000],
          hover: theme.palette.grey[1500],
        }}
        onClick={onModalClose}
      >
        Cancel
      </Button>
    </div>
  );
};

export default LeaveCallActionButtons;
