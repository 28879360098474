import { makeStyles, useTheme } from "@material-ui/core";

import InputBackIcon from "@/assets/images/btn-back-input.svg";
import FormattedContactDisplayName from "@/components/FormattedContactDisplayName";
import TextPreview from "@/components/TextPreview";
import Tooltip from "@/components/Tooltip";
import CallDuration from "../../CallDuration";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    cursor: "pointer",
    padding: "0px 8px 0px 13px",
  },

  contactDisplayName: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    maxWidth: "50%",
    borderBottom: `1px solid ${theme.palette.common.white}`,
    padding: "10px 0px 5px",
  },

  icon: {
    width: "11px",
    height: "13px",
    marginRight: "8px",
    "& path": {
      fill: theme.palette.common.white,
    },
  },
}));

const Header = ({
  contact = {},
  callAnsweredTimestamp,
  lastOnHoldAt,
  isAnsweredByMachine,
  isOnHold,
  onHandleConversationSwitch,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const contactDisplayName = contact?.displayName || "";

  return (
    <div className={classes.header}>
      <div
        className={classes.contactDisplayName}
        onClick={onHandleConversationSwitch}
      >
        <Tooltip whiteOption title="Switch to the conversation">
          <div className={classes.icon}>
            <InputBackIcon />
          </div>
        </Tooltip>

        <TextPreview
          ContainerProps={{
            fontSize: "1.08rem",
            fontWeight: "500",
            color: theme.palette.common.white,
          }}
          TooltipV2Props={{
            BaseTooltipProps: {
              title: (
                <FormattedContactDisplayName displayName={contactDisplayName} />
              ),
              placement: "top",
            },
          }}
        >
          <FormattedContactDisplayName displayName={contactDisplayName} />
        </TextPreview>
      </div>

      <CallDuration
        isFloatingVoiceCall
        callAnsweredTimestamp={callAnsweredTimestamp}
        lastOnHoldAt={lastOnHoldAt}
        isAnsweredByMachine={isAnsweredByMachine}
        isOnHold={isOnHold}
      />
    </div>
  );
};
export default Header;
