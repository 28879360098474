import { IconButton, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { useMemo, useRef } from "react";

import BtnCloseWhite from "@/assets/images/btn-close-white.svg";
import TextPreview from "@/components/TextPreview";
import Tooltip from "@/components/Tooltip";
import { tagTypeEnum } from "@/enums/tagEnum";
import { areTagPropsEqual } from "@/utils/inboxPageMemoUtils";
import { getTagThemeColor } from "@/utils/tagUtils";

const useStyles = makeStyles((theme) => ({
  tagContainer: {
    height: "18px",
    width: "fit-content",
    minWidth: "17px",
    fontSize: "0.85rem",
    position: "relative",
    borderRadius: "3px",
    padding: "3px 6px",
    display: "flex",
    alignItems: "center",
    "&.isInSelector": {
      height: "20px",
      fontSize: "0.92rem",
      borderRadius: "3px 0px 0px 3px",
      padding: "3px 0px 3px 6px",
    },

    "&.isExtraTagsCounter": {
      borderRadius: "3px",
    },

    "&.isButton": {
      cursor: "pointer",
    },

    "&:hover $deleteTagBtnContainer": {
      visibility: "visible",
    },
  },

  deleteTagBtnContainer: {
    right: 0,
    position: "absolute",
    visibility: "hidden",
    borderRadius: "0px 3px 3px 0px",
    padding: "3px 6px 3px 4px",
    height: "inherit",
    "&.isExtraTagsCounter": {
      position: "relative",
      visibility: "visible",
    },
  },

  tooltipRoot: {
    fontWeight: "400",
    color: theme.palette.textPrimary.main,
  },
}));

const Tag = ({
  isInSelector = false,
  isExtraTagsCounter = false,
  isTagOperationInProgress = false,
  className,
  tag,
  tagType = tagTypeEnum.DEFAULT,
  tooltipTitle,
  onClick,
  onRemoveTag,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const tagLabelRef = useRef();

  const { value, label } = tag;

  const tagTheme = useMemo(
    () => getTagThemeColor(theme, tagType),
    [tagType, theme],
  );

  const hasRemoveButton =
    onRemoveTag && (isExtraTagsCounter || (!onClick && !isInSelector));

  const deleteBtnStyles = useMemo(
    () => ({
      background: `transparent linear-gradient(270deg, ${tagTheme.background} 0%, ${tagTheme.background} 50%, ${tagTheme.transparentBackground} 100%) 0% 0% no-repeat padding-box`,
    }),
    [tagTheme],
  );

  const handleRemoveTag = (event) => {
    if (!onRemoveTag) return;

    if (isExtraTagsCounter) {
      onRemoveTag();
    } else {
      onRemoveTag({ option: tag });
    }

    event.stopPropagation();
  };

  const handleClick = (event) => {
    if (onClick) onClick({ option: tag });
    event.stopPropagation();
  };

  const labelElement = isExtraTagsCounter ? (
    <Tooltip
      whiteOption
      placement="bottom"
      title={tooltipTitle || label}
      rootClassName={classes.tooltipRoot}
    >
      <span ref={tagLabelRef}>{label}</span>
    </Tooltip>
  ) : (
    <TextPreview TooltipV2Props={{ BaseTooltipProps: { title: label } }}>
      {label}
    </TextPreview>
  );

  return (
    <div
      key={value}
      style={{
        color: tagTheme.text,
        backgroundColor: tagTheme.background,
        opacity: isTagOperationInProgress ? 0.8 : 1,
      }}
      className={clsx(classes.tagContainer, className, {
        isInSelector,
        isExtraTagsCounter,
        isButton: !!onClick,
      })}
      onClick={handleClick}
    >
      {labelElement}

      {hasRemoveButton && (
        <IconButton
          disableRipple
          size="small"
          style={{ ...(!onClick && deleteBtnStyles) }}
          className={clsx(classes.deleteTagBtnContainer, {
            isExtraTagsCounter,
          })}
          onClick={handleRemoveTag}
        >
          <BtnCloseWhite width="8px" height="10px" />
        </IconButton>
      )}
    </div>
  );
};

export default React.memo(Tag, areTagPropsEqual);
