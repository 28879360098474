export const cachedEventsTargetObjectEnum = Object.freeze({
  /* All events fetched chronologically. */
  allEvents: "allEvents",

  /* All media events. */
  eventsWithMedia: "eventsWithMedia",

  /* All events that appear in the events timeline. */
  timelineEvents: "timelineEvents",

  /* Most recent event only. */
  mostRecentEvents: "mostRecentEvents",
});
