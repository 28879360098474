import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

import AcceptCallIcon from "@/assets/images/icon-accept-ring-white.svg";
import Button from "@/components/Button";

const useStyles = makeStyles(() => ({
  acceptButton: {
    fontSize: "1.08rem",
    fontWeight: "500",
    "& .MuiButton-startIcon": {
      margin: "unset",
    },

    "&.isDisabled": {
      opacity: 0.5,
    },
  },

  buttonLabel: {
    flex: "1",
    justifyContent: "center",
  },
}));

const AcceptButton = ({ isDisabled, onClick, buttonText = "Accept" }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      className={clsx(classes.acceptButton, { isDisabled })}
      labelClassName={classes.buttonLabel}
      width="120px"
      height="36px"
      padding="0px 10px"
      disabled={isDisabled}
      startIcon={<AcceptCallIcon />}
      backgroundColor={{ disabled: theme.palette.primary.main }}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};
export default AcceptButton;
