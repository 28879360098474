import * as mediaActions from "@/actions/mediaActions";
import { LOG_OUT_SUCCESS } from "@/actions/userActions";
import { richTextEditorNameEnum } from "@/enums/editor";
import { fileUploadStatusEnum } from "@/enums/fileEnum";

const defaultState = Object.freeze({
  [richTextEditorNameEnum.chat]: {},
});

const mediaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case mediaActions.SET_UPLOAD_PROGRESS: {
      const { blobURL, uploadPercentage, editorName } = action;

      return Object.freeze({
        ...state,
        [editorName]: {
          ...state[editorName],
          [blobURL]: {
            progress: uploadPercentage,
            status: fileUploadStatusEnum.inProgress,
          },
        },
      });
    }

    case mediaActions.UPLOAD_SUCCESS: {
      const { blobURL, uuid, editorName } = action;

      return Object.freeze({
        ...state,
        [editorName]: {
          ...state[editorName],
          [blobURL]: {
            status: fileUploadStatusEnum.success,
            uuid,
          },
        },
      });
    }

    case mediaActions.UPLOAD_FAILED: {
      const { blobURL, error, editorName } = action;

      return Object.freeze({
        ...state,
        [editorName]: {
          ...state[editorName],
          [blobURL]: {
            status: fileUploadStatusEnum.failed,
            error,
          },
        },
      });
    }

    case mediaActions.RESET_UPLOADS: {
      const { editorName } = action;

      /* Release the object url when we remove upload data */
      const urls = Object.keys(state[editorName] || {});
      urls.forEach((url) => URL.revokeObjectURL(url));

      return Object.freeze({
        ...state,
        [editorName]: {},
      });
    }

    case LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default mediaReducer;
