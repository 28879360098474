import React from "react";

const MentionIcon = ({
  width = "11.172",
  height = "11.242",
  viewBox = "0 0 11.172 11.242",
  fill = "#173f5f",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        id="Path_279"
        data-name="Path 279"
        d="M9.1-5.782,8.68-2.3a5.413,5.413,0,0,0-.042.672.339.339,0,0,0,.07.224.212.212,0,0,0,.168.084.955.955,0,0,0,.6-.21,1.89,1.89,0,0,0,.476-.56,3.063,3.063,0,0,0,.315-.8,3.8,3.8,0,0,0,.112-.924,3.392,3.392,0,0,0-.308-1.456,3.3,3.3,0,0,0-.847-1.12A3.868,3.868,0,0,0,7.952-7.1,4.762,4.762,0,0,0,6.384-7.35,4.745,4.745,0,0,0,4.6-7.021a4.4,4.4,0,0,0-1.414.9,4.043,4.043,0,0,0-.931,1.351,4.219,4.219,0,0,0-.336,1.68,4.3,4.3,0,0,0,.364,1.806A3.928,3.928,0,0,0,3.269.056,4.315,4.315,0,0,0,4.725.889,5.4,5.4,0,0,0,6.5,1.176q.546,0,.966-.035a4.585,4.585,0,0,0,.791-.133A3.861,3.861,0,0,0,8.967.742,7.023,7.023,0,0,0,9.716.308l.6,1.176a6.822,6.822,0,0,1-1.785.791,7.526,7.526,0,0,1-2.023.259,6.494,6.494,0,0,1-2.471-.448A5.591,5.591,0,0,1,2.17.868,5.422,5.422,0,0,1,.98-.924a5.653,5.653,0,0,1-.42-2.17,5.448,5.448,0,0,1,.448-2.212A5.524,5.524,0,0,1,2.24-7.084a5.791,5.791,0,0,1,1.841-1.19A5.968,5.968,0,0,1,6.37-8.708a5.824,5.824,0,0,1,2.114.378,5.35,5.35,0,0,1,1.7,1.036,4.827,4.827,0,0,1,1.134,1.547,4.448,4.448,0,0,1,.413,1.9,5.3,5.3,0,0,1-.217,1.561A3.74,3.74,0,0,1,10.9-1.071,2.788,2.788,0,0,1,9.94-.28,2.778,2.778,0,0,1,8.68,0,1.217,1.217,0,0,1,8-.2a1.841,1.841,0,0,1-.525-.532,2.128,2.128,0,0,1-.882.581,2.885,2.885,0,0,1-.784.119,2.268,2.268,0,0,1-.931-.217A3.019,3.019,0,0,1,4-.854a3.369,3.369,0,0,1-.665-.938A2.712,2.712,0,0,1,3.066-3a2.758,2.758,0,0,1,.252-1.183A3.022,3.022,0,0,1,3.976-5.1a2.912,2.912,0,0,1,.931-.6,2.861,2.861,0,0,1,1.071-.21,2.262,2.262,0,0,1,1.05.231,1.522,1.522,0,0,1,.658.609l.126-.714ZM4.592-3.01a1.394,1.394,0,0,0,.112.546A1.492,1.492,0,0,0,5-2a1.465,1.465,0,0,0,.448.315,1.277,1.277,0,0,0,.539.119,1.277,1.277,0,0,0,.539-.119A1.465,1.465,0,0,0,6.979-2a1.492,1.492,0,0,0,.3-.462,1.394,1.394,0,0,0,.112-.546,1.344,1.344,0,0,0-.112-.539,1.611,1.611,0,0,0-.3-.462,1.409,1.409,0,0,0-.448-.322,1.277,1.277,0,0,0-.539-.119,1.277,1.277,0,0,0-.539.119A1.409,1.409,0,0,0,5-4.011a1.611,1.611,0,0,0-.3.462A1.344,1.344,0,0,0,4.592-3.01Z"
        transform="translate(-0.56 8.708)"
        fill={fill}
      />
    </svg>
  );
};

export default MentionIcon;
