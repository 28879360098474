import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { usePrivateVideoCallDurationContext } from "@/contextProviders/CallProvider/PrivateVideoCallProvider";
import * as commonUtils from "@/utils/commonUtils";
import { useStopwatch } from "@/utils/hookUtils";

const useStyles = makeStyles(() => ({
  duration: {
    fontSize: "1.08rem",
    fontWeight: "700",
    lineHeight: "1.23rem",
  },
}));

const CallDuration = ({ hasContactJoined }) => {
  const classes = useStyles();

  const { callTimer, waitingTimer } = usePrivateVideoCallDurationContext();
  const timer = hasContactJoined ? callTimer : waitingTimer;

  const seconds = useStopwatch(timer);

  const getWaitingText = (timer) => {
    const duration = moment().diff(timer, "minutes");

    if (duration < 10) return "Waiting ...";

    const roundedDuration = Math.floor(duration / 10) * 10;
    return `Waiting - ${roundedDuration} min...`;
  };

  const text = hasContactJoined
    ? commonUtils.formatTime(seconds * 1000)
    : getWaitingText(timer);

  return <span className={classes.duration}>{text}</span>;
};

export default CallDuration;
