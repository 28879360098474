import { mediaDefinition } from "../conversation/sharedConversationDefinitions";

export const messageTemplatePartButtonDefinition = `{
  id
  buttonActionType
  buttonText
  buttonUrl
  landingPageDocument {
    id
    name
    latestDocumentVersion {
      id
      versionNumber
    }
  }
}`;

export const messageTemplateDefinition = `{
  id
  messageTemplateVariants {
    id
    messagingProvider {
      id
      name
    }
    messageTemplateParts {
      id
      buttons ${messageTemplatePartButtonDefinition}
      textMessageTemplatePart {
        id
        body
      }
      mediaMessageTemplatePart {
        id
        caption
        mediaMessageType
        media ${mediaDefinition}
      }
    }
  }
}`;
