import { gql } from "@apollo/client";

import {
  sharedCurrentParticipantsDefinition,
  sharedLastVoiceConversationDefinition,
} from "@/definitions/conversation/lastVoiceConversation/lastVoiceConversationDefinition";

const voiceProviderAccountDefinition = `
  id
  name
  accountId
`;

export const GET_VOICE_FEATURE_DATA = gql`
  query GetVoiceFeatureData {
    voiceConfiguration {
      id
      autoSelectOutboundDid
      defaultOutboundCallVoiceProviderAccount {
        ${voiceProviderAccountDefinition}
      }
      vonageApiBaseUrl
      vonageWebsocketUrl
    }
    
    me {
      id
      groups {
        id
        name
        ringGroup {
          id
          autoSelectOutboundDid
          defaultOutboundCallVoiceProviderAccount {
            ${voiceProviderAccountDefinition}
          }
        }
      }
    }

    voiceProviderAccounts {
        ${voiceProviderAccountDefinition}
        voiceProvider {
            id
        }
    }
  }
`;

export const GET_LAST_VOICE_CALL = gql`
  query GetLastVoiceCall($id: ID) {
    conversations(id: $id) {
      totalCount
      results {
        id
        lastVoiceConversation {
          ${sharedLastVoiceConversationDefinition}
          currentParticipants {${sharedCurrentParticipantsDefinition}}
        }
      }
    }
  }
`;

export const GET_VOICE_CALL_CANDIDATES = gql`
  query GetVoiceCallCandidates($search: String) {
    voiceCallCandidates(search: $search) {
      ... on AgentObject {
        id
        user {
          email
          firstName
          lastName
        }
        groups {
          id
          name
        }
        agentCurrentStatus {
          canChat
          canVoiceCall
          canVideoCall
          status {
            id
            systemStatus
          }
        }
      }
      ... on VoiceCallContactCandidateObject {
        isDefault
        phoneNumber
        contact {
          id
          displayName
        }
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroups {
    groups(orderBy: "name") {
      id
      name
    }
  }
`;

export const GET_AGENT_IN_GROUP = gql`
  query GetAgentInGroup($id: ID, $groups: [ID]) {
    agents(id: $id, groups: $groups) {
      totalCount
    }
  }
`;

export const DEFAULT_OUTBOUND_CALL_VPA_SETTINGS_SUBSCRIPTION = gql`
  subscription onVoiceDefaultDidSettingsChange {
    onVoiceDefaultDidSettingsChange {
      voiceConfiguration {
        id
        autoSelectOutboundDid
        defaultOutboundCallVoiceProviderAccount {
          id
        }
      }

      group {
        id
        ringGroup {
          id
          autoSelectOutboundDid
          defaultOutboundCallVoiceProviderAccount {
            id
          }
        }
      }
    }
  }
`;
