import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

import HoldIcon from "@/assets/images/icon-hold.svg";
import IconAvatar from "@/components/Call/CallControlBarIcons/IconAvatar";
import Timer from "@/components/Timer";

const useStyles = makeStyles((theme) => ({
  duration: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "1.08rem",
    lineHeight: "1.23rem",
    "&.isFloatingVoiceCall": {
      fontSize: "1rem",
    },
    "&.isOnHoldFloating": {
      color: theme.palette.alert[400],
    },
  },
}));

const DurationText = ({
  callAnsweredTimestamp,
  lastOnHoldAt,
  isAnsweredByMachine,
  isFloatingVoiceCall,
  isOnHold,
}) => {
  const text = (() => {
    if (isOnHold) return "On hold";
    if (!isFloatingVoiceCall && isAnsweredByMachine)
      return "Call answered by voicemail";
    return "";
  })();

  return (
    <b>
      {text}&nbsp;
      <Timer startTimeStamp={isOnHold ? lastOnHoldAt : callAnsweredTimestamp} />
    </b>
  );
};

const CallDuration = ({
  isFloatingVoiceCall,
  callAnsweredTimestamp,
  lastOnHoldAt,
  isAnsweredByMachine,
  isOnHold,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isOnHoldFloating = isFloatingVoiceCall && isOnHold;

  return (
    <div
      className={clsx(classes.duration, {
        isFloatingVoiceCall,
        isOnHoldFloating,
      })}
    >
      {isOnHoldFloating && (
        <IconAvatar backgroundColor={theme.palette.alert[400]} size="18px">
          <HoldIcon width="8px" height="8px" />
        </IconAvatar>
      )}

      {callAnsweredTimestamp ? (
        <DurationText
          callAnsweredTimestamp={callAnsweredTimestamp}
          lastOnHoldAt={lastOnHoldAt}
          isAnsweredByMachine={isAnsweredByMachine}
          isFloatingVoiceCall={isFloatingVoiceCall}
          isOnHold={isOnHold}
        />
      ) : (
        <b>Calling...</b>
      )}
    </div>
  );
};

export default CallDuration;
