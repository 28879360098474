import { gql } from "@apollo/client";

export const agentStatusConfigurationDefinition = `{
  id
  idleStatus {
    id
    name
    systemStatus
  }
  initialStatus {
    id
    name
    systemStatus
  }
  idleTimeout
}`;

export const agentStatusDefinition = `{
  id
  name
  systemStatus
  isActive
  isDeletable
  order
}`;

export const AGENT_STATUS_FRAGMENT = gql`
  fragment NewAgentStatus on AgentStatusObject ${agentStatusDefinition}
`;
