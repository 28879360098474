import { equalPropsWith } from "@/utils/commonUtils";

export const areContentTypeTagsEqual = (prevProps, nextProps) =>
  equalPropsWith(prevProps, nextProps, ["contentTypeTags"]);

export const areTagPropsEqual = (prevProps, nextProps) =>
  equalPropsWith(prevProps, nextProps, [
    "tag.contentTypeTagId",
    "tag.label",
    "isTagOperationInProgress",
  ]);

export const arePlaybackTimePropsEqual = (prevProps, nextProps) =>
  equalPropsWith(prevProps, nextProps, ["time"]);

export const arePlaybackButtonPropsEqual = (prevProps, nextProps) =>
  equalPropsWith(prevProps, nextProps, ["paused"]);
