import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";

import Tag from "@/components/Tag";
import { getValueStringFromObjectOrList } from "@/utils/commonUtils";

const useStyles = makeStyles(() => ({
  extraTagsCounter: {
    padding: "unset",
    justifyContent: "center",
    width: "18px",
  },
}));

const MoreAvatarsIcon = ({ hiddenItems }) => {
  const classes = useStyles();

  const tag = useMemo(() => {
    return { value: "extra", label: `+${hiddenItems.length}` };
  }, [hiddenItems]);

  const tooltipTitle = useMemo(() => {
    return getValueStringFromObjectOrList({
      data: hiddenItems,
      stringKey: "label",
    });
  }, [hiddenItems]);

  return (
    <Tag
      isExtraTagsCounter
      className={classes.extraTagsCounter}
      tag={tag}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default MoreAvatarsIcon;
